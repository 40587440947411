import React, { useEffect, useState, useRef } from "react";
import axios from "axiosClient";
import { read, utils } from "xlsx";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import { useToken } from "auth/useToken";
import { toast } from "react-toastify";

const SaveResults = ({ toggle, session = "", module = "" }) => {
  const navigate = useNavigate();

  const [candidates, setCandidates] = useState([]);
  const [candidatesLoading, setCandidatesLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);
  const [token, setToken] = useToken();

  const copyGrades = (grades) => {
    let result = [];
    grades.forEach((grade) => {
      result.push({
        module: grade["TKT Module"],
        testDate:
          typeof grade["Test Date"] === "number"
            ? new Date(
                Math.floor((grade["Test Date"] - 25569) * 86400 * 1000)
              ).toLocaleDateString("en-GB")
            : grade["Test Date"],
        candidateNo: grade["Cand No"],
        firstName: grade["First Name"],
        lastName: grade["Family Name"],
        result: grade["Band Achieved"],
        certificateNo: grade["Cert No"],
      });
    });
    return result;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setSending(true);
    try {
      axios
        .post(
          `/api/tkt-results-import`,
          { candidates },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((result) => {
          // console.log(result.data);
          setSent(true);
          setSending(false);
          if (result.data.success) {
            toast.success("All successfully saved");
          } else {
            toast.warning("Something went wrong. Not saved");
          }
        })
        .catch((err) => {
          setErrorMessage(err.response.data);
          toast.error(err.response.data);
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      {!loading && (
        <Row>
          <Colxx xxs="12" className="mb-4">
            <Card className="mb-4">
              <CardBody>
                <Form>
                  <Row>
                    <Col md="12">
                      <br />
                      Please, upload the .xlsx file of grades:
                      <FormGroup row>
                        <Colxx sm="9">
                          <Input
                            type="file"
                            accept=".xlsx"
                            onChange={async (e) => {
                              const files = e.target.files;
                              if (files) {
                                setCandidatesLoading(true);
                                const wb = read(await files[0].arrayBuffer());
                                const data = utils.sheet_to_json(
                                  wb.Sheets[wb.SheetNames[0]]
                                );
                                // console.log("data", data);
                                setCandidates(copyGrades(data));
                                // console.log(copyGrades(data));
                                setCandidatesLoading(false);
                              }
                            }}
                          />
                        </Colxx>
                      </FormGroup>
                      {/* <>{JSON.stringify(candidates)}</> */}
                      {candidatesLoading ? (
                        <p>Loading...</p>
                      ) : (
                        <p>The number of results: {candidates.length}</p>
                      )}
                      {sending && (
                        <span className="btn btn-warning">Uploading...</span>
                      )}
                      {sent && <span className="btn btn-success">DONE!</span>}
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      )}
      <div>
        <Button
          color="light"
          className="top-right-button"
          onClick={() => toggle()}
        >
          Close
        </Button>
        {!sent && (
          <Button
            color="primary"
            className="ml-2 top-right-button"
            onClick={handleSubmit}
          >
            Save
          </Button>
        )}
      </div>
      &nbsp;
      <div>{errorMessage}</div>
    </div>
  );
};

export default SaveResults;

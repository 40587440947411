import React, { useEffect, useState } from "react";
import { Button, Row, Card } from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import AppLayout from "layout/AppLayout";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axiosClient";

const ApplicationsList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  // useEffect(() => {
  //   axios.get("/api/applications").then((result) => {
  //     setData(result.data.data);
  //     setLoading(false);
  //   });
  // }, []);

  const navigate = useNavigate();

  const onSearchKey = (e) => {
    // if (e.key === "Enter") {
    //   setLoading(true);
    //   handleSearch(e.target.value.toLowerCase());
    // }
  };

  const handleSearch = (word) => {
    ///
    axios.get(`/api/applications?search=${word}`).then((result) => {
      setData(result.data.data);
      setLoading(false);
    });
  };
  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div className="mb-2">
              <h1>Applications List</h1>
              <div className="text-zero top-right-button-container"></div>
            </div>
            <div className="mb-2">
              <div className="d-block d-md-inline-block pt-1">
                <div className="search-sm d-inline-block mb-1 ">
                  <input
                    type="text"
                    name="keyword"
                    id="search"
                    placeholder={"Search"}
                    onKeyPress={(e) => onSearchKey(e)}
                  />
                </div>
              </div>
            </div>
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row>
          <div className="loading" hidden={!loading} />
          {!loading && data.length === 0 ? (
            <p>List is empty</p>
          ) : (
            data.map((university) => (
              <Colxx xxs="12" key={university._id} className="mb-3"></Colxx>
            ))
          )}
        </Row>
      </div>
    </AppLayout>
  );
};

export default ApplicationsList;

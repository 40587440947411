import React, { useEffect, useState } from "react";
import {
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
} from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";
import { toast } from "react-toastify";

import { useToken } from "auth/useToken";
import { useUser } from "auth/useUser";
import axios from "axiosClient";

const DownloadAttendanceModal = (props) => {
  const { toggle, searchValues } = props;
  const user = useUser();

  const [token, setToken] = useToken();

  const [success, setSuccess] = useState(false);
  const [sending, setSending] = useState(false);
  const [sessionId, setSessionId] = useState("");
  const [location, setLocation] = useState("Edu-Action Tashkent, UZBEKISTAN");

  const handleSubmit = () => {
    setSending(true);
    axios
      .post(
        `/api/enquiry-download-attendance`,
        {
          ...searchValues,
          venueName: location,
          sessionId,
        },
        {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((result) => {
        const url = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement("a");
        link.href = url;
        link.download = `Attendance.pdf`; //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        toast.error(err.response.data);
      });
  };

  useEffect(() => {
    if (searchValues?.region) {
      setLocation(`Edu-Action ${searchValues.region}, UZBEKISTAN`);
    }
    if (searchValues?.module) {
      switch (searchValues?.module) {
        case "TKT Module 1":
          setSessionId("AM");
          break;
        case "TKT Module 2":
        case "TKT Module 3":
          setSessionId("PM");
          break;
        default:
          setSessionId("");
      }
    }
  }, []);

  return (
    <div>
      <Row>
        <Colxx xxs="12">
          <div className="mb-2">
            <h1>Download Attendance Report</h1>
          </div>
          <Separator className="mb-5" />
        </Colxx>
      </Row>

      <Row>
        <Colxx xxs="12" className="mb-4">
          <Card className="mb-4 text-left">
            <CardBody>
              <Form>
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <Label>Session ID</Label>
                      <Input
                        name="sessionId"
                        type="text"
                        value={sessionId}
                        onChange={(e) => setSessionId(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label>Location</Label>
                      <Input
                        name="location"
                        type="text"
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Colxx>
      </Row>
      <div>
        <Button color="light" className="top-right-button" onClick={toggle}>
          Close
        </Button>
        <Button
          color={success ? "success" : "primary"}
          className="top-right-button ml-2"
          onClick={handleSubmit}
          disabled={success || sending}
        >
          {success ? "Done" : `Download`}
        </Button>
      </div>
    </div>
  );
};

export default DownloadAttendanceModal;

/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/display-name */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  UncontrolledDropdown,
} from "reactstrap"; //
import DatatablePagination from "components/DatatablePagination";
import AppLayout from "layout/AppLayout";
import axios from "axiosClient";
import { useToken } from "auth/useToken";
import { Link, NavLink } from "react-router-dom";
import Select from "react-select";
import {
  handleSendMockForgotPassMail,
  handleSendPaymentConfirmationMail,
} from "utils/sendEmails";
import moment from "moment";
import DatePicker from "react-datepicker";
import { useUser } from "auth/useUser";

import "react-datepicker/dist/react-datepicker.css";

import ListingTable2 from "components/ListingTable2";
import { reactSelectChange } from "utils/reactSelectChange";

const MockUsersList = () => {
  const user = useUser();
  const cols = React.useMemo(
    () => [
      {
        Header: "Passport No",
        accessor: "passportNo",
        cellClass: "text-muted w-10",
        Cell: (props) => <>{props.value}</>,
        sortType: "basic",
      },
      {
        Header: "Candidate",
        accessor: "firstName",
        cellClass: "text-muted w-20",
        Cell: (props) => (
          <>
            {/* <NavLink to={`/app/mock-users/${props.row.original._id}`}> */}
            {props.row.original.firstName} {props.row.original.lastName}
            {/* </NavLink> */}
          </>
        ),
        sortType: "basic",
      },
      {
        Header: "Phone",
        accessor: "phone",
        cellClass: "text-muted w-10",
        Cell: (props) => <>{props.value}</>,
        sortType: "basic",
      },
      {
        Header: "Email",
        accessor: "email",
        cellClass: "text-muted w-8",
        Cell: (props) => <>{props.value}</>,
        sortType: "basic",
      },
      {
        Header: "Created Time",
        accessor: "createdAt",
        cellClass: "text-muted w-15",
        Cell: (props) => <>{moment(props.value).format("DD/MM/YYYY, HH:mm")}</>,
        sortType: "basic",
      },
      {
        Header: "Actions",
        cellClass: "text-muted w-10",
        Cell: (props) => (
          <div className="btn-group">
            <UncontrolledDropdown>
              <DropdownToggle caret color="primary" className="btn-xs" outline>
                Action
              </DropdownToggle>
              <DropdownMenu end>
                <DropdownItem
                  onClick={() =>
                    handleSendMockForgotPassMail(props.row.original.email)
                  }
                >
                  Send Forgot Pass Link
                </DropdownItem>
                {/* {user.role !== "User" && (
                  <DropdownItem
                    onClick={() => handleDelete(props.row.original._id)}
                  >
                    Delete
                  </DropdownItem>
                )} */}
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        ),
        sortType: "basic",
      },
    ],
    []
  );
  const [token, setToken] = useToken();
  const [itemsList, setItemsList] = useState([]);
  const [itemsTotal, setItemsTotal] = useState(0);
  const [searchValues, setSearchValues] = useState({});
  const [showData, setShowData] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [selectedPageSize, setSelectedPageSize] = useState(30);
  const [isUser, setIsUser] = useState(user.role === "User" ? true : false);

  const handleSearchValueChange = (e) => {
    setSearchValues({ ...searchValues, [e.target.name]: e.target.value });
  };

  const toggleEmailModal = () => {
    setEmailModal(!emailModal);
  };

  const handleSearchSubmit = (
    currentPage = 1,
    selectedPageSize = 30,
    searchValues
  ) => {
    axios
      .post(
        "/api/mock/users",
        { page: currentPage, per_page: selectedPageSize, ...searchValues },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((result) => {
        setItemsList(result.data.items);
        setItemsTotal(result.data.total);
        setShowData(true);
        setCurrentPage(result.data.page);
        setTotalPage(result.data.pageCount);
        setLoading(false);
      });
  };

  const handleDelete = (id) => {
    axios
      .delete(`/api/mock/user/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        handleSearchSubmit();
      });
  };
  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Card className="h-100 m-4">
          <CardBody>
            <CardTitle>Mock Users</CardTitle>
            <Form
              onSubmit={(e) => {
                e.preventDefault();

                handleSearchSubmit(1, selectedPageSize, searchValues);
              }}
            >
              <Row className="text-left">
                <Col md="2">
                  <FormGroup>
                    <Label>Passport No</Label>
                    <Input
                      type="text"
                      name="passportNo"
                      value={searchValues.passportNo}
                      onChange={handleSearchValueChange}
                    />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <Label>First Name</Label>
                    <Input
                      type="text"
                      name="firstName"
                      value={searchValues.firstName}
                      onChange={handleSearchValueChange}
                    />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <Label>Last Name</Label>
                    <Input
                      type="text"
                      name="lastName"
                      value={searchValues.lastName}
                      onChange={handleSearchValueChange}
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label>Email</Label>
                    <Input
                      type="text"
                      name="email"
                      value={searchValues.email}
                      onChange={handleSearchValueChange}
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label>Phone Number</Label>
                    <Input
                      type="text"
                      name="phoneNumber"
                      value={searchValues.phoneNumber}
                      onChange={handleSearchValueChange}
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label>From</Label>
                    <DatePicker
                      name="from"
                      selected={
                        searchValues.from ? new Date(searchValues.from) : null
                      }
                      dateFormat="dd/MM/yyyy"
                      placeholderText="dd/mm/yyyy"
                      onChange={(date) => {
                        setSearchValues({
                          ...searchValues,
                          from: moment(date).format("YYYY-MM-DD"),
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label>To</Label>
                    <DatePicker
                      name="to"
                      selected={
                        searchValues.to ? new Date(searchValues.to) : null
                      }
                      dateFormat="dd/MM/yyyy"
                      placeholderText="dd/mm/yyyy"
                      onChange={(date) => {
                        setSearchValues({
                          ...searchValues,
                          to: moment(date).format("YYYY-MM-DD"),
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="12 d-flex">
                  <Button
                    color="primary"
                    type="submit"
                    onClick={() =>
                      handleSearchSubmit(1, selectedPageSize, searchValues)
                    }
                  >
                    Search
                  </Button>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
        {showData && (
          <ListingTable2
            columns={cols}
            data={itemsList}
            totalItemCount={itemsTotal}
            handleSearch={handleSearchSubmit}
            loading={loading}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPage={totalPage}
            selectedPageSize={selectedPageSize}
            setSelectedPageSize={setSelectedPageSize}
            searchValues={searchValues}
            withNumeration={false}
          />
        )}
      </div>

      {emailModal && (
        <div className="in-modal">
          <div className="modal-overlay"></div>
          <div className="in-modal-content-bigger">
            <SendEmailModal
              toggle={toggleEmailModal}
              searchValues={searchValues}
            />
          </div>
        </div>
      )}
    </AppLayout>
  );
};

export default MockUsersList;

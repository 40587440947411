import React, { useEffect, useState } from "react";
import axios from "axiosClient";
import {
  Link,
  useNavigate,
  useParams,
  useLocation,
  NavLink,
} from "react-router-dom";
import {
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
} from "reactstrap";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import AppLayout from "layout/AppLayout";
import { useToken } from "auth/useToken";

const AddRegion = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [name, setName] = useState("");
  const [status, setStatus] = useState(true);
  const [addNew, setAddNew] = useState(false);
  const [newSession, setNewSession] = useState("");
  const [sessions, setSessions] = useState([]);

  const [errorMessage, setErrorMessage] = useState("");
  const [addErrorMessage, setAddErrorMessage] = useState("");
  const [isNew, setIsNew] = useState(
    location.pathname.split("/").slice(-1)[0] === "add"
  );
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useToken();

  const { id } = useParams();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (name === "") {
      setErrorMessage("The name of the region must be filled");
    } else {
      let data = {
        name: name.trim(),
        status,
        exam: "TKT",
      };
      axios
        .post(`/api/details/regions`, isNew ? data : { id, ...data }, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((result) => {
          if (result.data.success) {
            navigate(-1);
          }
        })
        .catch((err) => {
          setErrorMessage(err.response.data);
        });
    }
  };

  const handleDelete = () => {
    axios
      .delete(`/api/details/region/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        if (result.data.success) {
          navigate(-1);
        }
      });
  };

  const handleAddNew = () => {
    if (newSession === "") {
      setAddErrorMessage("The name of the module must be filled");
    } else {
      let data = {
        name: newSession,
        status: true,
        regionId: id,
      };
      axios
        .post(`/api/details/module`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((result) => {
          if (result.data.success) {
            setSessions([...sessions, result.data.data]);
            setAddErrorMessage("");
            setNewSession("");
            setAddNew(false);
          }
        })
        .catch((err) => {
          setErrorMessage(err.response.data);
        });
    }
  };

  const postStatus = (status, id) => {
    axios
      .post(
        `/api/details/module`,
        { id, status },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((result) => {
        if (result.data.success) {
          navigate(0);
        }
      });
  };

  useEffect(() => {
    !isNew && setLoading(true);
    !isNew &&
      axios
        .get(`/api/details/region/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((result) => {
          let { data } = result;
          console.log(data);
          setName(data.name);
          setStatus(data.status);
          setSessions(data.modules);
          setLoading(false);
        });
  }, [id, isNew]);

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div className="mb-2">
              <h1>{isNew ? "New" : "Update"} Region</h1>
            </div>

            <Separator className="mb-5" />
          </Colxx>
        </Row>
        {!loading && (
          <Row>
            <Colxx xxs="12" className="mb-4">
              <Card className="mb-4">
                <CardBody>
                  <Form>
                    <Row>
                      <Col md="8">
                        <FormGroup row>
                          <Label sm="2">Region Name</Label>
                          <Colxx sm="6">
                            <Input
                              type="text"
                              name="name"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                            />
                          </Colxx>
                          <Colxx xs="4">
                            <Switch
                              className="custom-switch custom-switch-primary-inverse"
                              checked={status}
                              onChange={(value) => setStatus(value)}
                            />
                          </Colxx>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Colxx>
          </Row>
        )}
        <div>
          <Button
            color="light"
            className="top-right-button mr-2"
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>

          {!isNew && (
            <Button
              color="danger"
              className="top-right-button"
              onClick={handleDelete}
            >
              Delete
            </Button>
          )}

          <Button
            color="primary"
            className="top-right-button mr-2"
            onClick={handleSubmit}
          >
            {isNew ? "Add" : "Update"}
          </Button>
        </div>
        &nbsp;
        <div>{errorMessage}</div>
        {!isNew && (
          <>
            <Row>
              <Colxx xxs="12">
                <Separator className="mb-5" />
                <div className="mb-2">
                  <h1>Modules</h1>
                  <div className="text-zero top-right-button-container">
                    {addNew && (
                      <Input
                        type="text"
                        name="name"
                        placeholder="Module Name"
                        value={newSession}
                        onChange={(e) => setNewSession(e.target.value)}
                      />
                    )}
                    <Button
                      color="primary"
                      className="top-right-button mt-2"
                      onClick={() =>
                        addNew ? handleAddNew() : setAddNew(true)
                      }
                    >
                      {addNew ? "Submit" : "Add New"}
                    </Button>
                    <p>{addErrorMessage}</p>
                  </div>
                </div>
              </Colxx>
              {sessions.length > 0 ? (
                <>
                  {sessions.map((session) => (
                    <Colxx xxs="12" key={session._id} className="mb-3">
                      <Card className="d-flex flex-row">
                        <div className="pl-2 d-flex flex-grow-1 min-width-zero">
                          <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
                            <NavLink
                              to={`/app/modules/${session._id}`}
                              className="w-40 w-sm-100"
                            >
                              <p className="list-item-heading mb-1 truncate">
                                {session.name}
                              </p>
                            </NavLink>
                            <p className="mb-1 text-muted text-small w-15 w-sm-100">
                              <Switch
                                className="custom-switch custom-switch-primary-inverse"
                                checked={session.status}
                                onChange={(value) =>
                                  postStatus(value, session._id)
                                }
                              />
                            </p>
                          </div>
                        </div>
                      </Card>
                    </Colxx>
                  ))}
                </>
              ) : (
                <p>List is empty</p>
              )}
            </Row>
          </>
        )}
      </div>
    </AppLayout>
  );
};

export default AddRegion;

import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Row,
  Card,
  Col,
  FormGroup,
  Label,
  Input,
  CardBody,
  CardTitle,
} from "reactstrap";
import { read, utils } from "xlsx";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import AppLayout from "layout/AppLayout";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axiosClient";
import { useToken } from "auth/useToken";
import { useUser } from "auth/useUser";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import Select from "react-select";

import { toast } from "react-toastify";
import ConfirmModal from "components/ConfirmModal";
import DropzoneExample from "containers/forms/DropzoneExample";
import { toBase64 } from "utils/toBase64";

const QuestionPage = () => {
  const [data, setData] = useState([]);
  const [type, setType] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [available, setAvailable] = useState(true);

  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [token, setToken] = useToken();
  const { id } = useParams();
  const [deleteModal, setDeleteModal] = useState(false);
  const [free, setFree] = useState(false);
  const [order, setOrder] = useState(null);
  const [module, setModule] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [file, setFile] = useState();

  useEffect(() => {
    axios
      .get(`/api/mock/task/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        const { data } = result.data;
        setData(data);
        setType(data.type);
        setTitle(data.title);
        setDescription(data.description);
        setAvailable(data.available);
        setFree(data.free);
        setModule(data.module);
        setQuestions(data.questions);
        setFile(data.file);
        setOrder(data.order);
        setLoading(false);
      });
  }, []);

  const navigate = useNavigate();

  const handleDelete = () => {
    setButtonLoading(true);
    axios
      .delete(`/api/mock/task/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        setButtonLoading(false);
        if (result.status === 200) {
          navigate(-1);
        }
      });
  };

  const handleFile = async (e) => {
    let file = e.target.files[0];
    // console.log(fileType);

    try {
      setUploadLoading(true);
      const result = await toBase64(file);
      let { data } = await axios.post(
        "/api/upload-file",
        {
          file: {
            extension: file.name.split(".")[file.name.split(".").length - 1],
            type: file.type,
            base64Data: result,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      //set image in te state
      setFile(data);
      setUploadLoading(false);
    } catch (err) {
      console.log("error", err);
    }
  };

  const handleAnswerChange = (index, value) => {
    const newAnswers = [...questions];
    newAnswers[index] = value;
    setQuestions(newAnswers);
  };

  const handleUpdate = () => {
    let data = {};
    let common = { title, type, description, available, order, module, free };
    // eslint-disable-next-line default-case

    data = { ...common, questions, file };

    setButtonLoading(true);
    // console.log(data);
    axios
      .post(`/api/mock/task/${id}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        // console.log(result);
        toast.success("Successfully updated");
        navigate(-1);
      });
    setButtonLoading(false);
  };

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div className="mb-2">
              <h1>{data.title}</h1>
              <Row className="text-left align-items-left">
                <Col md="3" className="mx-auto">
                  <FormGroup row>
                    <Label sm="3">Name</Label>
                    <Colxx sm="9">
                      <Input
                        type="text"
                        name="title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </Colxx>
                  </FormGroup>
                </Col>
                <Col md="1" sm="1">
                  <Switch
                    className="custom-switch custom-switch-primary-inverse"
                    checked={available}
                    onChange={(e) => setAvailable(!available)}
                  />
                </Col>
                <Col md="3" sm="3">
                  <FormGroup row>
                    <Label sm="3">Module</Label>
                    <Colxx sm="9">
                      <Select
                        required
                        className="react-select mb-3"
                        value={{
                          label: module,
                          value: module,
                        }}
                        name="module"
                        options={[
                          { label: "TKT Module 1", value: "TKT Module 1" },
                          { label: "TKT Module 2", value: "TKT Module 2" },
                          { label: "TKT Module 3", value: "TKT Module 3" },
                        ]}
                        onChange={(e) => setModule(e.value)}
                      />
                    </Colxx>
                  </FormGroup>
                </Col>
                <Col md="2" sm="2">
                  <FormGroup row>
                    <Label sm="3">Free?</Label>
                    <Colxx sm="9">
                      <Switch
                        className="custom-switch custom-switch-primary-inverse"
                        checked={free}
                        onChange={(e) => setFree(!free)}
                      />
                    </Colxx>
                  </FormGroup>
                </Col>
                <Col md="2" sm="2">
                  <FormGroup row>
                    <Label sm="3">Order</Label>
                    <Colxx sm="9">
                      <Input
                        type="number"
                        name="order"
                        value={order}
                        onChange={(e) => setOrder(e.target.value)}
                      />
                    </Colxx>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Button
                  className="mr-2"
                  color="danger"
                  onClick={() => setDeleteModal(true)}
                >
                  Delete
                </Button>
                <Button
                  onClick={(e) => navigate(-1)}
                  disabled={buttonLoading}
                  className="mr-2"
                  color="light"
                >
                  Cancel
                </Button>
                <Button onClick={handleUpdate} disabled={buttonLoading}>
                  Update
                </Button>
              </Row>
            </div>
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row>
          <div className="loading" hidden={!loading} />
          {!loading && (
            <>
              <Colxx xxs="12">
                <Card>
                  <CardBody className="text-left">
                    <FormGroup>
                      <Label>
                        Upload PDF File{" "}
                        {file?.Location && (
                          <a target="_blank" href={file?.Location}>
                            -- Show File
                          </a>
                        )}
                      </Label>
                      <Input
                        type="file"
                        name="questionsFile"
                        onChange={handleFile}
                        disabled={loading}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>Upload Answers: Excel File</Label>
                      <Input
                        type="file"
                        name="answersFile"
                        accept=".xlsx"
                        onChange={async (e) => {
                          const files = e.target.files;
                          if (files) {
                            const wb = read(await files[0].arrayBuffer());
                            const data = utils.sheet_to_json(
                              wb.Sheets[wb.SheetNames[0]]
                            );
                            setQuestions(data.map((i) => i.Answers));
                            // setCandidateNos(data);
                          }
                        }}
                      />
                    </FormGroup>

                    <Row>
                      <Colxx xs="12">
                        <Label>Questions</Label>
                      </Colxx>
                      {questions.map((answer, index) => (
                        <Colxx xs="1" key={index}>
                          <Label className="mr-2">{index + 1}: </Label>
                          <select
                            key={index}
                            value={answer}
                            onChange={(e) =>
                              handleAnswerChange(index, e.target.value)
                            }
                          >
                            {"ABCDEFGHI".split("").map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                        </Colxx>
                      ))}
                    </Row>
                  </CardBody>
                </Card>
              </Colxx>
            </>
          )}
        </Row>
        <Separator />
        <Row className="mt-3">
          <Col md="8"></Col>
          <Col md="4">
            <Button
              onClick={(e) => navigate(-1)}
              disabled={buttonLoading}
              className="mr-2"
              color="light"
            >
              Cancel
            </Button>
            <Button onClick={handleUpdate} disabled={buttonLoading}>
              Update
            </Button>
          </Col>
        </Row>

        <ConfirmModal
          modal={deleteModal}
          setModal={setDeleteModal}
          action={handleDelete}
          title={`Are you sure you want to delete?`}
        />
      </div>
    </AppLayout>
  );
};

export default QuestionPage;

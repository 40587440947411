export const modules = {
  IELTS: ["Test fee", "Extra TRF", "Transfer", "EOR", "Refund", "Mock IELTS"],
  TKT: ["TKT Module 1", "TKT Module 2", "TKT Module 3", "Extra TRF", "Refund"],
  Linguaskill: [
    "Listening and Reading, Writing, Speaking",
    "Extra TRF",
    "Refund",
  ],
  Celta: ["Teacher Training", "DELTA Module 1", "FED EX", "CELTA TP", "Refund"],
  TRKI: ["Test fee", "Refund"],
};

export const editorDetailsSuggests = [
  "CANDIDATE_NAME",
  "TEST_DATE",
  "TEST_MODULE",
  "TEST_REGION",
  "PAYMENT_RECEIPT_NO",
  "EXAM",
  "ceqAmount",
  "enquiryId",
  "candidateName",
  "firstName",
  "lastName",
  "gender",
  "candidateTelegramId",
  "email",
  "phoneNumber",
  "passportId",
  "exam",
  "region",
  "regionLive",
  "module",
  "paymentReceipt",
  "sw",
  "partner",
  "candidateNumber",
  "result",
  "pinpp",
  "residence",
];

import React, { useEffect, useState } from "react";
import axios from "axiosClient";
import {
  Link,
  useNavigate,
  useParams,
  useLocation,
  NavLink,
} from "react-router-dom";
import {
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
} from "reactstrap";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import { Colxx, Separator } from "components/common/CustomBootstrap";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import AppLayout from "layout/AppLayout";
import { useToken } from "auth/useToken";
import moment from "moment";
import ConfirmModal from "components/ConfirmModal";

const EditSession = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [name, setName] = useState("");
  const [status, setStatus] = useState(true);
  const [addNew, setAddNew] = useState(false);
  const [values, setValues] = useState({});
  const [sessionDate, setSessionDate] = useState(null);
  const [availableFromDate, setAvailableFromDate] = useState(null);
  const [availableToDate, setAvailableToDate] = useState(null);
  const [seatsMax, setSeatsMax] = useState(0);
  const [seatsBooked, setSeatsBooked] = useState(10);
  const [sessions, setSessions] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [addErrorMessage, setAddErrorMessage] = useState("");
  const [isNew, setIsNew] = useState(
    location.pathname.split("/").slice(-1)[0] === "add"
  );
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useToken();

  const { id } = useParams();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (name === "") {
      setErrorMessage("The name of the session must be filled");
    } else {
      let data = {
        name,
        status,
        sessionDate,
        availableFrom: moment(availableFromDate).format("YYYY-MM-DD"),
        availableTo: moment(availableToDate).format("YYYY-MM-DD"),
        availableFromDate: moment(availableFromDate).set({
          hour: 0,
          minute: 0,
        }),
        availableToDate: moment(availableToDate).set({ hour: 23, minute: 59 }),
        seatsMax,
        seatsBooked,
      };
      axios
        .post(`/api/details/date`, isNew ? data : { id, ...data }, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((result) => {
          if (result.data.success) {
            navigate(-1);
          }
        })
        .catch((err) => {
          setErrorMessage(err.response.data);
        });
    }
  };

  const handleDelete = () => {
    axios
      .delete(`/api/details/date/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        if (result.data.success) {
          navigate(-1);
        }
      });
  };

  useEffect(() => {
    !isNew && setLoading(true);
    !isNew &&
      axios
        .get(`/api/details/date/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((result) => {
          let { data } = result;
          setValues(data);
          console.log(data);
          setName(data.name);
          setStatus(data.status);
          setSessionDate(data.sessionDate);
          setAvailableFromDate(data.availableFromDate);
          setAvailableToDate(data.availableToDate);
          setSeatsBooked(data.seatsBooked);
          setSeatsMax(data.seatsMax);
          setLoading(false);
        });
  }, [id, isNew]);

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div className="mb-2">
              <h1>{isNew ? "New" : "Update"} Session</h1>
            </div>

            <Separator className="mb-5" />
          </Colxx>
        </Row>
        {!loading && (
          <Row>
            <Colxx xxs="12" className="mb-4">
              <Card className="mb-4">
                <CardBody>
                  <Form>
                    <Row>
                      <Col md="8">
                        <FormGroup row>
                          <Label sm="2">Session Name</Label>
                          <Colxx sm="6">
                            <Input
                              type="text"
                              name="name"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                            />
                          </Colxx>
                          <Colxx xs="4">
                            <Switch
                              className="custom-switch custom-switch-primary-inverse"
                              checked={status}
                              onChange={(value) => setStatus(value)}
                            />
                          </Colxx>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">
                        <FormGroup row>
                          <Label sm="4">Session Date</Label>
                          <Colxx sm="8">
                            <DatePicker
                              name="sessionDate"
                              selected={
                                sessionDate ? new Date(sessionDate) : null
                              }
                              dateFormat="dd/MM/yyyy"
                              onChange={(e) =>
                                setSessionDate(moment(e).format("YYYY-MM-DD"))
                              }
                              placeholderText="dd/mm/yyyy"
                            />
                          </Colxx>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup row>
                          <Label sm="4">Region</Label>
                          <Colxx sm="8">
                            <Input type="text" disabled value={values.region} />
                          </Colxx>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup row>
                          <Label sm="4">Module</Label>
                          <Colxx sm="8">
                            <Input type="text" disabled value={values.module} />
                          </Colxx>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">
                        <FormGroup row>
                          <Label sm="4">Available From</Label>
                          <Colxx sm="8">
                            <DatePicker
                              name="availableFrom"
                              selected={
                                availableFromDate
                                  ? new Date(availableFromDate)
                                  : null
                              }
                              dateFormat="dd/MM/yyyy"
                              onChange={(date) => setAvailableFromDate(date)}
                              placeholderText="dd/mm/yyyy"
                            />
                          </Colxx>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup row>
                          <Label sm="4">To</Label>
                          <Colxx sm="8">
                            <DatePicker
                              name="availableTo"
                              selected={
                                availableToDate
                                  ? new Date(availableToDate)
                                  : null
                              }
                              dateFormat="dd/MM/yyyy"
                              onChange={(date) => setAvailableToDate(date)}
                              placeholderText="dd/mm/yyyy"
                            />
                          </Colxx>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">
                        <FormGroup row>
                          <Label sm="4">Max Availablility</Label>
                          <Colxx sm="8">
                            <Input
                              type="number"
                              name="seatsMax"
                              value={seatsMax}
                              onChange={(e) => {
                                if (e.target.value >= seatsBooked) {
                                  setSeatsMax(e.target.value);
                                } else {
                                  setSeatsMax(seatsBooked);
                                }
                              }}
                            />
                          </Colxx>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup row>
                          <Label sm="4">Remaining Seats</Label>
                          <Colxx sm="8">
                            <Input
                              type="number"
                              disabled
                              name="seatsAvailable"
                              value={seatsMax - seatsBooked}
                            />
                          </Colxx>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup row>
                          <Label sm="4">Currently Booked</Label>
                          <Colxx sm="8">
                            <Input type="number" disabled value={seatsBooked} />
                          </Colxx>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Colxx>
          </Row>
        )}
        <div>
          <Button
            color="light"
            className="top-right-button"
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>
          {!isNew && (
            <Button
              color="danger"
              className="top-right-button ml-2"
              onClick={() => setDeleteModal(!deleteModal)}
            >
              Delete
            </Button>
          )}

          <Button
            color="primary"
            className="top-right-button ml-2"
            onClick={handleSubmit}
          >
            {isNew ? "Add" : "Update"}
          </Button>
        </div>
        &nbsp;
        <div>{errorMessage}</div>
        {deleteModal && (
          <ConfirmModal
            modal={deleteModal}
            setModal={setDeleteModal}
            title={"Delete Session"}
            body={`Do you really want to delete this session?`}
            action={handleDelete}
          />
        )}
      </div>
    </AppLayout>
  );
};

export default EditSession;

import React, { useEffect, useState } from "react";
import axios from "axiosClient";
import Select from "react-select";
import {
  Link,
  useNavigate,
  useParams,
  useLocation,
  NavLink,
} from "react-router-dom";
import {
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
} from "reactstrap";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import { Colxx, Separator } from "components/common/CustomBootstrap";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import AppLayout from "layout/AppLayout";
import { useToken } from "auth/useToken";
import moment from "moment";
import ConfirmModal from "components/ConfirmModal";
import { reactSelectChange } from "utils/reactSelectChange";

const CreateSessions = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [name, setName] = useState("");
  const [status, setStatus] = useState(true);
  const [isPb, setIsPb] = useState(true);
  const [isCb, setIsCb] = useState(false);
  const [sessionDate, setSessionDate] = useState(null);
  const [exam, setExam] = useState(null);
  const [availableFromDate, setAvailableFromDate] = useState(null);
  const [availableToDate, setAvailableToDate] = useState(null);
  const [seatsMax, setSeatsMax] = useState(0);
  const [seatsBooked, setSeatsBooked] = useState(0);
  const [regions, setRegions] = useState([]);
  const [modules, setModules] = useState([]);
  const [modulesApi, setModulesApi] = useState([]);
  const [venues, setVenues] = useState([]);
  const [showRegions, setShowRegions] = useState(false);
  const [showModules, setShowModules] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const [loading, setLoading] = useState(false);
  const [token, setToken] = useToken();

  const { id } = useParams();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (name === "") {
      setErrorMessage("The name of the session must be filled");
    } else {
      let data = {
        name,
        status,
        sessionDate,
        availableFrom: moment(availableFromDate).format("YYYY-MM-DD"),
        availableTo: moment(availableToDate).format("YYYY-MM-DD"),
        availableFromDate: moment(availableFromDate).set({
          hour: 0,
          minute: 0,
        }),
        availableToDate: moment(availableToDate).set({ hour: 23, minute: 59 }),
        seatsMax,
        seatsBooked,
        regions,
        modulesApi,
        exam,
        isCb,
        isPb,
      };
      const resultArray = [];

      data.regions.forEach((region) => {
        data.modulesApi.forEach((module) => {
          const newObj = { ...data, region, module };
          resultArray.push(newObj);
        });
      });

      console.log(resultArray);
      axios
        .post(
          `/api/details/batch-dates`,
          { dates: resultArray },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((result) => {
          if (result.data.success) {
            navigate(-1);
          }
        })
        .catch((err) => {
          setErrorMessage(err.response.data);
        });
    }
  };

  const handleExamChange = (e) => {
    setExam(e.value);
    setShowRegions(false);
    setShowModules(false);
    axios
      .get(
        `/api/${
          e.value === "TKT"
            ? "tkt"
            : e.value === "TRKI"
            ? "trki"
            : e.value === "CEQs"
            ? "ceq"
            : "ls"
        }-regions`
      )
      .then((result) => {
        let venuesList = [];
        result.data.forEach((element) => {
          venuesList.push({ label: element.name, value: element.name });
        });
        venuesList.sort((a, b) => {
          if (a.label < b.label) return -1;
          if (a.label > b.label) return 1;
          return 0;
        });
        const uniqueVenuesList = venuesList.filter((value, index, self) => {
          return index === self.findIndex((v) => v.label === value.label);
        });
        setVenues(uniqueVenuesList);
        axios
          .get(
            `/api/${
              e.value === "TKT"
                ? "tkt"
                : e.value === "TRKI"
                ? "trki"
                : e.value === "CEQs"
                ? "ceq"
                : "ls"
            }-modules`
          )
          .then((result) => {
            let modulesList = [];
            result.data.forEach((element) => {
              modulesList.push({ label: element.name, value: element.name });
            });
            modulesList.sort((a, b) =>
              a.label > b.label ? 1 : b.label > a.label ? -1 : 0
            );
            const uniqueModulesList = modulesList.filter(
              (value, index, self) => {
                return index === self.findIndex((v) => v.label === value.label);
              }
            );
            setModules(uniqueModulesList);
          });
        setLoading(false);
        setShowRegions(true);
        setShowModules(true);
      });
  };

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div className="mb-2">
              <h1>Add Sessions</h1>
            </div>

            <Separator className="mb-5" />
          </Colxx>
        </Row>
        {!loading && (
          <Row>
            <Colxx xxs="12" className="mb-4">
              <Card className="mb-4">
                <CardBody>
                  <Form>
                    <Row>
                      <Col md="8">
                        <FormGroup row>
                          <Label sm="2">Session Name</Label>
                          <Colxx sm="6">
                            <Input
                              type="text"
                              name="name"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                            />
                          </Colxx>
                          <Colxx xs="4">
                            <Switch
                              className="custom-switch custom-switch-primary-inverse"
                              checked={status}
                              onChange={(value) => setStatus(value)}
                            />
                          </Colxx>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">
                        <FormGroup row>
                          <Label sm="4">Session Date</Label>
                          <Colxx sm="8">
                            <DatePicker
                              name="sessionDate"
                              selected={
                                sessionDate ? new Date(sessionDate) : null
                              }
                              dateFormat="dd/MM/yyyy"
                              onChange={(e) =>
                                setSessionDate(moment(e).format("YYYY-MM-DD"))
                              }
                              placeholderText="dd/mm/yyyy"
                            />
                          </Colxx>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">
                        <FormGroup row>
                          <Label sm="4">Available From</Label>
                          <Colxx sm="8">
                            <DatePicker
                              name="availableFrom"
                              selected={
                                availableFromDate
                                  ? new Date(availableFromDate)
                                  : null
                              }
                              dateFormat="dd/MM/yyyy"
                              onChange={(date) => setAvailableFromDate(date)}
                              placeholderText="dd/mm/yyyy"
                            />
                          </Colxx>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup row>
                          <Label sm="4">To</Label>
                          <Colxx sm="8">
                            <DatePicker
                              name="availableTo"
                              selected={
                                availableToDate
                                  ? new Date(availableToDate)
                                  : null
                              }
                              dateFormat="dd/MM/yyyy"
                              onChange={(date) => setAvailableToDate(date)}
                              placeholderText="dd/mm/yyyy"
                            />
                          </Colxx>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup row>
                          <Label sm="6">Max Availablility</Label>
                          <Colxx sm="6">
                            <Input
                              type="number"
                              name="seatsMax"
                              value={seatsMax}
                              onChange={(e) => {
                                setSeatsMax(e.target.value);
                              }}
                            />
                          </Colxx>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">
                        <FormGroup row>
                          <Label sm="4">Exam</Label>
                          <Colxx sm="8">
                            <Select
                              className="react-select mb-3"
                              name="venue"
                              options={[
                                { label: "TKT", value: "TKT" },
                                { label: "Linguaskill", value: "Linguaskill" },
                                { label: "CEQs", value: "CEQs" },
                              ]}
                              onChange={(e) => handleExamChange(e)}
                            />
                          </Colxx>
                        </FormGroup>
                      </Col>
                      {showRegions && (
                        <Col md="4">
                          <FormGroup row>
                            <Label sm="4">Select Regions</Label>
                            <Colxx sm="8">
                              <Select
                                className="react-select mb-3"
                                name="venue"
                                options={venues}
                                onChange={(e) =>
                                  setRegions(reactSelectChange(e))
                                }
                                isMulti
                              />
                            </Colxx>
                          </FormGroup>
                        </Col>
                      )}
                      {showModules && (
                        <Col md="4">
                          <FormGroup row>
                            <Label sm="4">Select Modules</Label>
                            <Colxx sm="8">
                              <Select
                                className="react-select mb-3"
                                name="module"
                                options={modules}
                                onChange={(e) =>
                                  setModulesApi(reactSelectChange(e))
                                }
                                isMulti
                              />
                            </Colxx>
                          </FormGroup>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <Col md="4">
                        <FormGroup row>
                          <Label sm="8">Paper Based</Label>
                          <Colxx sm="4">
                            <Switch
                              className="custom-switch custom-switch-primary-inverse"
                              checked={isPb}
                              onChange={(value) => setIsPb(value)}
                            />
                          </Colxx>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup row>
                          <Label sm="8">Computer Based</Label>
                          <Colxx sm="4">
                            <Switch
                              className="custom-switch custom-switch-primary-inverse"
                              checked={isCb}
                              onChange={(value) => setIsCb(value)}
                            />
                          </Colxx>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Colxx>
          </Row>
        )}
        <div>
          <Button
            color="light"
            className="top-right-button"
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>

          <Button
            color="primary"
            className="top-right-button ml-2"
            onClick={handleSubmit}
          >
            Add
          </Button>
        </div>
        &nbsp;
        <div>{errorMessage}</div>
      </div>
    </AppLayout>
  );
};

export default CreateSessions;

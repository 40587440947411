import React, { useState } from "react";
import { Row } from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";
import { CKEditor } from "ckeditor4-react";
import parse from "html-react-parser";

import AppLayout from "layout/AppLayout";

const Blank = () => {
  const [content, setContent] = useState("<p>Hello from CKEditor 4!</p>");
  const editorConfig = {
    toolbar: [
      ["Undo", "Redo"],
      ["Format", "Font", "FontSize"],
      ["TextColor", "BGColor"],
      ["JustifyLeft", "JustifyCenter", "JustifyRight", "JustifyBlock"],
      [
        "Bold",
        "Italic",
        "Underline",
        "Strike",
        "RemoveFormat",
        "CopyFormatting",
      ],
      ["Link", "Unlink"],
      [
        "NumberedList",
        "BulletedList",
        "-",
        "Outdent",
        "Indent",
        "-",
        "Blockquote",
      ],
      ["Image", "Table"],
      ["Scayt"],
    ],
    extraPlugins: "justify,format,font,colorbutton",
  };

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            Breadcrumb
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row>
          <Colxx xxs="12" className="mb-4">
            <h2>Editor</h2>
            <CKEditor
              initData={content}
              onChange={(event) => setContent(event.editor.getData())}
              config={editorConfig}
            />
            <h2>Content</h2>
            {parse(content)}
          </Colxx>
        </Row>
      </div>
    </AppLayout>
  );
};

export default Blank;

/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/display-name */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Row,
  UncontrolledDropdown,
} from "reactstrap"; //
import DatatablePagination from "components/DatatablePagination";
import AppLayout from "layout/AppLayout";
import axios from "axiosClient";
import { useToken } from "auth/useToken";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Select from "react-select";
import moment from "moment";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import VolumeDetails from "./volumeDetails";
import { useUser } from "auth/useUser";
import ListingTable2 from "components/ListingTable2";

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 20 },
    },
    useSortBy,
    usePagination
  );

  return (
    <>
      <table {...getTableProps()} className="r-table table text-left">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, columnIndex) => (
                <th key={`th_${columnIndex}`}>
                  {column.render("Header")}
                  <span />
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell, cellIndex) => (
                  <td
                    key={`td_${cellIndex}`}
                    {...cell.getCellProps({
                      className: cell.column.cellClass,
                    })}
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

      <DatatablePagination
        page={pageIndex}
        pages={pageCount}
        canPrevious={canPreviousPage}
        canNext={canNextPage}
        pageSizeOptions={[4, 10, 20, 30, 40, 50]}
        showPageSizeOptions={false}
        showPageJump={false}
        defaultPageSize={pageSize}
        onPageChange={(p) => gotoPage(p)}
        onPageSizeChange={(s) => setPageSize(s)}
        paginationMaxSize={pageCount}
      />
      <p>Total Sessions: {data.length}</p>
    </>
  );
}

const openStatusShow = (props) => {
  let now = new Date().toJSON();

  if (
    props.row.original.availableFromDate < now &&
    props.row.original.availableToDate > now
  ) {
    return "Open";
  } else {
    return "Closed";
  }
};

const SessionsList = () => {
  const user = useUser();
  const [isUser, setIsUser] = useState(user.role === "User" ? true : false);
  const cols = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        cellClass: "text-muted w-10",
        Cell: (props) => (
          <>
            {!isUser && (
              <NavLink to={`/app/dates/${props.row.original._id}`}>
                {props.value}
              </NavLink>
            )}
            {isUser && props.value}
          </>
        ),
        sortType: "basic",
      },
      {
        Header: "Date",
        accessor: "sessionDate",
        cellClass: "text-muted w-10",
        Cell: (props) => <>{props.value}</>,
        sortType: "basic",
      },
      {
        Header: "Module",
        accessor: "module",
        cellClass: "text-muted w-10",
        Cell: (props) => <>{props.value}</>,
        sortType: "basic",
      },
      {
        Header: "Test Region",
        accessor: "region",
        cellClass: "text-muted w-10",
        Cell: (props) => <>{props.value}</>,
        sortType: "basic",
      },
      {
        Header: "Session Info",
        accessor: "seatsMax",
        cellClass: "text-muted w-15",
        Cell: (props) => (
          <>
            <p>
              Capacity: {props.value}
              <br />
              Exam: {props.row.original.exam}
              <br />
              Open Status: <strong>{openStatusShow(props)}</strong>
              {props.row.original.isPb && (
                <>
                  <br />
                  <strong>Paper Based </strong>
                </>
              )}
              {props.row.original.isCb && (
                <>
                  <br />
                  <strong>Computer Based </strong>
                </>
              )}
            </p>
          </>
        ),
        sortType: "basic",
      },
      {
        Header: "Volume",
        accessor: "seatsBooked",
        cellClass: "text-muted w-15",
        Cell: (props) => (
          <>
            <p>Allocated: {props.value}</p>
            <VolumeDetails examId={props.row.original._id} />
          </>
        ),
        sortType: "basic",
      },
    ],
    []
  );
  const [token, setToken] = useToken();
  const [enquiriesList, setEnquiriesList] = useState([]);
  const [enquiriesTotal, setEnquiriesTotal] = useState(0);
  const [searchValues, setSearchValues] = useState({});
  const [showData, setShowData] = useState(false);
  const [regions, setRegions] = useState([]);
  const [modules, setModules] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [selectedPageSize, setSelectedPageSize] = useState(30);
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;
    axios
      .get("/api/dates-search-distinct", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        if (isMounted) {
          let tempRegions = [];
          result.data[0].distinctRegion.forEach((region) =>
            tempRegions.push({ label: region, value: region })
          );
          setRegions(tempRegions);
          let tempModules = [];
          result.data[0].distinctModule.forEach((module) =>
            tempModules.push({ label: module, value: module })
          );
          setModules(tempModules);
          setShowData(true);
        }
      });
    return () => {
      isMounted = false;
    };
  }, []);

  const handleSearchValueChange = (e) => {
    setSearchValues({ ...searchValues, [e.target.name]: e.target.value });
  };

  const handleSearchValueRegionChange = (e) => {
    setSearchValues({ ...searchValues, region: e.value });
  };

  const handleSearchValueModuleChange = (e) => {
    setSearchValues({ ...searchValues, module: e.value });
  };

  const handleSearchValueExamChange = (e) => {
    setSearchValues({ ...searchValues, exam: e.value });
  };

  const handleSearchSubmit = (
    currentPage = 1,
    selectedPageSize = 30,
    searchValues
  ) => {
    axios
      .post(
        "/api/enquiry-available-transfers",
        { page: currentPage, per_page: selectedPageSize, ...searchValues },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((result) => {
        setEnquiriesList(result.data.enquiries);
        setEnquiriesTotal(result.data.total);
        setShowData(true);
        setCurrentPage(result.data.page);
        setTotalPage(result.data.pageCount);
        setLoading(false);
      });
  };

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Card className="h-100 m-4">
          <CardBody>
            <CardTitle>Sessions</CardTitle>
            <Row className="text-left">
              <Col md="2">
                <FormGroup>
                  <Label>Region</Label>
                  <Select
                    value={{
                      label: searchValues.region,
                      value: searchValues.region,
                    }}
                    options={regions}
                    onChange={handleSearchValueRegionChange}
                  />
                </FormGroup>
              </Col>
              <Col md="2">
                <FormGroup>
                  <Label>Exam</Label>
                  <Select
                    value={{
                      label: searchValues.exam,
                      value: searchValues.exam,
                    }}
                    options={[
                      { label: "TKT", value: "TKT" },
                      { label: "Linguaskill", value: "Linguaskill" },
                      { label: "CEQs", value: "CEQs" },
                      { label: "TRKI", value: "TRKI" },
                    ]}
                    onChange={handleSearchValueExamChange}
                  />
                </FormGroup>
              </Col>
              <Col md="2">
                <FormGroup>
                  <Label>Module</Label>
                  <Select
                    value={{
                      label: searchValues.module,
                      value: searchValues.module,
                    }}
                    options={modules}
                    onChange={handleSearchValueModuleChange}
                  />
                </FormGroup>
              </Col>
              <Col md="2">
                <FormGroup>
                  <Label>From</Label>
                  <DatePicker
                    name="from"
                    selected={
                      searchValues.from ? new Date(searchValues.from) : null
                    }
                    dateFormat="dd/MM/yyyy"
                    placeholderText="dd/mm/yyyy"
                    onChange={(date) => {
                      setSearchValues({
                        ...searchValues,
                        from: moment(date).format("YYYY-MM-DD"),
                      });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="2">
                <FormGroup>
                  <Label>To</Label>
                  <DatePicker
                    name="to"
                    selected={
                      searchValues.to ? new Date(searchValues.to) : null
                    }
                    dateFormat="dd/MM/yyyy"
                    placeholderText="dd/mm/yyyy"
                    onChange={(date) => {
                      setSearchValues({
                        ...searchValues,
                        to: moment(date).format("YYYY-MM-DD"),
                      });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <Button
                  color="primary"
                  onClick={() =>
                    handleSearchSubmit(1, selectedPageSize, searchValues)
                  }
                >
                  Search
                </Button>
                <Button
                  color="primary"
                  className="ml-2"
                  onClick={() => navigate("create")}
                >
                  Create Sessions
                </Button>
              </Col>
            </Row>
            {/* <Table columns={cols} data={enquiriesList} /> */}
          </CardBody>
        </Card>
        {showData && (
          <ListingTable2
            columns={cols}
            data={enquiriesList}
            totalItemCount={enquiriesTotal}
            handleSearch={handleSearchSubmit}
            loading={loading}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPage={totalPage}
            selectedPageSize={selectedPageSize}
            setSelectedPageSize={setSelectedPageSize}
            searchValues={searchValues}
            withNumeration={false}
          />
        )}
      </div>
    </AppLayout>
  );
};

export default SessionsList;

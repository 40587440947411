import React, { useEffect, useState } from "react";
import {
  Row,
  Table,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Collapse,
  Card,
  CardBody,
} from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";
import Pagination from "containers/pages/Pagination";
import { useSortBy, useTable } from "react-table";

const pageSizes = [30, 50, 100];

const ListingTable = ({
  columns,
  data,
  totalItemCount,
  handleSearch,
  loading,
  currentPage,
  setCurrentPage,
  totalPage,
  withNumeration = true,
}) => {
  const [selectedPageSize, setSelectedPageSize] = useState(30);
  const [search, setSearch] = useState("");

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedPageSize]);

  useEffect(() => {
    handleSearch(currentPage, selectedPageSize, search);
  }, [selectedPageSize, currentPage, search]);

  const onSearchKey = (e) => {
    if (e.key === "Enter") {
      setSearch(e.target.value.toLowerCase());
    }
  };

  const startIndex = (currentPage - 1) * selectedPageSize;
  const endIndex = currentPage * selectedPageSize;

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useSortBy
    );

  return (
    <>
      <Row className="listing-top-options">
        <Colxx xxs="12">
          <div className="mb-2">
            <Collapse isOpen className="d-md-block" id="displayOptions">
              <div className="d-block d-md-inline-block pt-1">
                <div className="search-sm d-inline-block float-md-left mr-1 mb-1 align-top">
                  <input
                    type="text"
                    name="keyword"
                    id="search"
                    placeholder={`Search`}
                    onKeyPress={(e) => onSearchKey(e)}
                  />
                </div>
              </div>
              <div className="float-md-right pt-1">
                <span className="text-muted text-small mr-1">
                  {startIndex + 1}-
                  {totalItemCount >= endIndex ? endIndex : totalItemCount}
                  {` of `}
                  {totalItemCount}
                </span>
                <UncontrolledDropdown className="d-inline-block">
                  <DropdownToggle caret color="outline-dark" size="xs">
                    {selectedPageSize}
                  </DropdownToggle>
                  <DropdownMenu end>
                    {pageSizes.map((size, index) => {
                      return (
                        <DropdownItem
                          key={index}
                          onClick={() => setSelectedPageSize(size)}
                        >
                          {size}
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </Collapse>
          </div>
        </Colxx>
      </Row>
      <Row>
        <Colxx xxs="12">
          <Card>
            <CardBody>
              <div className="loading" hidden={!loading} />
              <Table
                responsive
                striped
                {...getTableProps()}
                className={`r-table table`}
              >
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column, columnIndex) => (
                        <th
                          key={`th_${columnIndex}`}
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          className={
                            column.isSorted
                              ? column.isSortedDesc
                                ? "sorted-desc"
                                : "sorted-asc"
                              : ""
                          }
                        >
                          {column.render("Header")}
                          <span />
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                  {rows.map((row, rowIndex) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell, cellIndex) => (
                          <td
                            key={`td_${cellIndex}`}
                            {...cell.getCellProps({
                              className: cell.column.cellClass + " listing-row",
                            })}
                          >
                            {withNumeration &&
                              cellIndex === 0 &&
                              Number(
                                (currentPage - 1) * selectedPageSize +
                                  rowIndex +
                                  1
                              )}
                            {withNumeration &&
                              cellIndex !== 0 &&
                              cell.render("Cell")}
                            {!withNumeration && cell.render("Cell")}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>

              <Pagination
                currentPage={currentPage}
                totalPage={totalPage}
                onChangePage={(i) => setCurrentPage(i)}
              />
            </CardBody>
          </Card>
        </Colxx>
      </Row>
    </>
  );
};
export default ListingTable;

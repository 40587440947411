/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/display-name */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import AppLayout from "layout/AppLayout";
import axios from "axiosClient";
import { useToken } from "auth/useToken";
import { Link, NavLink, useNavigate } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";
import ListingTable2 from "components/ListingTable2";

const EmailsList = () => {
  const cols = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "receiverName",
        cellClass: "text-muted w-10",
        Cell: (props) => (
          <>
            <NavLink to={`/app/emails/email/${props.row.original._id}`}>
              {props.value}
            </NavLink>
          </>
        ),
        sortType: "basic",
      },
      {
        Header: "Email",
        accessor: "receiverEmail",
        cellClass: "text-muted w-10",
        Cell: (props) => <>{props.value}</>,
        sortType: "basic",
      },
      {
        Header: "Date",
        accessor: "createdAt",
        cellClass: "text-muted w-10",
        Cell: (props) => <>{props.value}</>,
        sortType: "basic",
      },
      {
        Header: "Status",
        accessor: "isSent",
        cellClass: "text-muted w-10",
        Cell: (props) => (
          <>
            {props.value ? (
              <span className="btn btn-success py-1 px-3">Sent</span>
            ) : (
              <span className="btn btn-danger py-1 px-3">Error</span>
            )}
          </>
        ),
        sortType: "basic",
      },
    ],
    []
  );
  const [token, setToken] = useToken();
  const [emailsList, setEmailsList] = useState([]);
  const [total, setTotal] = useState(0);
  const [searchValues, setSearchValues] = useState({});
  const [showData, setShowData] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [selectedPageSize, setSelectedPageSize] = useState(30);
  const navigate = useNavigate();

  const handleSearchValueChange = (e) => {
    setSearchValues({ ...searchValues, [e.target.name]: e.target.value });
  };

  const handleSearchSubmit = (
    currentPage = 1,
    selectedPageSize = 30,
    searchValues
  ) => {
    axios
      .post(
        "/api/emails",
        { page: currentPage, per_page: selectedPageSize, ...searchValues },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((result) => {
        setEmailsList(result.data.emails);
        setShowData(true);
        setTotal(result.data.total);
        setCurrentPage(result.data.page);
        setTotalPage(result.data.pageCount);
        setLoading(false);
      });
  };

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Card className="h-100 m-4">
          <CardBody>
            <CardTitle>
              Emails{" "}
              <Button
                color="primary"
                className="top-right-button ml-3"
                onClick={() => navigate("/app/emails/send")}
              >
                Send
              </Button>
            </CardTitle>
            <Row className="text-left">
              <Col md="3">
                <FormGroup>
                  <Label>Name</Label>
                  <Input
                    type="text"
                    name="receiverName"
                    value={searchValues.receiverName}
                    onChange={handleSearchValueChange}
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>Email</Label>
                  <Input
                    type="text"
                    name="receiverEmail"
                    value={searchValues.receiverEmail}
                    onChange={handleSearchValueChange}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <Button
                  color="primary"
                  onClick={() =>
                    handleSearchSubmit(1, selectedPageSize, searchValues)
                  }
                >
                  Search
                </Button>
              </Col>
            </Row>
            {/* <Table columns={cols} data={emailsList} /> */}
          </CardBody>
        </Card>
        {showData && (
          <ListingTable2
            columns={cols}
            data={emailsList}
            totalItemCount={total}
            handleSearch={handleSearchSubmit}
            loading={loading}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPage={totalPage}
            selectedPageSize={selectedPageSize}
            setSelectedPageSize={setSelectedPageSize}
            searchValues={searchValues}
            withNumeration={false}
          />
        )}
      </div>
    </AppLayout>
  );
};

export default EmailsList;

const data = [
  {
    id: "tkt-regions",
    icon: "iconsminds-edit-map",
    label: "Regions",
    to: `/app/tkt-regions`,
    access: ["Admin", "Manager"],
  },
  {
    id: "sessions",
    icon: "iconsminds-check",
    label: "Sessions",
    to: `/app/sessions`,
    access: ["Admin", "Manager"],
  },
  {
    id: "enquiries",
    icon: "iconsminds-profile",
    label: "Enquiries",
    to: `/app/enquiries`,
    access: ["Admin", "Manager", "User"],
  },
  {
    id: "partners",
    icon: "iconsminds-handshake",
    label: "Partners",
    to: `/app/partners`,
    access: ["Admin", "Manager"],
  },
  {
    id: "bannedusers",
    icon: "iconsminds-male-female",
    label: "Banned Users",
    to: `/app/bannedusers`,
    access: ["Admin", "Manager"],
  },
  {
    id: "tkt-results",
    icon: "iconsminds-box-with-folders",
    label: "TKT-Results",
    to: `/app/tkt-results`,
    access: ["Admin", "Manager"],
  },
  {
    id: "certificate",
    icon: "iconsminds-diploma-2",
    label: "Certificate",
    to: `/app/certificate`,
    access: ["Admin", "Manager", "User"],
  },
  {
    id: "emails",
    icon: "iconsminds-email",
    label: "Emails",
    to: `/app/emails`,
    access: ["Admin", "Manager"],
  },
  {
    id: "broadcast",
    icon: "iconsminds-megaphone",
    label: "Broadcast",
    to: `/app/broadcast`,
    access: ["Admin", "Manager"],
  },
  {
    id: "sms",
    icon: "iconsminds-mail-send",
    label: "SMS",
    to: `/app/sms`,
    access: ["Admin", "Manager"],
  },
  {
    id: "mock",
    icon: "iconsminds-notepad",
    label: "TKT MOCK",
    to: `/app/mock`,
    access: ["Admin", "Manager"],
  },
  {
    id: "details",
    icon: "iconsminds-information",
    label: "Details",
    to: `/app/details`,
    access: ["Admin"],
  },
  {
    id: "users",
    icon: "iconsminds-conference",
    label: "Users",
    to: `/app/users`,
    access: ["Admin"],
  },
];
export default data;

import React, { useEffect, useState, useRef } from "react";
import axios from "axiosClient";
import {
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import { useToken } from "auth/useToken";
import Papa from "papaparse";
import { toast } from "react-toastify";

const SaveBulkResults = ({ toggle, setCandidates }) => {
  const [results, setResults] = useState([]);
  const [candidatesLoading, setCandidatesLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);
  const [token, setToken] = useToken();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSending(true);
    setCandidates(results);
    setSending(false);
    toast.success("Successfully saved");
    toggle();
  };

  return (
    <div>
      {!loading && (
        <Row>
          <Colxx xxs="12" className="mb-4">
            <Card className="mb-4">
              <CardBody>
                <Form className="text-left">
                  <Row>
                    <Col md="12">
                      Please, upload the .CSV file of results:
                      <FormGroup row>
                        <Colxx sm="9">
                          <Input
                            type="file"
                            accept=".csv"
                            onChange={(e) => {
                              const files = e.target.files;
                              if (files) {
                                setCandidatesLoading(true);
                                Papa.parse(files[0], {
                                  delimiter: ",",
                                  header: true,
                                  skipEmptyLines: true,
                                  transformHeader: function (h) {
                                    return h.replace(/ /g, "");
                                  },
                                  transform: function (h) {
                                    return h.replace(/[="\\]/g, "");
                                  },
                                  complete: function (results) {
                                    console.log("Finished:");
                                    console.log(results.data);
                                    setResults(results.data);
                                    setCandidatesLoading(false);
                                  },
                                });
                              }
                            }}
                          />
                        </Colxx>
                      </FormGroup>
                      {/* <>{JSON.stringify(candidates)}</> */}
                      {candidatesLoading ? (
                        <p>Loading...</p>
                      ) : (
                        <p>The number of results: {results.length}</p>
                      )}
                      {sending && (
                        <span className="btn btn-warning">Uploading...</span>
                      )}
                      {sent && <span className="btn btn-success">DONE!</span>}
                    </Col>
                  </Row>
                </Form>
                <div className="mt-3">
                  <Button
                    color="light"
                    className="top-right-button"
                    onClick={() => toggle()}
                  >
                    Close
                  </Button>
                  {!sent && (
                    <Button
                      color="primary"
                      className="ml-2 top-right-button"
                      onClick={handleSubmit}
                    >
                      Save
                    </Button>
                  )}
                </div>
                &nbsp;
                <div>{errorMessage}</div>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      )}
    </div>
  );
};

export default SaveBulkResults;

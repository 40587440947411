import React, { useState } from "react";
import axios from "axiosClient";
import { useNavigate } from "react-router-dom";
import {
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
} from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import AppLayout from "layout/AppLayout";
import { useToken } from "auth/useToken";
import { toast } from "react-toastify";

const TelegramBroadcast = () => {
  const navigate = useNavigate();

  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useToken();

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(
        `/api/sendmsgtoall`,
        { text: message },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((result) => {
        if (result.status === 200) {
          toast.success("The message has been broadcasted successfully");
        }
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data);
        setLoading(false);
      });
  };

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div className="mb-2">
              <h1>Telegram Broadcast</h1>
            </div>
            <Separator className="mb-5" />
          </Colxx>
        </Row>

        <Row>
          <Colxx xxs="12" className="mb-4">
            <Card className="mb-4">
              <CardBody>
                <Form>
                  <Row>
                    <Col md="8">
                      <FormGroup row>
                        <Label sm="3">Message</Label>
                        <Colxx sm="9">
                          <Input
                            type="textarea"
                            name="message"
                            style={{ height: "250px" }}
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                          />
                        </Colxx>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
        <div>
          <Button
            color="primary"
            className="top-right-button"
            onClick={() => navigate("/app/users")}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            className="top-right-button ml-2"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? "Sending..." : "Send"}
          </Button>
        </div>
      </div>
    </AppLayout>
  );
};

export default TelegramBroadcast;

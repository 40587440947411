import React, { useEffect, useState } from "react";
import { Button, Row, Card } from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import AppLayout from "layout/AppLayout";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useToken } from "auth/useToken";
import axios from "axiosClient";
import EditPartner from "./editModal";
import { toast } from "react-toastify";
import CandidatesList from "./candidatesList";

const PartnerDetails = () => {
  const location = useLocation();

  const id = location.pathname.split("/").slice(-1)[0];

  const [partner, setPartner] = useState({});
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useToken();

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    if (id) {
      axios
        .get(`/api/partner/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((result) => {
          setPartner(result.data);
          setLoading(false);
        })
        .catch((e) => {
          toast.error("something went wrong");
        });
    }
  }, [id]);

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div className="mb-2">
              <h1>Partner Details</h1>
              <br />
              <h1>{partner?.organization}</h1>
              <div
                className="text-zero top-right-button-container"
                style={{ display: "flex" }}
              >
                <Button
                  color="primary"
                  className="top-right-button ml-2"
                  onClick={() => navigate(-1)}
                >
                  Back
                </Button>
              </div>
            </div>
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        {!loading && <CandidatesList partner={partner} partnerId={id} />}
      </div>
    </AppLayout>
  );
};

export default PartnerDetails;

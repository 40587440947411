import React, { useEffect, useState } from "react";
import { Button, Row, Card, FormGroup } from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import Switch from "rc-switch";
import "rc-switch/assets/index.css";

import AppLayout from "layout/AppLayout";
import { NavLink, useNavigate } from "react-router-dom";
import { useToken } from "auth/useToken";
import axios from "axiosClient";
import EditPartner from "./editModal";
import { toast } from "react-toastify";

const PartnersList = () => {
  const [data, setData] = useState([]);
  const [count, setCount] = useState(null);
  const [word, setWord] = useState("");
  const [pages, setPages] = useState(1);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [archived, setArchived] = useState(false);
  const [token, setToken] = useToken();

  const [editModal, setEditModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [importModal, setImportModal] = useState(false);
  const [partnerId, setPartnerId] = useState(null);

  const navigate = useNavigate();

  const onSearchKey = (e) => {
    if (e.key === "Enter") {
      setLoading(true);
      handleSearch(e.target.value.toLowerCase());
    }
  };

  const handleSearch = (word) => {
    setWord(word);
    ///
    axios
      .post(
        `/api/partners`,
        { search: word, page: 1 },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((result) => {
        setData(result.data.partners);
        setCount(result.data.total);
        setPages(result.data.pageCount);
        setPage(result.data.page);
        setLoading(false);
      });
  };

  const handleLoadMore = () => {
    let postData = word ? { search: word, page: page + 1 } : { page: page + 1 };
    axios
      .post(`/api/partners`, postData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        setData([...data, ...result.data.partners]);
        setCount(result.data.total);
        setPages(result.data.pageCount);
        setPage(page + 1);
        setLoading(false);
      });
  };

  const toggleAddModal = () => {
    setAddModal(!addModal);
  };

  const toggleEditModal = () => {
    setEditModal(!editModal);
  };

  const handleClickPartner = (id) => {
    setPartnerId(id);
    toggleEditModal();
  };

  if (editModal) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }

  useEffect(() => {
    setLoading(true);
    axios
      .post(
        `/api/partners`,
        { archived },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((result) => {
        setData(result.data.partners);
        setCount(result.data.total);
        setPages(result.data.pageCount);
        setPage(result.data.page);
        setLoading(false);
      });
  }, [archived]);

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div className="mb-2">
              <h1>Partners List</h1>
              <div
                className="text-zero top-right-button-container"
                style={{ display: "flex" }}
              >
                <Button
                  color="primary"
                  className="top-right-button ml-2"
                  onClick={toggleAddModal}
                >
                  Add New
                </Button>
                {/* <Button
                  color="primary"
                  className="top-right-button ml-2"
                  onClick={() => navigate("/app/ieltspartners")}
                >
                  Partner Organizations
                </Button> */}
              </div>
            </div>
            <div className="mb-2">
              <Row>
                <Colxx>
                  <div className="d-block mb-2 d-md-inline-block">
                    <div className="search-sm d-inline-block float-md-left mr-1 mb-1 align-top">
                      <input
                        type="text"
                        name="keyword"
                        id="search"
                        placeholder={"Search"}
                        onKeyPress={(e) => onSearchKey(e)}
                      />
                    </div>
                    <span className="ml-2"> Total: {count}</span>
                  </div>
                </Colxx>
                <Colxx>
                  <FormGroup row>
                    <h4 className="mr-2">Archived</h4>
                    <Switch
                      className="custom-switch custom-switch-primary-inverse"
                      size="xs"
                      checked={archived}
                      onChange={(value) => setArchived(value)}
                    />{" "}
                  </FormGroup>
                </Colxx>
              </Row>
              {/* </Collapse> */}
            </div>

            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row>
          <div className="loading" hidden={!loading} />
          {count === null && (
            <p>Please search for the data from the input above. </p>
          )}
          {!loading && count !== null && data.length === 0 ? (
            <p> List is empty</p>
          ) : (
            data.map((user) => (
              <Colxx xxs="12" key={user._id} className="mb-3">
                <Card className="d-flex flex-row">
                  <div className="pl-2 d-flex flex-grow-1 min-width-zero">
                    <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
                      <span
                        onClick={() => navigate(user._id)}
                        className="w-40 w-sm-100 clickable"
                      >
                        <p className="list-item-heading mb-1 truncate">
                          {user.organization}
                        </p>
                      </span>
                      <span
                        onClick={() => handleClickPartner(user._id)}
                        className="w-15 w-sm-100 clickable"
                      >
                        <p className="mb-1 text-small">{user.login}</p>
                      </span>
                      <p className="mb-1 text-muted text-small w-15 w-sm-100">
                        {user.username}
                      </p>
                      <p className="mb-1 text-muted text-small w-15 w-sm-100">
                        Staff: {user.orgStaff}
                      </p>
                    </div>
                  </div>
                </Card>
              </Colxx>
            ))
          )}
          <Colxx xxs="12" className="mb-3">
            {!loading && data.length !== 0 && pages !== page && (
              <div className="col-12">
                <Button
                  color="primary"
                  className="top-right-button"
                  onClick={handleLoadMore}
                >
                  More...
                </Button>
              </div>
            )}
          </Colxx>
        </Row>
        {editModal && (
          <div className="in-modal">
            <div className="modal-overlay"></div>
            <div className="in-modal-content">
              <EditPartner toggle={toggleEditModal} partnerId={partnerId} />
            </div>
          </div>
        )}
        {addModal && (
          <div className="in-modal">
            <div className="modal-overlay"></div>
            <div className="in-modal-content">
              <EditPartner toggle={toggleAddModal} partnerId={null} />
            </div>
          </div>
        )}
      </div>
    </AppLayout>
  );
};

export default PartnersList;

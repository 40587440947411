import React, { useEffect, useState, useRef } from "react";
import axios from "axiosClient";
import {
  Link,
  useNavigate,
  useParams,
  useLocation,
  NavLink,
} from "react-router-dom";
import {
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
  FormFeedback,
} from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import AppLayout from "layout/AppLayout";
import { useToken } from "auth/useToken";
import Select from "react-select";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useUser } from "auth/useUser";
import { toast } from "react-toastify";
import { modules } from "utils/constants";
import ConfirmModal from "components/ConfirmModal";

const PaymentView = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [errorMessage, setErrorMessage] = useState("");
  const [thisId, setThisId] = useState(
    location.pathname.split("/").slice(-1)[0]
  );
  const [isNew, setIsNew] = useState(
    location.pathname.split("/").slice(-1)[0] === "add"
  );
  const [values, setValues] = useState({});
  const [validate, setValidate] = useState({
    emailState: "",
    passportState: "",
  });
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useToken();
  const [examModules, setExamModules] = useState([]);
  const [duplicated, setDuplicated] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const user = useUser();

  const [isUser, setIsUser] = useState(user.role === "User" ? true : false);

  // const { id } = useParams();

  const handleDelete = () => {
    axios
      .delete(`/api/payments/${thisId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        navigate(-1);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (errorMessage === "") {
      axios
        .post(
          `/api/payments/${thisId}`,
          { ...values, editor: values.editor ? values.editor : user.username },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          if (result.status === 200) {
            if (duplicated) {
              navigate(-1);
            } else if (isNew) {
              navigate(0);
              toast.success("Payment added successfully");
            } else {
              navigate(-1);
            }
          }
        })
        .catch((err) => {
          setErrorMessage(err.response.data);
        });
    }
  };

  useEffect(() => {
    !isNew && setLoading(true);
    !isNew &&
      axios
        .get(`/api/payments/${thisId}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((result) => {
          setValues(result.data);
          setLoading(false);
        });
  }, []);

  const handleValuesChange = (e) => {
    if (e.target.name === "passportId") {
      setValues({
        ...values,
        [e.target.name]: e.target.value.toUpperCase().replace(" ", ""),
      });
    } else {
      setValues({ ...values, [e.target.name]: e.target.value });
    }
    setErrorMessage("");
  };

  const validatePassportId = (e) => {
    let rex = /[A-Z]{2}[0-9]{7}/;
    if (rex.test(e.target.value)) {
      setValidate({ ...validate, passportState: "has-success" });
    } else {
      setValidate({ ...validate, passportState: "has-danger" });
    }
  };

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div className="mb-2">
              <h1>Payment details #{values.enquiryId}</h1>
            </div>
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        {!loading && (
          <Row>
            <Colxx xxs="12" className="mb-4">
              <Card className="mb-4 text-left">
                <CardBody>
                  <Form>
                    <Row>
                      <Col md="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <Label>First Name</Label>
                              <Input
                                required
                                type="text"
                                name="firstName"
                                value={values.firstName}
                                onChange={handleValuesChange}
                                disabled={
                                  user.canEdit ? false : isNew ? false : true
                                }
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Label>Last Name</Label>
                              <Input
                                required
                                type="text"
                                name="lastName"
                                value={values.lastName}
                                onChange={handleValuesChange}
                                disabled={
                                  user.canEdit ? false : isNew ? false : true
                                }
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Label>Passport Number</Label>
                              <Input
                                required
                                type="text"
                                name="candidatePassport"
                                valid={validate.passportState === "has-success"}
                                invalid={
                                  validate.passportState === "has-danger"
                                }
                                value={values.candidatePassport}
                                onChange={(e) => {
                                  validatePassportId(e);
                                  handleValuesChange(e);
                                }}
                                disabled={
                                  user.canEdit ? false : isNew ? false : true
                                }
                              />{" "}
                              <FormFeedback>
                                Please input a correct passport or ID number.
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Label>Exam Date</Label>
                              <Input
                                required
                                type="text"
                                name="examDate"
                                value={values.examDate}
                                onChange={handleValuesChange}
                                disabled={
                                  user.role === "Admin" ? false : !isNew
                                }
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Label>Exam</Label>
                              <Select
                                required
                                className="react-select mb-3"
                                value={{
                                  label: values.exam,
                                  value: values.exam,
                                }}
                                name="exam"
                                options={[
                                  { label: "TKT", value: "TKT" },
                                  {
                                    label: "Linguaskill",
                                    value: "Linguaskill",
                                  },
                                  { label: "IELTS", value: "IELTS" },
                                  { label: "Celta", value: "Celta" },
                                  { label: "TRKI", value: "TRKI" },
                                ]}
                                onChange={(e) => {
                                  setValues({
                                    ...values,
                                    exam: e.value,
                                  });
                                  let mods = modules[e.value].map((mod) => ({
                                    label: mod,
                                    value: mod,
                                  }));
                                  setExamModules(mods);
                                }}
                                isDisabled={
                                  user.role === "Admin" ? false : !isNew
                                }
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Label>Exam Module</Label>
                              <Select
                                required
                                className="react-select mb-3"
                                value={{
                                  label: values.module,
                                  value: values.module,
                                }}
                                name="module"
                                options={examModules}
                                onChange={(e) =>
                                  setValues({
                                    ...values,
                                    module: e.value,
                                  })
                                }
                                isDisabled={
                                  user.role === "Admin" ? false : !isNew
                                }
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Label>Payment Method</Label>
                              <Select
                                required
                                className="react-select mb-3"
                                value={{
                                  label: values.paymentMethod,
                                  value: values.paymentMethod,
                                }}
                                name="module"
                                options={[
                                  { label: "Bank", value: "Bank" },
                                  { label: "UPay", value: "UPay" },
                                  { label: "Click", value: "Click" },
                                  { label: "Payme", value: "Payme" },
                                  { label: "Cash", value: "Cash" },
                                  { label: "Terminal", value: "Terminal" },
                                  { label: "Free Seat", value: "Free Seat" },
                                ]}
                                onChange={(e) =>
                                  setValues({
                                    ...values,
                                    paymentMethod: e.value,
                                  })
                                }
                                isDisabled={
                                  user.role === "Admin" ? false : !isNew
                                }
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Label>Amount</Label>
                              <Input
                                required
                                type="text"
                                name="amount"
                                value={values.amount}
                                onChange={handleValuesChange}
                                disabled={
                                  user.role === "Admin" ? false : !isNew
                                }
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Label>Enquiry ID</Label>
                              <Input
                                required
                                type="text"
                                name="enquiryId"
                                value={values.enquiryId}
                                onChange={handleValuesChange}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Label>Transaction ID</Label>
                              <Input
                                required
                                type="text"
                                name="transactionId"
                                value={values.transactionId}
                                onChange={handleValuesChange}
                                disabled={
                                  user.canEdit ? false : isNew ? false : true
                                }
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Label>PINPP</Label>
                              <Input
                                required
                                type="text"
                                name="pinpp"
                                value={values.pinpp}
                                onChange={handleValuesChange}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Label>Processed By</Label>
                              <Input
                                required
                                type="text"
                                name="processedBy"
                                value={values.processedBy}
                                onChange={handleValuesChange}
                              />
                            </FormGroup>
                          </Col>
                          {!isNew && (
                            <Col md="6">
                              <FormGroup>
                                <Label>Created At</Label>
                                <Input
                                  required
                                  type="text"
                                  name="createdAt"
                                  value={moment(values.createdAt).format(
                                    "DD/MM/YYYY"
                                  )}
                                  disabled
                                />
                              </FormGroup>
                            </Col>
                          )}
                          {!isNew && user.role === "Admin" && (
                            <Col md="6">
                              <FormGroup>
                                <Label>Editor</Label>
                                <Input
                                  type="text"
                                  name="editor"
                                  value={values.editor}
                                  disabled
                                />
                              </FormGroup>
                            </Col>
                          )}
                          <Col md="12">
                            <FormGroup>
                              <Label>Comments</Label>
                              <Input
                                type="textarea"
                                name="comments"
                                value={values.comments}
                                onChange={handleValuesChange}
                                disabled={
                                  user.canEdit ? false : isNew ? false : true
                                }
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <p className="text-danger">{errorMessage}</p>
                      </Col>

                      <div>
                        <Button
                          color="primary"
                          className="top-right-button"
                          onClick={() => navigate(-1)}
                        >
                          Back
                        </Button>
                        {!isNew && (
                          <Button
                            color="primary"
                            className="top-right-button ml-2"
                            onClick={() => {
                              setIsNew(true);
                              setThisId("add");
                              let val = { ...values };
                              delete val._id;
                              delete val.createdAt;
                              delete val.updatedAt;
                              setValues(val);
                              setDuplicated(true);
                            }}
                          >
                            Duplicate
                          </Button>
                        )}
                        {!isNew && user.role === "Admin" && (
                          <Button
                            color="danger"
                            className="top-right-button ml-2"
                            onClick={() => setDeleteModal(!deleteModal)}
                          >
                            Delete
                          </Button>
                        )}

                        <Button
                          color="primary"
                          className="top-right-button ml-2"
                          onClick={handleSubmit}
                        >
                          {isNew ? "Add" : "Update"}
                        </Button>
                      </div>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Colxx>
          </Row>
        )}
        {deleteModal && (
          <ConfirmModal
            modal={deleteModal}
            setModal={setDeleteModal}
            title={"Delete Payment"}
            body={`Do you really want to delete the payment?`}
            action={handleDelete}
          />
        )}
      </div>
    </AppLayout>
  );
};

export default PaymentView;

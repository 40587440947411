import React, { useEffect, useState, useRef } from "react";
import axios from "axiosClient";
import { read, utils } from "xlsx";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import { useToken } from "auth/useToken";
import { toast } from "react-toastify";
import Select from "react-select";

const SendSmsModal = ({ toggle, searchValues }) => {
  const navigate = useNavigate();
  const [body, setBody] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);
  const [single, setSingle] = useState(false);
  const [selectValue, setSelectValue] = useState({
    value: null,
    label: "Select...",
  });
  const [token, setToken] = useToken();

  const selectOptions = [
    { label: "TKT Time and Location", value: "6421c07b50c33d8fd140481a" },
    { label: "LS Time and Location", value: "649920082767629c24517018" },
    { label: "TKT Results", value: "6499204c2767629c24517019" },
  ];

  const getDetailTexts = async (id) => {
    const result = await axios.get(`/api/details/info/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return result?.data?.body;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSending(true);

    try {
      if (errorMessage === "") {
        axios
          .post(
            `/api/send-sms-query`,
            { ...searchValues, content: body, single, type: selectValue.label },
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((result) => {
            // console.log(result.data);
            setSent(true);
            setSending(false);
          })
          .catch((err) => {
            setErrorMessage(err.response.data);
          });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      {!loading && (
        <Row>
          <Colxx xxs="12" className="mb-4">
            <Card className="mb-4">
              <CardBody>
                <Form>
                  <Row>
                    <Col md="8">
                      <FormGroup row>
                        <Label sm="3">Type</Label>
                        <Colxx sm="9">
                          <Select
                            value={{
                              label: selectValue.label,
                              value: selectValue.value,
                            }}
                            options={selectOptions}
                            onChange={async (e) => {
                              setSelectValue(e);
                              let text = await getDetailTexts(e.value);
                              setBody(text);
                            }}
                          />
                        </Colxx>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="8">
                      <FormGroup row>
                        <Label sm="3">Message</Label>
                        <Colxx sm="9">
                          <Input
                            type="textarea"
                            name="message"
                            style={{ height: "250px" }}
                            value={body}
                            onChange={(e) => setBody(e.target.value)}
                          />
                        </Colxx>
                      </FormGroup>
                      <FormGroup row className="text-left">
                        <Colxx sm="3"></Colxx>
                        <Colxx sm="9">
                          <Button
                            color="primary"
                            outline
                            onClick={() => setSingle(!single)}
                            active={single}
                          >
                            Send as single?
                          </Button>
                        </Colxx>
                      </FormGroup>
                      <br />
                      {sending && (
                        <span className="btn btn-warning">Sending...</span>
                      )}
                      {sent && <span className="btn btn-success">DONE!</span>}
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      )}
      <div>
        <Button
          color="light"
          className="top-right-button"
          onClick={() => toggle()}
        >
          Close
        </Button>
        {!sent && (
          <Button
            color="primary"
            className="ml-2 top-right-button"
            onClick={handleSubmit}
          >
            Send
          </Button>
        )}
      </div>
      &nbsp;
      <div>{errorMessage}</div>
    </div>
  );
};

export default SendSmsModal;

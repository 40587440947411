import React, { useState } from "react";
import axios from "axiosClient";
import { useNavigate } from "react-router-dom";
import {
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
} from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import AppLayout from "layout/AppLayout";
import { useToken } from "auth/useToken";
import { toast } from "react-toastify";
import Select from "react-select";
import SaveBulkResults from "./saveBulkModal";

const SmsBroadcast = () => {
  const navigate = useNavigate();

  const [message, setMessage] = useState("");
  const [bot, setBot] = useState("");
  const [candidates, setCandidates] = useState(false);
  const [contentTkt, setContentTkt] = useState("");
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);
  const [token, setToken] = useToken();
  const [importModal, setImportModal] = useState(false);

  const toggleImportModal = () => {
    setImportModal(!importModal);
  };

  const getResultTexts = () => {
    axios
      .get(`/api/details/info/6499204c2767629c24517019`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        setContentTkt(result?.data?.body);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (bot === "TKT Result") {
      setSending(true);
      axios
        .post(
          `/api/send-sms-tkt-results`,
          {
            candidates,
            content: contentTkt,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((result) => {
          if (result.status === 200) {
            setSending(false);
            toast.success("SMS sent successfully");
          } else {
            toast.error("Something went wrong");
          }
        });
    } else {
      axios
        .post(
          `/api/send-sms`,
          { text: message, bot },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((result) => {
          if (result.status === 200) {
            toast.success("The sms has been sent successfully");
          }
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err.response.data);
          setLoading(false);
        });
    }
  };

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div className="mb-2">
              <h1>Send SMS</h1>
            </div>
            <Separator className="mb-5" />
          </Colxx>
        </Row>

        <Row>
          <Colxx xxs="12" className="mb-4">
            <Card className="mb-4">
              <CardBody>
                <Form>
                  <Row>
                    <Col md="8">
                      <FormGroup row>
                        <Label sm="3">Type</Label>
                        <Colxx sm="9">
                          <Select
                            className="react-select"
                            classNamePrefix="react-select"
                            name="venue"
                            onChange={(e) => {
                              setBot(e.value);
                              if (e.value === "TKT Result") {
                                getResultTexts();
                              }
                            }}
                            options={[
                              { label: "TKT Result", value: "TKT Result" },
                            ]}
                          />
                        </Colxx>
                      </FormGroup>
                    </Col>
                    <Col md="4" className="text-left">
                      {bot === "TKT Result" && (
                        <Button
                          color="light"
                          className="top-right-button"
                          onClick={toggleImportModal}
                        >
                          Import
                        </Button>
                      )}
                    </Col>
                  </Row>
                  {bot === "TKT Result" ? (
                    <Row>
                      <Col md="12">
                        <FormGroup row>
                          <Label sm="3">Message</Label>
                          <Colxx sm="9">
                            <Input
                              type="textarea"
                              name="message"
                              style={{ height: "250px" }}
                              value={contentTkt}
                              onChange={(e) => setContentTkt(e.target.value)}
                            />
                          </Colxx>
                        </FormGroup>
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col md="8">
                        <FormGroup row>
                          <Label sm="3">Message</Label>
                          <Colxx sm="9">
                            <Input
                              type="textarea"
                              name="message"
                              style={{ height: "250px" }}
                              value={message}
                              onChange={(e) => setMessage(e.target.value)}
                            />
                          </Colxx>
                        </FormGroup>
                      </Col>
                    </Row>
                  )}
                </Form>
                <div>
                  <Button
                    color="light"
                    className="top-right-button"
                    onClick={() => navigate("/app")}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    className="top-right-button ml-2"
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    {loading ? "Sending..." : "Send"}
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
        {importModal && (
          <div className="in-modal">
            <div className="modal-overlay"></div>
            <div className="in-modal-content">
              <SaveBulkResults
                toggle={toggleImportModal}
                setCandidates={setCandidates}
              />
            </div>
          </div>
        )}
      </div>
    </AppLayout>
  );
};

export default SmsBroadcast;

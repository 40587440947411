import React, { useEffect, useState, useRef } from "react";
import axios from "axiosClient";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";
import { CKEditor } from "ckeditor4-react";
import parse from "html-react-parser";

import AppLayout from "layout/AppLayout";
import { useToken } from "auth/useToken";
import { TextEditor } from "components/text-editor";
import { editorDetailsSuggests } from "utils/constants";

const editorConfig = {
  toolbar: [
    ["Undo", "Redo"],
    ["Format", "Font", "FontSize"],
    ["TextColor", "BGColor"],
    ["JustifyLeft", "JustifyCenter", "JustifyRight", "JustifyBlock"],
    ["Bold", "Italic", "Underline", "Strike", "RemoveFormat", "CopyFormatting"],
    ["Link", "Unlink"],
    [
      "NumberedList",
      "BulletedList",
      "-",
      "Outdent",
      "Indent",
      "-",
      "Blockquote",
    ],
    ["Image", "Table"],
    ["Scayt"],
  ],
  extraPlugins: "justify,format,font,colorbutton",
};

const AddDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [data, setData] = useState({});
  const [body, setBody] = useState(data.body || "");

  const [errorMessage, setErrorMessage] = useState("");
  const [isNew, setIsNew] = useState(
    location.pathname.split("/").slice(-1)[0] === "add"
  );
  const [showEditor, setShowEditor] = useState(false);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useToken();

  const { id } = useParams();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (errorMessage === "") {
      let postData =
        body === "" ? data : { ...data, body, values: body.match(/#(.*?)#/g) };
      axios
        .post(`/api/details/info`, postData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((result) => {
          if (result.data.success) {
            navigate("/app/details");
          }
        })
        .catch((err) => {
          setErrorMessage(err.response.data);
        });
    }
  };

  useEffect(() => {
    !isNew && setLoading(true);
    !isNew &&
      axios
        .get(`/api/details/info/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((result) => {
          setData(result.data);
          setBody(result.data.body);
          setShowEditor(true);
          setLoading(false);
        });
  }, [id, isNew]);

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div className="mb-2">
              <h1>{data.title}</h1>
            </div>
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        {!loading && (
          <Row>
            <Colxx xxs="12" className="mb-4">
              <Card className="mb-4">
                <CardBody>
                  {/* <Form> */}
                  <Row>
                    <Col md="12" className="text-left">
                      {data.isEmail && (
                        <>
                          <FormGroup>
                            <h2>Email Subject</h2>
                            <Input
                              type="text"
                              name="subject"
                              value={data.subject}
                              onChange={(e) =>
                                setData({ ...data, subject: e.target.value })
                              }
                            />
                          </FormGroup>
                          <h2>Email Content</h2>
                          {showEditor && (
                            <TextEditor
                              onUpdate={setBody}
                              defaultValue={data.body}
                              fields={editorDetailsSuggests}
                            />
                            // <CKEditor
                            //   initData={data.body}
                            //   onChange={(event) =>
                            //     setBody(event.editor.getData())
                            //   }
                            //   config={editorConfig}
                            // />
                          )}
                        </>
                      )}
                      {!data.isEmail && (
                        <>
                          <FormGroup>
                            <h2>Content</h2>
                            <Input
                              type="textarea"
                              name="message"
                              style={{ height: "250px" }}
                              value={body}
                              onChange={(e) => setBody(e.target.value)}
                            />
                          </FormGroup>
                        </>
                      )}
                    </Col>
                    <Col md="6" className="mt-3">
                      <FormGroup row>
                        <Colxx xxs="3">
                          <Label>Group</Label>
                        </Colxx>
                        <Colxx xxs="9">
                          <Input
                            type="text"
                            name="group"
                            value={data.group}
                            onChange={(e) =>
                              setData({ ...data, group: e.target.value })
                            }
                          />
                        </Colxx>
                      </FormGroup>
                    </Col>
                  </Row>
                  {/* </Form> */}
                </CardBody>
              </Card>
            </Colxx>
          </Row>
        )}
        <div>
          <Button
            color="primary"
            className="top-right-button"
            onClick={() => navigate("/app/details")}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            className="top-right-button ml-2"
            onClick={handleSubmit}
          >
            {isNew ? "Add" : "Update"}
          </Button>
        </div>
        &nbsp;
        <div>{errorMessage}</div>
      </div>
    </AppLayout>
  );
};

export default AddDetail;

import React, { useEffect, useState } from "react";
import { Button, Row, Card } from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import AppLayout from "layout/AppLayout";
import { NavLink, useNavigate } from "react-router-dom";
import { useToken } from "auth/useToken";
import axios from "axiosClient";
import Select from "react-select";
import { toast } from "react-toastify";
import EditCertificateCandidate from "./editModal";
import moment from "moment";

const CandidatesListCertificate = () => {
  const [data, setData] = useState([]);
  const [count, setCount] = useState(null);
  const [word, setWord] = useState("");
  const [pages, setPages] = useState(1);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useToken();

  const [importModal, setImportModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [candidateId, setCandidateId] = useState(null);

  const navigate = useNavigate();

  const onSearchKey = (e) => {
    if (e.key === "Enter") {
      setLoading(true);
      handleSearch(e.target.value.toLowerCase());
    }
  };

  const handleSearch = (word) => {
    setWord(word);

    ///
    axios
      .post(
        `/api/candidates`,
        { search: word, page },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((result) => {
        setData(result.data.candidates);
        setCount(result.data.total);
        setPages(result.data.pageCount);
        setPage(result.data.page);
        setLoading(false);
      });
  };

  const handleLoadMore = () => {
    let postData = word ? { search: word, page: page + 1 } : { page: page + 1 };
    axios
      .post(`/api/candidates`, postData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        setData([...data, ...result.data.candidates]);
        setCount(result.data.total);
        setPages(result.data.pageCount);
        setPage(page + 1);
        setLoading(false);
      });
  };

  const toggleEditModal = () => {
    setEditModal(!editModal);
  };

  const handleClickCandidate = (id) => {
    setCandidateId(id);
    toggleEditModal();
  };

  if (importModal || editModal) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }

  useEffect(() => {
    axios
      .post(
        `/api/candidates`,
        { certificate: true },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((result) => {
        setData(result.data.candidates);
        setCount(result.data.total);
        setPages(result.data.pageCount);
        setPage(result.data.page);
        setLoading(false);
      });
  }, [importModal]);

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div className="mb-2">
              <h1>Candidates List (Certificate)</h1>
            </div>
            <div className="mb-2 justify-content-between">
              <div className="d-block mb-2 d-md-inline-block">
                <div className="search-sm d-inline-block float-md-left mr-1 mb-1 align-top">
                  {/* <div className="d-block d-md-inline-block pt-1">
                <div className="search-sm d-inline-block mb-1 "> */}
                  <input
                    type="text"
                    name="keyword"
                    id="search"
                    placeholder={"Search"}
                    onKeyPress={(e) => onSearchKey(e)}
                  />
                  {/* </div> */}

                  {/* </div> */}
                </div>
                <span className="ml-2"> Total: {count}</span>
              </div>
            </div>
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row>
          <div className="loading" hidden={!loading} />
          {count === null && (
            <p>Please search for the data from the input above. </p>
          )}
          {!loading && count !== null && data.length === 0 ? (
            <p> List is empty</p>
          ) : (
            data.map((user) => (
              <Colxx xxs="12" key={user._id} className="mb-3">
                <Card className="d-flex flex-row">
                  <div className="pl-2 d-flex flex-grow-1 min-width-zero">
                    <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
                      <span
                        onClick={() => handleClickCandidate(user._id)}
                        className="w-40 w-sm-100 clickable"
                      >
                        <p className="list-item-heading mb-1 truncate">
                          {user.firstName} {user.lastName}
                        </p>
                      </span>
                      <span
                        onClick={() => handleClickCandidate(user._id)}
                        className="w-15 w-sm-100 clickable"
                      >
                        <p className="mb-1 text-small">
                          {user.enquiryId}{" "}
                          {user.collectedSigned && (
                            <i
                              className={"simple-icon-check"}
                              style={{
                                backgroundColor: "green",
                                color: "white",
                                padding: "3px",
                                borderRadius: "50%",
                              }}
                            />
                          )}
                        </p>
                      </span>
                      <p className="mb-1 text-muted text-small w-15 w-sm-100">
                        {user.passportId}
                      </p>
                      <p className="mb-1 text-muted text-small w-15 w-sm-100">
                        Exam: {user.exam}{" "}
                        {moment(user.examDate).format("DD/MM/YYYY")}
                      </p>
                      <p className="mb-1 text-muted text-small w-15 w-sm-100">
                        {user.module}
                      </p>
                      <p className="mb-1 text-muted text-small w-15 w-sm-100">
                        {user.candidateNumber}
                      </p>
                    </div>
                  </div>
                </Card>
              </Colxx>
            ))
          )}
          <Colxx xxs="12" className="mb-3">
            {!loading && data.length !== 0 && pages !== page && (
              <div className="col-12">
                <Button
                  color="primary"
                  className="top-right-button"
                  onClick={handleLoadMore}
                >
                  More...
                </Button>
              </div>
            )}
          </Colxx>
        </Row>
        {editModal && (
          <div className="in-modal">
            <div className="modal-overlay"></div>
            <div className="in-modal-content">
              <EditCertificateCandidate
                toggle={toggleEditModal}
                candidateId={candidateId}
              />
            </div>
          </div>
        )}
      </div>
    </AppLayout>
  );
};

export default CandidatesListCertificate;

// FieldSuggestion.ts
import { Extension } from "@tiptap/core";
import Suggestion from "@tiptap/suggestion";

export const FieldSuggestion = Extension.create({
  name: "fieldSuggestion",

  addOptions() {
    return {
      suggestion: {
        char: "#",
        command: ({ editor, range, props }) => {
          editor
            .chain()
            .focus()
            .insertContentAt(range, `##${props.fieldName}##`)
            .run();

          window.getSelection()?.removeAllRanges();
        },
      },
    };
  },

  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        ...this.options.suggestion,
      }),
    ];
  },
});

/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/display-name */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  UncontrolledDropdown,
} from "reactstrap"; //
import DatatablePagination from "components/DatatablePagination";
import AppLayout from "layout/AppLayout";
import axios from "axiosClient";
import { useToken } from "auth/useToken";
import { Link, NavLink } from "react-router-dom";
import Select from "react-select";
import { handleSendPaymentConfirmationMail } from "utils/sendEmails";
import moment from "moment";
import DatePicker from "react-datepicker";
import { useUser } from "auth/useUser";

import "react-datepicker/dist/react-datepicker.css";
import DownloadAttendanceModal from "./downloadAttendanceModal";
import SaveGrades from "./saveGradesModal";
import SendEmailModal from "./emailModal";
import SendSmsModal from "./smsModal";
import ListingTable2 from "components/ListingTable2";
import { reactSelectChange } from "utils/reactSelectChange";

const EnquiriesList = () => {
  const user = useUser();
  const cols = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "enquiryId",
        cellClass: "text-muted w-10",
        Cell: (props) => (
          <>
            <NavLink to={`/app/enquiries/enquiry/${props.row.original._id}`}>
              {props.value}
            </NavLink>
          </>
        ),
        sortType: "basic",
      },
      {
        Header: "Passport No",
        accessor: "passportId",
        cellClass: "text-muted w-10",
        Cell: (props) => <>{props.value}</>,
        sortType: "basic",
      },
      {
        Header: "Candidate",
        accessor: "firstName",
        cellClass: "text-muted w-20",
        Cell: (props) => (
          <>
            <NavLink to={`/app/enquiries/enquiry/${props.row.original._id}`}>
              {props.row.original.firstName} {props.row.original.lastName}
            </NavLink>
          </>
        ),
        sortType: "basic",
      },
      {
        Header: "Module",
        accessor: "module",
        cellClass: "text-muted w-10",
        Cell: (props) => (
          <>
            {props.value}
            {props.row.original.isCb && (
              <>
                <br />
                <strong>Computer Based</strong>
              </>
            )}
          </>
        ),
        sortType: "basic",
      },
      {
        Header: "Location",
        accessor: "region",
        cellClass: "text-muted w-8",
        Cell: (props) => <>{props.value}</>,
        sortType: "basic",
      },
      {
        Header: "Session",
        accessor: "examId",
        cellClass: "text-muted w-10",
        Cell: (props) => (
          <>
            <NavLink to={`/app/dates/${props.value}`}>
              {props.row.original.examDate}
            </NavLink>
          </>
        ),
        sortType: "basic",
      },
      {
        Header: "Booking Status",
        accessor: "status",
        cellClass: "text-muted w-10",
        Cell: (props) => (
          <>
            {props.value}
            <br />
            <strong>{props.row.original.partner}</strong>
            <br />
            <span style={{ backgroundColor: "#FBFFB1" }}>
              {props.row.original.requestedFreeSeat ? "Free Seat" : ""}
            </span>
          </>
        ),
        sortType: "basic",
      },
      {
        Header: "Booking Time",
        accessor: "createdAt",
        cellClass: "text-muted w-10",
        Cell: (props) => <>{moment(props.value).format("DD/MM/YYYY, HH:mm")}</>,
        sortType: "basic",
      },
      {
        Header: "Actions",
        cellClass: "text-muted w-10",
        Cell: (props) => (
          <div className="btn-group">
            <UncontrolledDropdown>
              <DropdownToggle caret color="primary" className="btn-xs" outline>
                Action
              </DropdownToggle>
              <DropdownMenu end>
                <DropdownItem
                  onClick={() =>
                    handleSendPaymentConfirmationMail(
                      props.row.original._id,
                      token,
                      props.row.original.exam
                    )
                  }
                >
                  Resend Payment Confirmation
                </DropdownItem>
                {user.role !== "User" && (
                  <DropdownItem
                    onClick={() => handleDelete(props.row.original._id)}
                  >
                    Delete
                  </DropdownItem>
                )}
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        ),
        sortType: "basic",
      },
    ],
    []
  );
  const [token, setToken] = useToken();
  const [enquiriesList, setEnquiriesList] = useState([]);
  const [enquiriesTotal, setEnquiriesTotal] = useState(0);
  const [searchValues, setSearchValues] = useState({});
  const [showData, setShowData] = useState(false);
  const [regions, setRegions] = useState([]);
  const [modules, setModules] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [partners, setPartners] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const [attendanceModal, setAttendanceModal] = useState(false);
  const [importGradesModal, setImportGradesModal] = useState(false);
  const [smsModal, setSmsModal] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [selectedPageSize, setSelectedPageSize] = useState(30);
  const [isUser, setIsUser] = useState(user.role === "User" ? true : false);

  useEffect(() => {
    let isMounted = true;
    axios
      .get("/api/enquiries-search-distinct", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        if (isMounted) {
          let tempRegions = [];
          result.data[0].distinctRegion.forEach((region) =>
            tempRegions.push({ label: region, value: region })
          );
          setRegions(tempRegions);
          let tempModules = [];
          result.data[0].distinctModule.forEach((module) =>
            tempModules.push({ label: module, value: module })
          );
          setModules(tempModules);
          let tempStatuses = [];
          result.data[0].distinctStatus.forEach((status) =>
            tempStatuses.push({ label: status, value: status })
          );
          setStatuses(tempStatuses);
          let tempSessions = [];
          result.data[0].distinctSession.forEach((session) =>
            tempSessions.push({ label: session, value: session })
          );
          setSessions(tempSessions);
          let tempPartners = [];
          result.data[0].distinctPartner.forEach((partner) =>
            tempPartners.push({ label: partner, value: partner })
          );
          setPartners(tempPartners);
          setShowData(true);
        }
      });
    return () => {
      isMounted = false;
    };
  }, [refresh]);

  const handleSearchValueChange = (e) => {
    setSearchValues({ ...searchValues, [e.target.name]: e.target.value });
  };

  const handleSearchValueRegionChange = (e) => {
    // console.log(reactSelectChange(e));
    setSearchValues({ ...searchValues, region: reactSelectChange(e) });
  };

  const handleSearchValueModuleChange = (e) => {
    setSearchValues({ ...searchValues, module: reactSelectChange(e) });
  };

  const handleSearchValueStatusChange = (e) => {
    setSearchValues({ ...searchValues, status: reactSelectChange(e) });
  };
  const handleSearchValueExamChange = (e) => {
    setSearchValues({ ...searchValues, exam: reactSelectChange(e) });
  };
  const handleSearchValueSessionChange = (e) => {
    setSearchValues({ ...searchValues, examDate: reactSelectChange(e) });
  };

  const handleSearchValuePartnerChange = (e) => {
    setSearchValues({ ...searchValues, partner: reactSelectChange(e) });
  };

  const toggleAttendanceModal = () => {
    setAttendanceModal(!attendanceModal);
  };

  const toggleImportGradesModal = () => {
    setImportGradesModal(!importGradesModal);
  };

  const toggleEmailModal = () => {
    setEmailModal(!emailModal);
  };

  const toggleSmsModal = () => {
    setSmsModal(!smsModal);
  };

  const handleSearchSubmit = (
    currentPage = 1,
    selectedPageSize = 30,
    searchValues
  ) => {
    axios
      .post(
        "/api/enquiries",
        { page: currentPage, per_page: selectedPageSize, ...searchValues },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((result) => {
        setEnquiriesList(result.data.enquiries);
        setEnquiriesTotal(result.data.total);
        setShowData(true);
        setCurrentPage(result.data.page);
        setTotalPage(result.data.pageCount);
        setLoading(false);
      });
  };

  const handleDownload = () => {
    axios
      .post("/api/enquiries-download", searchValues, {
        responseType: "blob",
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        // FileDownload(result.data, "enquiries.xlsx");
        // window.saveAs(new Blob([result.data]), "enquiries.xlsx");
        const url = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement("a");
        link.href = url;
        link.download = "enquiries.xlsx"; //or any other extension
        document.body.appendChild(link);
        link.click();
      });
  };

  const handleResultsDownload = () => {
    axios
      .post("/api/enquiries-results-download", searchValues, {
        responseType: "blob",
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        // FileDownload(result.data, "enquiries.xlsx");
        // window.saveAs(new Blob([result.data]), "enquiries.xlsx");
        const url = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement("a");
        link.href = url;
        link.download = "results.xlsx"; //or any other extension
        document.body.appendChild(link);
        link.click();
      });
  };

  const handleCsvDownload = (type) => {
    let url;
    if (type === "grade") {
      url = "/api/enquiries-download-csv-grade";
    } else {
      url = "/api/enquiries-download-csv";
    }
    axios
      .post(url, searchValues, {
        responseType: "blob",
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        // FileDownload(result.data, "enquiries.xlsx");
        // window.saveAs(new Blob([result.data]), "enquiries.xlsx");
        const url = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement("a");
        link.href = url;
        link.download = "enquiries.csv"; //or any other extension
        document.body.appendChild(link);
        link.click();
      });
  };

  const handleDelete = (id) => {
    axios
      .delete(`/api/enquiry/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        handleSearchSubmit();
      });
  };
  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Card className="h-100 m-4">
          <CardBody>
            <CardTitle>Enquiries</CardTitle>
            <Form
              onSubmit={(e) => {
                e.preventDefault();

                handleSearchSubmit(1, selectedPageSize, searchValues);
              }}
            >
              <Row className="text-left">
                <Col md="2">
                  <FormGroup>
                    <Label>Enquiry ID</Label>
                    <Input
                      type="number"
                      name="enquiryId"
                      value={searchValues.enquiryId}
                      onChange={handleSearchValueChange}
                    />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <Label>First Name</Label>
                    <Input
                      type="text"
                      name="firstName"
                      value={searchValues.firstName}
                      onChange={handleSearchValueChange}
                    />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <Label>Last Name</Label>
                    <Input
                      type="text"
                      name="lastName"
                      value={searchValues.lastName}
                      onChange={handleSearchValueChange}
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label>Email</Label>
                    <Input
                      type="text"
                      name="email"
                      value={searchValues.email}
                      onChange={handleSearchValueChange}
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label>Phone Number</Label>
                    <Input
                      type="text"
                      name="phoneNumber"
                      value={searchValues.phoneNumber}
                      onChange={handleSearchValueChange}
                    />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <Label>Passport</Label>
                    <Input
                      type="text"
                      name="passportId"
                      value={searchValues.passportId}
                      onChange={handleSearchValueChange}
                    />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <Label>Region</Label>
                    <Select
                      // value={{
                      //   label: searchValues.region,
                      //   value: searchValues.region,
                      // }}
                      options={regions}
                      onChange={handleSearchValueRegionChange}
                      isMulti
                    />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <Label>Exam</Label>
                    <Select
                      // value={{
                      //   label: searchValues.exam,
                      //   value: searchValues.exam,
                      // }}
                      options={[
                        { label: "TKT", value: "TKT" },
                        { label: "Linguaskill", value: "Linguaskill" },
                        { label: "CEQs", value: "CEQs" },
                        { label: "TRKI", value: "TRKI" },
                      ]}
                      onChange={handleSearchValueExamChange}
                      isMulti
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label>Partner</Label>
                    <Select
                      // value={{
                      //   label: searchValues.partner,
                      //   value: searchValues.partner,
                      // }}
                      options={partners}
                      onChange={handleSearchValuePartnerChange}
                      isMulti
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label>Module</Label>
                    <Select
                      // value={{
                      //   label: searchValues.module,
                      //   value: searchValues.module,
                      // }}
                      options={modules}
                      onChange={handleSearchValueModuleChange}
                      isMulti
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label>Session</Label>
                    <Select
                      // value={{
                      //   label: searchValues.examDate,
                      //   value: searchValues.examDate,
                      // }}
                      options={sessions}
                      onChange={handleSearchValueSessionChange}
                      isMulti
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label>Status</Label>
                    <Select
                      // value={{
                      //   label: searchValues.status,
                      //   value: searchValues.status,
                      // }}
                      options={statuses}
                      onChange={handleSearchValueStatusChange}
                      isMulti
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label>From</Label>
                    <DatePicker
                      name="from"
                      selected={
                        searchValues.from ? new Date(searchValues.from) : null
                      }
                      dateFormat="dd/MM/yyyy"
                      placeholderText="dd/mm/yyyy"
                      onChange={(date) => {
                        setSearchValues({
                          ...searchValues,
                          from: moment(date).format("YYYY-MM-DD"),
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label>To</Label>
                    <DatePicker
                      name="to"
                      selected={
                        searchValues.to ? new Date(searchValues.to) : null
                      }
                      dateFormat="dd/MM/yyyy"
                      placeholderText="dd/mm/yyyy"
                      onChange={(date) => {
                        setSearchValues({
                          ...searchValues,
                          to: moment(date).format("YYYY-MM-DD"),
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="12 d-flex">
                  {!isUser && (
                    <UncontrolledDropdown>
                      <DropdownToggle caret color="primary" className="mr-2">
                        Actions
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={handleDownload}>
                          Download .xlsx
                        </DropdownItem>
                        <DropdownItem onClick={handleCsvDownload}>
                          Download .csv
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => handleCsvDownload("grade")}
                        >
                          Download .csv for grade
                        </DropdownItem>
                        <DropdownItem onClick={toggleImportGradesModal}>
                          Import Grades
                        </DropdownItem>
                        <DropdownItem onClick={toggleAttendanceModal}>
                          Attendance Report
                        </DropdownItem>
                        <DropdownItem onClick={toggleEmailModal}>
                          Send Email
                        </DropdownItem>
                        <DropdownItem onClick={toggleSmsModal}>
                          Send SMS
                        </DropdownItem>
                        <DropdownItem onClick={handleResultsDownload}>
                          Download Results
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  )}
                  <Button
                    color="primary"
                    type="submit"
                    onClick={() =>
                      handleSearchSubmit(1, selectedPageSize, searchValues)
                    }
                  >
                    Search
                  </Button>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
        {showData && (
          <ListingTable2
            columns={cols}
            data={enquiriesList}
            totalItemCount={enquiriesTotal}
            handleSearch={handleSearchSubmit}
            loading={loading}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPage={totalPage}
            selectedPageSize={selectedPageSize}
            setSelectedPageSize={setSelectedPageSize}
            searchValues={searchValues}
            withNumeration={false}
          />
        )}
      </div>

      {attendanceModal && (
        <div className="in-modal">
          <div className="modal-overlay"></div>
          <div className="in-modal-content-bigger">
            <DownloadAttendanceModal
              toggle={toggleAttendanceModal}
              searchValues={searchValues}
            />
          </div>
        </div>
      )}
      {importGradesModal && (
        <div className="in-modal">
          <div className="modal-overlay"></div>
          <div className="in-modal-content-bigger">
            <SaveGrades
              toggle={toggleImportGradesModal}
              session={searchValues.examDate}
              module={searchValues.module}
            />
          </div>
        </div>
      )}
      {emailModal && (
        <div className="in-modal">
          <div className="modal-overlay"></div>
          <div className="in-modal-content-bigger">
            <SendEmailModal
              toggle={toggleEmailModal}
              searchValues={searchValues}
            />
          </div>
        </div>
      )}

      {smsModal && (
        <div className="in-modal">
          <div className="modal-overlay"></div>
          <div className="in-modal-content-bigger">
            <SendSmsModal toggle={toggleSmsModal} searchValues={searchValues} />
          </div>
        </div>
      )}
    </AppLayout>
  );
};

export default EnquiriesList;

import React, { useEffect, useState } from "react";
import axios from "axiosClient";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import Resizer from "react-image-file-resizer";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import AppLayout from "layout/AppLayout";
import { useToken } from "auth/useToken";
import Select from "react-select";
import { useUser } from "auth/useUser";

const initialValues = {
  username: "",
  password: "",
  fullName: "",
  details: "",
};

const ChangeUser = () => {
  const navigate = useNavigate();

  const [values, setValues] = useState(initialValues);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useToken();
  const user = useUser();
  const id = user._id;

  const handleChange = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (values.password === "") {
      delete values.password;
    }
    let data = { ...values };

    axios
      .post(`/api/admin/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((result) => {
        if (result.data.success) {
          navigate("/app");
        }
      })
      .catch((err) => {
        setErrorMessage(err.response.data);
      });
  };

  useEffect(() => {
    setLoading(true);

    axios
      .get(`/api/admin/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        let { data } = result.data;
        setValues(data);
        setLoading(false);
      });
  }, [id]);

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div className="mb-2">
              <h1>Update User Details</h1>
            </div>

            <Separator className="mb-5" />
          </Colxx>
        </Row>
        {!loading && (
          <Row>
            <Colxx xxs="12" className="mb-4">
              <Card className="mb-4">
                <CardBody>
                  <Form>
                    <Row>
                      <Col md="8">
                        <FormGroup row>
                          <Label sm="3">username</Label>
                          <Colxx sm="9">
                            <Input
                              type="text"
                              name="username"
                              value={values.username}
                              onChange={handleChange}
                              disabled
                            />
                          </Colxx>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">
                        <FormGroup>
                          <Label>Password</Label>
                          <Input
                            type="text"
                            name="password"
                            value={values.password}
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <FormGroup md="4">
                          <Label>First Name</Label>
                          <Input
                            type="text"
                            name="firstName"
                            value={values.firstName}
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup md="4">
                          <Label>Last Name</Label>
                          <Input
                            type="text"
                            name="lastName"
                            value={values.lastName}
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <FormGroup md="4">
                          <Label>Email</Label>
                          <Input
                            type="email"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup md="4">
                          <Label>Phone Number</Label>
                          <Input
                            type="text"
                            name="phone"
                            value={values.phone}
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Colxx>
          </Row>
        )}
        <div>
          <Button
            color="primary"
            className="top-right-button mr-2"
            onClick={() => navigate("/app")}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            className="top-right-button mr-2"
            onClick={handleSubmit}
          >
            Update
          </Button>
        </div>
        <div>{errorMessage}</div>
      </div>
    </AppLayout>
  );
};

export default ChangeUser;

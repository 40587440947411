import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Login from "./views/user/login";
import Error from "views/error";
import Unauthorized from "views/unauthorized";
import AppLayout from "layout/AppLayout";
import Blank from "views/app/Blank";
import RegionsList from "views/app/tkt-regions/regionsList";
import AddRegion from "views/app/tkt-regions/add";
import UsersList from "views/app/users/usersList";
import AddUser from "views/app/users/add";
import ApplicationsList from "views/app/applications/applicationsList";
import { PrivateRoute } from "auth/privateRoute";
import Settings from "views/app/settings/settings";
import DetailsList from "views/app/details/detailsList";
import AddDetail from "views/app/details/add";
import PublicForm from "views/publicForm";
import EditModule from "views/app/tkt-regions/editModule";
import EditSession from "views/app/tkt-regions/editSession";
import EnquiriesList from "views/app/enquiries/enquiriesList";
import LSRegionsList from "views/app/other-regions/regionsList";
import LSAddRegion from "views/app/other-regions/add";
import Home from "views/home";
import EnquiryView from "views/app/enquiries/enquiryVew";
import SessionsList from "views/app/sessions/sessionsList";
import ChangeUser from "views/app/users/changeDetails";
import EmailsList from "views/app/emails/emailsList";
import EmailView from "views/app/emails/emailView";
import SendEmail from "views/app/emails/sendEmail";
import TelegramBroadcast from "views/app/broadcast/telegram";
import PartnersList from "views/app/partners/partnersList";
import OrganizationsList from "views/app/partners/organizationsList";
import PartnerDetails from "views/app/partners/partnerDetails";
import SmsBroadcast from "views/app/broadcast/sms";
import TrkiAddRegion from "views/app/trki-regions/add";
import TrkiRegionsList from "views/app/trki-regions/regionsList";
import PricesList from "views/app/details/pricesList";
import AddPrice from "views/app/details/priceAdd";
import PaymentsList from "views/app/payments/paymentsList";
import PaymentView from "views/app/payments/paymentView";
import ResultsList from "views/app/tkt-results/resultsList";
import UsersListTkt from "views/app/tkt-results/usersList";
import AddUserTkt from "views/app/tkt-results/add";
import BannedUserView from "views/app/bannedUsers/bannedUserView";
import BannedUsersList from "views/app/bannedUsers/bannedUsersList";
import CandidatesListCertificate from "views/app/candidates-certificate/candidatesList";
import CreateSessions from "views/app/sessions/createSessions";
import QuestionsList from "views/app/tkt-mock/questionsList";
import QuestionPage from "views/app/tkt-mock/questionPage";
import CEQAddRegion from "views/app/ceq-regions/add";
import CEQRegionsList from "views/app/ceq-regions/regionsList";
import MockUsersList from "views/app/tkt-mock/mockUsersList";

function App() {
  return (
    <div className="App">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />

          <Route path="/error" element={<Error />} />
          <Route path="/unauthorized" element={<Unauthorized />} />
          <Route
            path="/app"
            element={
              <PrivateRoute>
                <AppLayout />
              </PrivateRoute>
            }
          />

          <Route
            path="/app/tkt-regions"
            element={
              <PrivateRoute>
                <RegionsList />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/tkt-regions/add"
            element={
              <PrivateRoute>
                <AddRegion />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/tkt-regions/:id"
            element={
              <PrivateRoute>
                <AddRegion />
              </PrivateRoute>
            }
          />

          <Route
            path="/app/ls-regions"
            element={
              <PrivateRoute>
                <LSRegionsList />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/ls-regions/add"
            element={
              <PrivateRoute>
                <LSAddRegion />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/ls-regions/:id"
            element={
              <PrivateRoute>
                <LSAddRegion />
              </PrivateRoute>
            }
          />

          <Route
            path="/app/ceq-regions"
            element={
              <PrivateRoute>
                <CEQRegionsList />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/ceq-regions/add"
            element={
              <PrivateRoute>
                <CEQAddRegion />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/ceq-regions/:id"
            element={
              <PrivateRoute>
                <CEQAddRegion />
              </PrivateRoute>
            }
          />

          <Route
            path="/app/trki-regions"
            element={
              <PrivateRoute>
                <TrkiRegionsList />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/trki-regions/add"
            element={
              <PrivateRoute>
                <TrkiAddRegion />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/trki-regions/:id"
            element={
              <PrivateRoute>
                <TrkiAddRegion />
              </PrivateRoute>
            }
          />

          <Route
            path="/app/modules/:id"
            element={
              <PrivateRoute>
                <EditModule />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/dates/:id"
            element={
              <PrivateRoute>
                <EditSession />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/sessions"
            element={
              <PrivateRoute>
                <SessionsList />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/sessions/create"
            element={
              <PrivateRoute>
                <CreateSessions />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/enquiries"
            element={
              <PrivateRoute>
                <EnquiriesList />
              </PrivateRoute>
            }
          />

          <Route
            path="/app/tkt-results"
            element={
              <PrivateRoute>
                <ResultsList />
              </PrivateRoute>
            }
          />

          <Route
            path="/app/tkt-result-users"
            element={
              <PrivateRoute>
                <UsersListTkt />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/tkt-result-users/add"
            element={
              <PrivateRoute>
                <AddUserTkt />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/tkt-result-users/:id"
            element={
              <PrivateRoute>
                <AddUserTkt />
              </PrivateRoute>
            }
          />

          <Route
            path="/app/enquiries/enquiry/:id"
            element={
              <PrivateRoute>
                <EnquiryView />
              </PrivateRoute>
            }
          />

          <Route
            path="/app/payments"
            element={
              <PrivateRoute>
                <PaymentsList />
              </PrivateRoute>
            }
          />

          <Route
            path="/app/payments/enquiry/:id"
            element={
              <PrivateRoute>
                <PaymentView />
              </PrivateRoute>
            }
          />

          <Route
            path="/app/details"
            element={
              <PrivateRoute>
                <DetailsList />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/details/:id"
            element={
              <PrivateRoute>
                <AddDetail />
              </PrivateRoute>
            }
          />

          <Route
            path="/app/prices"
            element={
              <PrivateRoute>
                <PricesList />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/prices/:id"
            element={
              <PrivateRoute>
                <AddPrice />
              </PrivateRoute>
            }
          />

          <Route
            path="/app/users"
            element={
              <PrivateRoute>
                <UsersList />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/users/add"
            element={
              <PrivateRoute>
                <AddUser />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/users/:id"
            element={
              <PrivateRoute>
                <AddUser />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/change-details"
            element={
              <PrivateRoute>
                <ChangeUser />
              </PrivateRoute>
            }
          />

          <Route
            path="/app/partners"
            element={
              <PrivateRoute>
                <PartnersList />
              </PrivateRoute>
            }
          />

          <Route
            path="/app/partners/tg-enquiries"
            element={
              <PrivateRoute>
                <OrganizationsList />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/partners/:id"
            element={
              <PrivateRoute>
                <PartnerDetails />
              </PrivateRoute>
            }
          />

          <Route
            path="/app/emails"
            element={
              <PrivateRoute>
                <EmailsList />
              </PrivateRoute>
            }
          />

          <Route
            path="/app/emails/email/:id"
            element={
              <PrivateRoute>
                <EmailView />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/emails/send"
            element={
              <PrivateRoute>
                <SendEmail />
              </PrivateRoute>
            }
          />

          <Route
            path="/app/broadcast"
            element={
              <PrivateRoute>
                <TelegramBroadcast />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/sms"
            element={
              <PrivateRoute>
                <SmsBroadcast />
              </PrivateRoute>
            }
          />

          <Route
            path="/app/settings"
            element={
              <PrivateRoute>
                <Settings />
              </PrivateRoute>
            }
          />

          <Route
            path="/app/applications"
            element={
              <PrivateRoute>
                <ApplicationsList />
              </PrivateRoute>
            }
          />

          <Route
            path="/app/bannedusers"
            element={
              <PrivateRoute>
                <BannedUsersList />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/bannedusers/:id"
            element={
              <PrivateRoute>
                <BannedUserView />
              </PrivateRoute>
            }
          />

          <Route
            path="/app/certificate"
            element={
              <PrivateRoute>
                <CandidatesListCertificate />
              </PrivateRoute>
            }
          />

          <Route
            path="/app/mock"
            element={
              <PrivateRoute>
                <QuestionsList />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/mock/question/:id"
            element={
              <PrivateRoute>
                <QuestionPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/mock-users"
            element={
              <PrivateRoute>
                <MockUsersList />
              </PrivateRoute>
            }
          />

          <Route
            path="/app/blank"
            element={
              <PrivateRoute>
                <Blank />
              </PrivateRoute>
            }
          />
          <Route path="*" element={<Error />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;

/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/display-name */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Row,
  UncontrolledDropdown,
} from "reactstrap"; //
import DatatablePagination from "components/DatatablePagination";
import AppLayout from "layout/AppLayout";
import axios from "axiosClient";
import { useToken } from "auth/useToken";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Select from "react-select";
import moment from "moment";
import DatePicker from "react-datepicker";
import { useUser } from "auth/useUser";

import "react-datepicker/dist/react-datepicker.css";
import { currencyFormatter } from "utils/formatter";
import ListingTable2 from "components/ListingTable2";

function Table({ columns, data, total }) {
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 20 },
    },
    useSortBy,
    usePagination
  );

  return (
    <>
      <table {...getTableProps()} className="r-table table text-left">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, columnIndex) => (
                <th key={`th_${columnIndex}`}>
                  {column.render("Header")}
                  <span />
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell, cellIndex) => (
                  <td
                    key={`td_${cellIndex}`}
                    {...cell.getCellProps({
                      className: cell.column.cellClass,
                    })}
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

      <DatatablePagination
        page={pageIndex}
        pages={pageCount}
        canPrevious={canPreviousPage}
        canNext={canNextPage}
        pageSizeOptions={[4, 10, 20, 30, 40, 50]}
        showPageSizeOptions={false}
        showPageJump={false}
        defaultPageSize={pageSize}
        onPageChange={(p) => gotoPage(p)}
        onPageSizeChange={(s) => setPageSize(s)}
        paginationMaxSize={pageCount}
      />
      <p>Total Enquiries: {total}</p>
    </>
  );
}

const customCols = [
  {
    Header: "ID",
    accessor: "enquiryId",
    cellClass: "text-muted w-10",
    Cell: (props) => (
      <>
        <NavLink to={`/app/payments/enquiry/${props.row.original._id}`}>
          {props.value}
        </NavLink>
      </>
    ),
    sortType: "basic",
  },
  {
    Header: "Passport No",
    accessor: "candidatePassport",
    cellClass: "text-muted w-10",
    Cell: (props) => <>{props.value}</>,
    sortType: "basic",
  },
  {
    Header: "PINPP",
    accessor: "pinpp",
    cellClass: "text-muted w-10",
    Cell: (props) => <>{props.value}</>,
    sortType: "basic",
  },
  {
    Header: "Candidate",
    accessor: "candidateName",
    cellClass: "text-muted w-20",
    Cell: (props) => (
      <>
        <NavLink to={`/app/payments/enquiry/${props.row.original._id}`}>
          {`${props.row.original.firstName} ${props.row.original.lastName}`}
        </NavLink>
      </>
    ),
    sortType: "basic",
  },
  {
    Header: "Exam",
    accessor: "exam",
    cellClass: "text-muted w-8",
    Cell: (props) => <>{props.value}</>,
    sortType: "basic",
  },
  {
    Header: "Module",
    accessor: "module",
    cellClass: "text-muted w-10",
    Cell: (props) => <>{props.value}</>,
    sortType: "basic",
  },
  {
    Header: "Exam Date",
    accessor: "examDate",
    cellClass: "text-muted w-8",
    Cell: (props) => <>{props.value}</>,
    sortType: "basic",
  },
  {
    Header: "Transaction ID",
    accessor: "transactionId",
    cellClass: "text-muted w-10",
    Cell: (props) => <>{props.value}</>,
    sortType: "basic",
  },
  {
    Header: "Created At",
    accessor: "createdAt",
    cellClass: "text-muted w-10",
    Cell: (props) => <>{moment(props.value).format("DD/MM/YYYY")}</>,
    sortType: "basic",
  },
  {
    Header: "Amount",
    accessor: "amount",
    cellClass: "text-muted w-10",
    Cell: (props) => <>{currencyFormatter(props.value, "")}</>,
    sortType: "basic",
  },
  {
    Header: "Payment Method",
    accessor: "paymentMethod",
    cellClass: "text-muted w-10",
    Cell: (props) => <>{props.value}</>,
    sortType: "basic",
  },
];

const PaymentsList = () => {
  const user = useUser();
  const cols = React.useMemo(
    () =>
      user.role === "Admin" || user.role === "Manager"
        ? [
            ...customCols,
            {
              Header: "Editor",
              accessor: "editor",
              cellClass: "text-muted w-10",
              Cell: (props) => <>{props.value}</>,
              sortType: "basic",
            },
          ]
        : [...customCols],
    []
  );
  const navigate = useNavigate();

  const [token, setToken] = useToken();
  const [enquiriesList, setEnquiriesList] = useState([]);
  const [enquiriesTotal, setEnquiriesTotal] = useState(0);
  const [searchValues, setSearchValues] = useState({});
  const [showData, setShowData] = useState(false);
  const [modules, setModules] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [methods, setMethods] = useState([]);
  const [exams, setExams] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [selectedPageSize, setSelectedPageSize] = useState(30);

  const [isUser, setIsUser] = useState(
    user.role === "User" ? true : user.role === "Consultant" ? true : false
  );

  useEffect(() => {
    let isMounted = true;
    axios
      .get("/api/payments-search-distinct", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        if (isMounted) {
          let tempMethods = [];
          result.data[0].distinctMethod.forEach((method) =>
            tempMethods.push({ label: method, value: method })
          );
          setMethods(tempMethods);

          let tempModules = [];
          result.data[0].distinctModule.forEach((module) =>
            tempModules.push({ label: module, value: module })
          );
          setModules(tempModules);

          let tempSessions = [];
          result.data[0].distinctSession.forEach((session) =>
            tempSessions.push({ label: session, value: session })
          );
          setSessions(tempSessions);

          let tempExams = [];
          result.data[0].distinctExam.forEach((exam) =>
            tempExams.push({ label: exam, value: exam })
          );
          setExams(tempExams);
          if (user.role !== "Admin") {
            setSearchValues({
              ...searchValues,
              from: moment().subtract(
                user?.searchPeriod
                  ? user?.searchPeriod === 1
                    ? 0
                    : user?.searchPeriod
                  : 0,
                "days"
              ),
              to: moment(),
            });
          }
          setShowData(true);
        }
      });
    return () => {
      isMounted = false;
    };
  }, [refresh]);

  const handleSearchValueChange = (e) => {
    setSearchValues({ ...searchValues, [e.target.name]: e.target.value });
  };

  const handleSearchValueModuleChange = (e) => {
    setSearchValues({ ...searchValues, module: e.value });
  };

  const handleSearchValueMethodChange = (e) => {
    setSearchValues({ ...searchValues, paymentMethod: e.value });
  };
  const handleSearchValueExamChange = (e) => {
    setSearchValues({ ...searchValues, exam: e.value });
  };
  const handleSearchValueSessionChange = (e) => {
    setSearchValues({ ...searchValues, examDate: e.value });
  };

  const handleSearchSubmit = (
    currentPage = 1,
    selectedPageSize = 30,
    searchValues
  ) => {
    axios
      .post(
        "/api/payments",
        { page: currentPage, per_page: selectedPageSize, ...searchValues },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((result) => {
        setEnquiriesList(result.data.enquiries);
        setEnquiriesTotal(result.data.total);
        setShowData(true);
        setCurrentPage(result.data.page);
        setTotalPage(result.data.pageCount);
        setLoading(false);
      });
  };

  const handleUpdatePinfl = () => {
    // console.log();
    axios
      .post("/api/update-pinfl-payments", searchValues, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        console.log(result.data);
      });
  };

  const handleDownload = () => {
    axios
      .post("/api/payments-download", searchValues, {
        responseType: "blob",
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        // FileDownload(result.data, "enquiries.xlsx");
        // window.saveAs(new Blob([result.data]), "enquiries.xlsx");
        const url = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement("a");
        link.href = url;
        link.download = "payments.xlsx"; //or any other extension
        document.body.appendChild(link);
        link.click();
      });
  };

  const handleDownloadReport = () => {
    axios
      .post("/api/payments-report-download", searchValues, {
        responseType: "blob",
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        const url = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement("a");
        link.href = url;
        link.download = `Payments-${moment().format("DD-MM-YYYY")}.pdf`; //or any other extension
        document.body.appendChild(link);
        link.click();
      });
  };

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Card className="h-100 m-4">
          <CardBody>
            <CardTitle>Enquiries</CardTitle>
            <Row className="text-left">
              <Col md="2">
                <FormGroup>
                  <Label>First Name</Label>
                  <Input
                    type="text"
                    name="firstName"
                    value={searchValues.firstName}
                    onChange={handleSearchValueChange}
                  />
                </FormGroup>
              </Col>
              <Col md="2">
                <FormGroup>
                  <Label>Last Name</Label>
                  <Input
                    type="text"
                    name="lastName"
                    value={searchValues.lastName}
                    onChange={handleSearchValueChange}
                  />
                </FormGroup>
              </Col>
              <Col md="2">
                <FormGroup>
                  <Label>Passport</Label>
                  <Input
                    type="text"
                    name="candidatePassport"
                    value={searchValues.candidatePassport}
                    onChange={handleSearchValueChange}
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>Payment Method</Label>
                  <Select
                    value={{
                      label: searchValues.paymentMethod,
                      value: searchValues.paymentMethod,
                    }}
                    options={methods}
                    onChange={handleSearchValueMethodChange}
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>Exam</Label>
                  <Select
                    value={{
                      label: searchValues.exam,
                      value: searchValues.exam,
                    }}
                    options={exams}
                    onChange={handleSearchValueExamChange}
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>Module</Label>
                  <Select
                    value={{
                      label: searchValues.module,
                      value: searchValues.module,
                    }}
                    options={modules}
                    onChange={handleSearchValueModuleChange}
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>Session</Label>
                  <Select
                    value={{
                      label: searchValues.examDate,
                      value: searchValues.examDate,
                    }}
                    options={sessions}
                    onChange={handleSearchValueSessionChange}
                  />
                </FormGroup>
              </Col>
              {isUser ? null : (
                <>
                  <Col md="3">
                    <FormGroup>
                      <Label>From</Label>
                      <DatePicker
                        name="from"
                        selected={
                          searchValues.from ? new Date(searchValues.from) : null
                        }
                        dateFormat="dd/MM/yyyy"
                        placeholderText="dd/mm/yyyy"
                        onChange={(date) => {
                          setSearchValues({
                            ...searchValues,
                            from: moment(date).format("YYYY-MM-DD"),
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label>To</Label>
                      <DatePicker
                        name="to"
                        selected={
                          searchValues.to ? new Date(searchValues.to) : null
                        }
                        dateFormat="dd/MM/yyyy"
                        placeholderText="dd/mm/yyyy"
                        onChange={(date) => {
                          setSearchValues({
                            ...searchValues,
                            to: moment(date).format("YYYY-MM-DD"),
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                </>
              )}
              <Col md="12 d-flex">
                <UncontrolledDropdown>
                  <DropdownToggle caret color="primary" className="mr-2">
                    Actions
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={handleDownloadReport}>
                      Download Report
                    </DropdownItem>
                    {!isUser && (
                      <DropdownItem onClick={handleDownload}>
                        Download .xlsx
                      </DropdownItem>
                    )}
                    {!isUser && (
                      <DropdownItem onClick={handleUpdatePinfl}>
                        Update PINFL
                      </DropdownItem>
                    )}
                    {!isUser && (
                      <DropdownItem onClick={() => navigate("/app/prices")}>
                        Prices
                      </DropdownItem>
                    )}
                  </DropdownMenu>
                </UncontrolledDropdown>
                <Button
                  color="primary"
                  onClick={() =>
                    handleSearchSubmit(1, selectedPageSize, searchValues)
                  }
                  className="mr-2"
                >
                  Search
                </Button>
                <Button
                  color="primary"
                  onClick={() => navigate("/app/payments/enquiry/add")}
                >
                  Add New
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
        {showData && (
          <ListingTable2
            columns={cols}
            data={enquiriesList}
            totalItemCount={enquiriesTotal}
            handleSearch={handleSearchSubmit}
            loading={loading}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPage={totalPage}
            selectedPageSize={selectedPageSize}
            setSelectedPageSize={setSelectedPageSize}
            searchValues={searchValues}
            withNumeration={false}
          />
        )}
      </div>
    </AppLayout>
  );
};

export default PaymentsList;

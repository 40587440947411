import React, { useEffect, useState } from "react";
import axios from "axiosClient";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
} from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import AppLayout from "layout/AppLayout";
import { useToken } from "auth/useToken";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useUser } from "auth/useUser";
import ConfirmModal from "components/ConfirmModal";

const BannedUserView = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [errorMessage, setErrorMessage] = useState("");
  const [isNew, setIsNew] = useState(
    location.pathname.split("/").slice(-1)[0] === "add"
  );
  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [token, setToken] = useToken();

  const { id } = useParams();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (errorMessage === "") {
      axios
        .post(
          `/api/banned-user/${id}`,
          { ...values },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          if (result.status === 200) {
            navigate(-1);
          }
        })
        .catch((err) => {
          setErrorMessage(err.response.data);
        });
    }
  };

  const handleDelete = () => {
    axios
      .delete(`/api/banned-user/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        if (result.status === 200) {
          navigate(-1);
        }
      });
  };

  useEffect(() => {
    !isNew && setLoading(true);
    !isNew &&
      axios
        .get(`/api/banned-user/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((result) => {
          setValues(result.data);
          setLoading(false);
        });
  }, [id, isNew]);

  const handleValuesChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });

    setErrorMessage("");
  };
  const handleDateChange = (e) => {
    setValues({
      ...values,
      bannedTill: new Date(moment(e).set("hour", 10)),
    });
  };

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div className="mb-2">
              <h1>Banned User details </h1>
            </div>
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        {!loading && (
          <Row>
            <Colxx xxs="12" className="mb-4">
              <Card className="mb-4 text-left">
                <CardBody>
                  <Form>
                    <Row>
                      <Col md="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <Label>First Name</Label>
                              <Input
                                required
                                type="text"
                                name="firstName"
                                value={values.firstName}
                                onChange={handleValuesChange}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Label>Last Name</Label>
                              <Input
                                required
                                type="text"
                                name="lastName"
                                value={values.lastName}
                                onChange={handleValuesChange}
                              />
                            </FormGroup>
                          </Col>

                          <Col md="6">
                            <FormGroup>
                              <Label>PINPP</Label>
                              <Input
                                required
                                type="number"
                                name="pinpp"
                                value={values.pinpp}
                                onChange={(e) => {
                                  handleValuesChange(e);
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Label>Banned Until</Label>
                              <DatePicker
                                name="bannedTill"
                                selected={
                                  new Date(
                                    moment(
                                      values.bannedTill
                                        ? values.bannedTill
                                        : new Date()
                                    )
                                  )
                                }
                                dateFormat="dd/MM/yyyy"
                                onChange={handleDateChange}
                                placeholderText="e.g. 20/04/2024"
                              />
                            </FormGroup>
                          </Col>
                          <Col md="12">
                            <FormGroup>
                              <Label>Note</Label>
                              <Input
                                required
                                type="textarea"
                                name="reason"
                                value={values.reason}
                                className="react-select mb-3"
                                onChange={handleValuesChange}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>

                      <div>
                        <Button
                          color="light"
                          className="top-right-button"
                          onClick={() => navigate(-1)}
                        >
                          Back
                        </Button>

                        {!isNew && (
                          <Button
                            color="danger"
                            className="top-right-button ml-2"
                            onClick={() => setDeleteModal(!deleteModal)}
                          >
                            Delete
                          </Button>
                        )}
                        <Button
                          color="primary"
                          className="top-right-button ml-2"
                          onClick={handleSubmit}
                        >
                          {isNew ? "Add" : "Update"}
                        </Button>
                      </div>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Colxx>
          </Row>
        )}

        {deleteModal && (
          <ConfirmModal
            modal={deleteModal}
            setModal={setDeleteModal}
            title={"Delete User"}
            body={`Do you really want to delete this user?`}
            action={handleDelete}
          />
        )}
      </div>
    </AppLayout>
  );
};

export default BannedUserView;

import { Extension } from '@tiptap/core';

export const TableRowResize = Extension.create({
  name: 'tableRowResize',

  addAttributes() {
    return {
      height: {
        default: null,
        parseHTML: (element) => element.style.height,
        renderHTML: (attributes) => {
          if (!attributes.height) {
            return {};
          }
          return {
            style: `height: ${attributes.height}`,
          };
        },
      },
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: ['tableRow'],
        attributes: {
          height: {
            default: null,
            parseHTML: (element) => element.style.height,
            renderHTML: (attributes) => {
              if (!attributes.height) {
                return {};
              }
              return {
                style: `height: ${attributes.height}`,
              };
            },
          },
        },
      },
    ];
  },
});

"use client";

import React from "react";
import BulletList from "@tiptap/extension-bullet-list";
import { Color } from "@tiptap/extension-color";
import FontFamily from "@tiptap/extension-font-family";
import Image from "@tiptap/extension-image";
import ListItem from "@tiptap/extension-list-item";
import OrderedList from "@tiptap/extension-ordered-list";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import TextAlign from "@tiptap/extension-text-align";
import TextStyle from "@tiptap/extension-text-style";
import Underline from "@tiptap/extension-underline";
import { EditorContent, ReactRenderer, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import tippy from "tippy.js";

// import { cn } from "@/lib/utils/cn";

import { FieldSuggestion } from "./FieldSuggestion";
import { FieldSuggestionList } from "./FieldSuggestionList";
import MenuBar from "./MenuBar";
import { TableRowResize } from "./TableRowResize";
import { Card } from "reactstrap";

const fallbackContent = "<p>Write your content here...</p>";

export function TextEditor({
  onUpdate = () => {},
  defaultValue,
  editable = true,
  fields = ["firstName", "lastName"],
}) {
  const content = defaultValue ?? fallbackContent;

  const editor = useEditor({
    editable,
    editorProps: {
      attributes: {
        class:
          "saaskit-text-editor [&_>_:first-child]:mt-0 [&_>_:last-child]:mb-0 focus:outline-none text-steel-700 dark:text-steel-100",
      },
    },
    extensions: [
      StarterKit,
      TextAlign.configure({
        types: ["heading", "paragraph"],
      }),
      Image,
      Table.configure({
        resizable: true,
        lastColumnResizable: true,
        allowTableNodeSelection: true,
      }),
      TableRow,
      TableCell,
      TableHeader,
      FontFamily,
      TextStyle,
      Color,
      Underline,
      BulletList.configure({
        HTMLAttributes: {
          class: "bullet-list",
        },
      }),
      ListItem,
      OrderedList.configure({
        HTMLAttributes: {
          class: "ordered-list",
        },
      }),
      TableRowResize,
      FieldSuggestion.configure({
        suggestion: {
          items: ({ query }) => {
            return fields.filter((item) =>
              item.toLowerCase().startsWith(query.toLowerCase())
            );
          },
          render: () => {
            let component;
            let popup;

            return {
              onStart: (props) => {
                component = new ReactRenderer(FieldSuggestionList, {
                  props,
                  editor: props.editor,
                });

                popup = tippy("body", {
                  getReferenceClientRect: props.clientRect,
                  appendTo: () => document.body,
                  content: component.element,
                  showOnCreate: true,
                  interactive: true,
                  trigger: "manual",
                  placement: "bottom-start",
                });
              },
              onUpdate(props) {
                component.updateProps(props);

                popup[0].setProps({
                  getReferenceClientRect: props.clientRect,
                });
              },
              onKeyDown(props) {
                if (props.event.key === "Escape") {
                  popup[0].hide();
                  return true;
                }

                return component.ref?.onKeyDown(props);
              },
              onExit() {
                popup[0].destroy();
                component.destroy();
              },
            };
          },
        },
      }),
    ],
    content,
    autofocus: true,
    onUpdate: () => {
      const data = editor?.getHTML();
      onUpdate(data ?? "");
    },
  });

  return (
    <Card className="mb-4">
      {/* {editor && <EditorBubbleMenu editor={editor} />} */}
      {/* {editor && <EditorFloatingMenu editor={editor} />} */}
      {editor && editable && <MenuBar editor={editor} />}

      <EditorContent editor={editor} />
    </Card>
  );
}

import React, { useEffect, useState, useRef } from "react";
import axios from "axiosClient";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import AppLayout from "layout/AppLayout";
import { useToken } from "auth/useToken";
import { useUser } from "auth/useUser";

const AddPrice = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const user = useUser();
  const [isUser, setIsUser] = useState(user.role === "User" ? true : false);

  const [data, setData] = useState({ isPrice: true });

  const [errorMessage, setErrorMessage] = useState("");
  const [isNew, setIsNew] = useState(
    location.pathname.split("/").slice(-1)[0] === "add"
  );
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useToken();

  const { id } = useParams();

  const handleValuesChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });

    setErrorMessage("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (errorMessage === "") {
      axios
        .post(
          `/api/details/info`,
          { ...data, id },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((result) => {
          if (result.data.success) {
            navigate("/app/prices");
          }
        })
        .catch((err) => {
          setErrorMessage(err.response.data);
        });
    }
  };

  useEffect(() => {
    !isNew && setLoading(true);
    !isNew &&
      axios
        .get(`/api/details/info/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((result) => {
          setData({ ...result.data, ...data });
          setLoading(false);
        });
  }, [id, isNew]);

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        {/* <Row>
          <Colxx xxs="12">
            <div className="mb-2">
              <h1>{data.title}</h1>
            </div>
            <Separator className="mb-5" />
          </Colxx>
        </Row> */}
        {!loading && (
          <Row>
            <Colxx xxs="12" className="mb-4">
              <Card className="mb-4">
                <CardBody>
                  <Form>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <Label>Exam</Label>
                          <Input
                            type="text"
                            name="exam"
                            className="react-select mb-3"
                            value={data.exam}
                            onChange={handleValuesChange}
                            disabled={isUser}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label>Module</Label>
                          <Input
                            type="text"
                            name="module"
                            className="react-select mb-3"
                            value={data.module}
                            onChange={handleValuesChange}
                            disabled={isUser}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label>Price</Label>
                          <Input
                            type="number"
                            name="priceAmount"
                            className="react-select mb-3"
                            value={data.priceAmount}
                            onChange={handleValuesChange}
                            disabled={isUser}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Colxx>
          </Row>
        )}
        <div>
          <Button
            color="primary"
            className="top-right-button"
            onClick={() => navigate("/app/prices")}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            className="top-right-button ml-2"
            onClick={handleSubmit}
          >
            {isNew ? "Add" : "Update"}
          </Button>
        </div>
        &nbsp;
        <div>{errorMessage}</div>
      </div>
    </AppLayout>
  );
};

export default AddPrice;

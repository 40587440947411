import React, { useEffect, useState, useRef } from "react";
import axios from "axiosClient";
import { read, utils } from "xlsx";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import { useToken } from "auth/useToken";
import { toast } from "react-toastify";
import { CKEditor } from "ckeditor4-react";
import Select from "react-select";

const SendEmailModal = ({ toggle, searchValues }) => {
  const navigate = useNavigate();
  const [editor, setEditor] = useState(null);
  const [showEditor, setShowEditor] = useState(false);
  const [subject, setSubject] = useState("Linguaskill Reminder");
  const [body, setBody] = useState("");
  const [candidates, setCandidates] = useState([]);
  const [candidatesLoading, setCandidatesLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);
  const [selectValue, setSelectValue] = useState({
    value: null,
    label: "Select...",
  });
  const [token, setToken] = useToken();

  const editorConfig = {
    toolbar: [
      ["Undo", "Redo"],
      ["Format", "Font", "FontSize"],
      ["TextColor", "BGColor"],
      ["JustifyLeft", "JustifyCenter", "JustifyRight", "JustifyBlock"],
      [
        "Bold",
        "Italic",
        "Underline",
        "Strike",
        "RemoveFormat",
        "CopyFormatting",
      ],
      ["Link", "Unlink"],
      [
        "NumberedList",
        "BulletedList",
        "-",
        "Outdent",
        "Indent",
        "-",
        "Blockquote",
      ],
      ["Image", "Table"],
      ["Scayt"],
    ],
    extraPlugins: "justify,format,font,colorbutton",
  };

  const selectOptions = [
    { label: "TKT Time and Location", value: "63bfcd44eb8009bca4f70d7e" },
    { label: "LS Time and Location", value: "6421c02750c33d8fd1404818" },
    { label: "LS Results", value: "64b4f007caa7e7194924426d" },
  ];

  const onBeforeLoad = (e) => {
    setEditor(e.editor);
  };

  const getDetailTexts = async (id) => {
    const result = await axios.get(`/api/details/info/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return result?.data?.body;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSending(true);

    try {
      if (errorMessage === "") {
        axios
          .post(
            `/api/send-email-query`,
            { ...searchValues, content: body, subject },
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((result) => {
            // console.log(result.data);
            setSent(true);
            setSending(false);
          })
          .catch((err) => {
            setErrorMessage(err.response.data);
          });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      {!loading && (
        <Row>
          <Colxx xxs="12" className="mb-4">
            <Card className="mb-4">
              <CardBody>
                <Form>
                  <Row>
                    <Col md="12">
                      <>
                        <FormGroup>
                          <h2>Select Email</h2>
                          <Select
                            value={{
                              label: selectValue.label,
                              value: selectValue.value,
                            }}
                            options={selectOptions}
                            onChange={async (e) => {
                              setSelectValue(e);
                              setShowEditor(false);
                              let text = await getDetailTexts(e.value);
                              setBody(text);
                              editor && editor.setData(text);
                              setSubject(e.label);
                              setShowEditor(true);
                            }}
                          />
                        </FormGroup>

                        <FormGroup>
                          <h2>Email Subject</h2>
                          <Input
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                          />
                        </FormGroup>
                        <h2>Email Content</h2>
                        {showEditor && (
                          <CKEditor
                            initData={body}
                            onLoaded={onBeforeLoad}
                            onChange={(event) =>
                              setBody(event.editor.getData())
                            }
                            config={editorConfig}
                          />
                        )}
                      </>
                      <br />
                      {sending && (
                        <span className="btn btn-warning">Sending...</span>
                      )}
                      {sent && <span className="btn btn-success">DONE!</span>}
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      )}
      <div>
        <Button
          color="light"
          className="top-right-button"
          onClick={() => toggle()}
        >
          Close
        </Button>
        {!sent && (
          <Button
            color="primary"
            className="ml-2 top-right-button"
            onClick={handleSubmit}
          >
            Send
          </Button>
        )}
      </div>
      &nbsp;
      <div>{errorMessage}</div>
    </div>
  );
};

export default SendEmailModal;

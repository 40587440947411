import React, { useEffect, useState } from "react";
import { Button, Row, Card, Col, FormGroup, Label, Input } from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import AppLayout from "layout/AppLayout";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axiosClient";
import { useToken } from "auth/useToken";
import { useUser } from "auth/useUser";

const QuestionsList = () => {
  const [newTask, setNewTask] = useState("");
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [token, setToken] = useToken();
  const user = useUser();

  useEffect(() => {
    axios
      .get(`/api/mock/questions`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        setTasks(result.data.data);
        setLoading(false);
      });
  }, []);

  const handleAddTask = () => {
    setButtonLoading(true);
    axios
      .post(
        `/api/mock/task`,
        { title: newTask, author: user.username, type: "Quiz" },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((result) => {
        setTasks([...tasks, result.data.data]);
      });
    setButtonLoading(false);
    setNewTask("");
  };

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div className="mb-2">
              <h1>TKT Mock Questions</h1>
            </div>
            <Row>
              <Col md="8">
                <FormGroup row>
                  <Label sm="3">Mock Name</Label>
                  <Colxx sm="9">
                    <Input
                      type="text"
                      name="newTask"
                      value={newTask}
                      onChange={(e) => setNewTask(e.target.value)}
                    />
                  </Colxx>
                </FormGroup>
              </Col>
              <Col md="4" className="text-left">
                <Button
                  disabled={newTask === "" || buttonLoading}
                  onClick={handleAddTask}
                >
                  Add Mock
                </Button>
              </Col>
            </Row>
            <Separator className="mb-5" />
          </Colxx>
        </Row>

        <Row>
          <div className="loading" hidden={!loading} />
          {!loading && tasks.length === 0 ? (
            <p>List is empty</p>
          ) : (
            tasks.map((task) => (
              <Colxx xxs="12" key={task._id} className="mb-3">
                <Card className="d-flex flex-row">
                  <div className="pl-2 d-flex flex-grow-1 min-width-zero">
                    <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
                      <NavLink
                        to={`/app/mock/question/${task._id}`}
                        className="w-40 w-sm-100"
                      >
                        <p className="list-item-heading mb-1 truncate">
                          {task.title}
                        </p>
                      </NavLink>
                      <p className="mb-1 text-muted text-small w-15 w-sm-100">
                        {task.author}
                      </p>
                    </div>
                  </div>
                </Card>
              </Colxx>
            ))
          )}
        </Row>
      </div>
    </AppLayout>
  );
};

export default QuestionsList;

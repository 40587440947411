/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/display-name */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import AppLayout from "layout/AppLayout";
import axios from "axiosClient";
import { useToken } from "auth/useToken";
import { Link, NavLink, useNavigate } from "react-router-dom";
import moment from "moment";
import DatePicker from "react-datepicker";
import { useUser } from "auth/useUser";

import "react-datepicker/dist/react-datepicker.css";
import ListingTable2 from "components/ListingTable2";

const BannedUsersList = () => {
  const cols = React.useMemo(
    () => [
      {
        Header: "PINPP",
        accessor: "pinpp",
        cellClass: "text-muted w-10",
        Cell: (props) => (
          <>
            <NavLink to={`/app/bannedusers/${props.row.original._id}`}>
              {props.value}
            </NavLink>
          </>
        ),
        sortType: "basic",
      },
      {
        Header: "Full Name",
        accessor: "lastName",
        cellClass: "text-muted w-20",
        Cell: (props) => (
          <>
            <NavLink to={`/app/bannedusers/${props.row.original._id}`}>
              {props.row.original.firstName} {props.row.original.lastName}
            </NavLink>
          </>
        ),
        sortType: "basic",
      },
      {
        Header: "Banned Until",
        accessor: "bannedTill",
        cellClass: "text-muted w-10",
        Cell: (props) => <>{moment(props.value).format("DD/MM/YYYY")}</>,
        sortType: "basic",
      },
      {
        Header: "Created At",
        accessor: "createdAt",
        cellClass: "text-muted w-10",
        Cell: (props) => <>{moment(props.value).format("DD/MM/YYYY, HH:mm")}</>,
        sortType: "basic",
      },
    ],
    []
  );
  const [token, setToken] = useToken();
  const [enquiriesList, setEnquiriesList] = useState([]);
  const [enquiriesTotal, setEnquiriesTotal] = useState(0);
  const [searchValues, setSearchValues] = useState({});
  const [showData, setShowData] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [selectedPageSize, setSelectedPageSize] = useState(30);

  const handleSearchValueChange = (e) => {
    setSearchValues({ ...searchValues, [e.target.name]: e.target.value });
  };

  const navigate = useNavigate();

  const handleSearchSubmit = (
    currentPage = 1,
    selectedPageSize = 30,
    searchValues
  ) => {
    axios
      .post(
        "/api/banned-users",
        { page: currentPage, per_page: selectedPageSize, ...searchValues },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((result) => {
        setEnquiriesList(result.data.bannedUsers);
        setEnquiriesTotal(result.data.total);
        setShowData(true);
        setCurrentPage(result.data.page);
        setTotalPage(result.data.pageCount);
        setLoading(false);
      });
  };

  useEffect(() => {
    handleSearchSubmit();
  }, []);

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Card className="h-100 m-4">
          <CardBody>
            <CardTitle>Banned Users</CardTitle>
            <Row className="text-left">
              <Col md="2">
                <FormGroup>
                  <Label>PINPP</Label>
                  <Input
                    type="number"
                    name="pinpp"
                    value={searchValues.pinpp}
                    onChange={handleSearchValueChange}
                  />
                </FormGroup>
              </Col>
              <Col md="2">
                <FormGroup>
                  <Label>First Name</Label>
                  <Input
                    type="text"
                    name="firstName"
                    value={searchValues.firstName}
                    onChange={handleSearchValueChange}
                  />
                </FormGroup>
              </Col>
              <Col md="2">
                <FormGroup>
                  <Label>Last Name</Label>
                  <Input
                    type="text"
                    name="lastName"
                    value={searchValues.lastName}
                    onChange={handleSearchValueChange}
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>From</Label>
                  <DatePicker
                    name="from"
                    selected={
                      searchValues.from ? new Date(searchValues.from) : null
                    }
                    dateFormat="dd/MM/yyyy"
                    placeholderText="dd/mm/yyyy"
                    onChange={(date) => {
                      setSearchValues({
                        ...searchValues,
                        from: moment(date).format("YYYY-MM-DD"),
                      });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>To</Label>
                  <DatePicker
                    name="to"
                    selected={
                      searchValues.to ? new Date(searchValues.to) : null
                    }
                    dateFormat="dd/MM/yyyy"
                    placeholderText="dd/mm/yyyy"
                    onChange={(date) => {
                      setSearchValues({
                        ...searchValues,
                        to: moment(date).format("YYYY-MM-DD"),
                      });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="12 d-flex">
                <Button
                  color="primary"
                  onClick={() =>
                    handleSearchSubmit(1, selectedPageSize, searchValues)
                  }
                >
                  Search
                </Button>
                <Button
                  color="primary"
                  className="ml-2"
                  onClick={() => navigate("add")}
                >
                  Add New
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
        {showData && (
          <ListingTable2
            columns={cols}
            data={enquiriesList}
            totalItemCount={enquiriesTotal}
            handleSearch={handleSearchSubmit}
            loading={loading}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPage={totalPage}
            selectedPageSize={selectedPageSize}
            setSelectedPageSize={setSelectedPageSize}
            searchValues={searchValues}
            withNumeration={false}
          />
        )}
      </div>
    </AppLayout>
  );
};

export default BannedUsersList;

import axios from "axios";
import axiosClient from "axiosClient";
import moment from "moment";
//
export const handleSendPaymentConfirmationMail = (enquiryId, token, exam) => {
  axiosClient
    .post(
      "/api/send-email",
      {
        mailName: `${
          exam === "TKT" ? "paymentConfirmation" : "paymentConfirmationLs"
        }`,
        enquiryId,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then((result) => {
      if (result.status === 200) {
        alert("The email has been successfully sent.");
      } else {
        alert("Something went wrong.");
      }
    });
};

export const handleSendRequestNewIdMail = (id, token) => {
  axiosClient
    .post(
      "/api/send-email",
      { mailName: "requestNewId", enquiryId: id },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then(async (result) => {
      if (result.status === 200) {
        await axios.post(
          `/api/enquiry-update/${id}`,
          { passportRequestedOn: moment().format("DD MMM YYYY") },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        alert("The email has been successfully sent.");
      } else {
        alert("Something went wrong.");
      }
    });
};

export const handleSendMockForgotPassMail = (email) => {
  axios
    .post("https://api-mock.edu-action.uk/api/forgot-password", {
      email,
    })
    .then((result) => {
      if (result.status === 200) {
        alert("The email has been successfully sent.");
      } else {
        alert("Something went wrong.");
      }
    });
};

/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/display-name */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import AppLayout from "layout/AppLayout";
import axios from "axiosClient";
import { useToken } from "auth/useToken";
import { useUser } from "auth/useUser";

import SaveResults from "./saveBulkModal";
import { Colxx, Separator } from "components/common/CustomBootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import ListingTable from "components/ListingTable";

const ResultsList = () => {
  const user = useUser();
  const [importGradesModal, setImportGradesModal] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [downloading, setDownloading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [count, setCount] = useState(1);
  const [token, setToken] = useToken();

  const toggleImportGradesModal = () => {
    setImportGradesModal(!importGradesModal);
  };

  useEffect(() => {
    let isMounted = true;
    axios
      .get(`/api/tkt-results?&page=${currentPage}&per_page=${30}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        if (isMounted) {
          const { results, count, page, pageCount } = result.data;
          setData(results);
          setCount(count);
          setCurrentPage(page);
          setTotalPage(pageCount);
          setLoading(false);
        }
      });
    return () => {
      isMounted = false;
    };
  }, []);

  const navigate = useNavigate();

  const onSearchKey = (e) => {
    if (e.key === "Enter") {
      setLoading(true);
      handleSearch(e.target.value.toLowerCase());
    }
  };

  const handleSearch = (currentPage, selectedPageSize, word) => {
    ///
    axios
      .get(
        `/api/tkt-results?search=${word}&page=${currentPage}&per_page=${selectedPageSize}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((result) => {
        const { results, count, page, pageCount } = result.data;
        setData(results);
        setCount(count);
        setCurrentPage(page);
        setTotalPage(pageCount);
        setLoading(false);
      });
  };

  const handleExportResults = () => {
    setDownloading(true);
    axios
      .post(
        "/api/results-download",
        {},
        {
          responseType: "blob",
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((result) => {
        // FileDownload(result.data, "enquiries.xlsx");
        // window.saveAs(new Blob([result.data]), "enquiries.xlsx");
        const url = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement("a");
        link.href = url;
        link.download = "Results.xlsx"; //or any other extension
        document.body.appendChild(link);
        link.click();
        setDownloading(false);
      });
  };

  const cols = React.useMemo(
    () => [
      {
        Header: "#",
        // accessor: "title",
        cellClass: "list-item-heading",
      },
      {
        Header: "First Name",
        accessor: "firstName",
        cellClass: "list-item-heading w-15",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Last Name",
        accessor: "lastName",
        cellClass: "list-item-heading w-15",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Certitifcate No",
        accessor: "certificateNo",
        cellClass: "text-muted w-15",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Test Module",
        accessor: "module",
        cellClass: "text-muted w-15",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Result",
        accessor: "result",
        cellClass: "text-muted w-15",
        Cell: (props) => <>Band {props.value}</>,
      },
      {
        Header: "Test Date",
        accessor: "testDate",
        cellClass: "text-muted w-15",
        Cell: (props) => <>{props.value}</>,
      },
    ],
    []
  );

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div className="mb-2 d-flex">
              <h1>TKT Results List</h1>
              <UncontrolledDropdown className="ml-3">
                <DropdownToggle caret color="primary" className="mr-2">
                  Actions
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={toggleImportGradesModal}>
                    Import Results
                  </DropdownItem>
                  <DropdownItem
                    disabled={downloading}
                    onClick={handleExportResults}
                  >
                    {downloading ? "Downloading..." : "Export Results"}
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => navigate("/app/tkt-result-users")}
                  >
                    Users
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
            <Separator className="mb-5" />
            <Col md="12 d-flex"></Col>
          </Colxx>
        </Row>
        {!loading && (
          <ListingTable
            columns={cols}
            data={data}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            handleSearch={handleSearch}
            totalPage={totalPage}
            totalItemCount={count}
            loading={loading}
          />
        )}
      </div>

      {importGradesModal && (
        <div className="in-modal">
          <div className="modal-overlay"></div>
          <div className="in-modal-content-bigger">
            <SaveResults toggle={toggleImportGradesModal} />
          </div>
        </div>
      )}
    </AppLayout>
  );
};

export default ResultsList;

import React, { useEffect, useState, useRef } from "react";
import axios from "axiosClient";
import {
  Link,
  useNavigate,
  useParams,
  useLocation,
  NavLink,
} from "react-router-dom";
import {
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
  InputGroup,
  InputGroupText,
  FormFeedback,
  CardHeader,
  Nav,
  NavItem,
  TabContent,
  TabPane,
} from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import AppLayout from "layout/AppLayout";
import { useToken } from "auth/useToken";
import Select from "react-select";
import Resizer from "react-image-file-resizer";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import {
  handleSendPaymentConfirmationMail,
  handleSendRequestNewIdMail,
} from "utils/sendEmails";
import { toBase64 } from "utils/toBase64";
import { useUser } from "auth/useUser";

const EnquiryView = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [data, setData] = useState({});
  const [body, setBody] = useState(data.body || "");

  const [errorMessage, setErrorMessage] = useState("");
  const [isNew, setIsNew] = useState(
    location.pathname.split("/").slice(-1)[0] === "add"
  );
  const [values, setValues] = useState({});
  const [validate, setValidate] = useState({
    emailState: "",
    passportState: "",
  });
  const [image, setImage] = useState({});
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useToken();
  const user = useUser();

  const [activeTab, setActiveTab] = useState("payment");
  const [searchValues, setSearchValues] = useState({});
  const [modules, setModules] = useState([]);
  const [partnersOptions, setPartnersOptions] = useState([]);
  const [availableTransfers, setAvailableTransfers] = useState([]);
  const [showTransfers, setShowTransfers] = useState(false);
  const [showReinstateDetails, setShowReinstateDetails] = useState(false);
  const [isUser, setIsUser] = useState(user.role === "User" ? true : false);

  const { id } = useParams();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (errorMessage === "") {
      axios
        .post(
          `/api/enquiry-update/${id}`,
          { ...values, passportFile: image },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          if (result.status === 200) {
            navigate(-1);
          }
        })
        .catch((err) => {
          setErrorMessage(err.response.data);
        });
    }
  };

  const handlePaymentSubmit = (e) => {
    e.preventDefault();
    if (errorMessage === "") {
      axios
        .post(
          body.status === "Paid"
            ? `/api/enquiry-update/${id}`
            : `/api/enquiry-update-paid/${id}`,
          { ...values, status: "Paid" },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          if (result.status === 200) {
            navigate(-1);
          }
        })
        .catch((err) => {
          setErrorMessage(err.response.data);
        });
    }
  };
  const handleFreeSeat = (remove) => {
    axios
      .post(
        `/api/enquiry-${remove ? "remove" : "add"}-fs/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => {
        if (result.status === 200) {
          navigate(0);
        }
      })
      .catch((err) => {
        setErrorMessage(err.response.data);
      });
  };

  const handleCancelSubmit = (e) => {
    e.preventDefault();
    if (errorMessage === "") {
      axios
        .post(
          `/api/enquiry-update/${id}`,
          {
            ...values,
            status: `${
              values.cancelWithRefund ? "Cancelled with Refund" : "Cancelled"
            }`,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          if (result.status === 200) {
            navigate(-1);
          }
        })
        .catch((err) => {
          setErrorMessage(err.response.data);
        });
    }
  };

  useEffect(() => {
    !isNew && setLoading(true);
    !isNew &&
      axios
        .get(`/api/enquiry/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((result) => {
          setValues(result.data);
          setBody(result.data);
          setImage(result.data.passportFile);
          setLoading(false);
          if (result.data.status === "Expired") {
            setActiveTab("reinstate");
          }
        });

    axios
      .get(`/api/enquiry-partners`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        setPartnersOptions(
          result.data.map((one) => {
            return { label: one, value: one };
          })
        );
      });
  }, [id, isNew]);

  const handleValuesChange = (e) => {
    if (e.target.name === "passportId") {
      setValues({
        ...values,
        [e.target.name]: e.target.value.toUpperCase().replace(" ", ""),
      });
    } else if (e.target.name === "phoneNumber") {
      setValues({
        ...values,
        [e.target.name]: e.target.value.replace(" ", "").replace("+", ""),
      });
    } else if (e.target.name === "cancelWithRefund") {
      setValues({
        ...values,
        [e.target.name]: e.target.checked,
      });
    } else {
      setValues({ ...values, [e.target.name]: e.target.value });
    }
    setErrorMessage("");
  };

  const updateSearchModules = () => {
    if (modules.length === 0) {
      axios
        .get(`/api/enquiry-available-modules`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((result) => {
          let tempModules = [];
          result.data.forEach((one) =>
            tempModules.push({ label: one, value: one })
          );
          setModules(tempModules);
        });
    }
  };

  const handleSearchModuleChange = (e) => {
    setSearchValues({
      ...searchValues,
      module: e.value,
    });
  };

  const handleDOBChange = (e) => {
    // console.log(moment(e).format("YYYY-MM-DD"));
    setValues({
      ...values,
      dateOfBirth: new Date(moment(e).set("hour", 10)),
    });
  };

  const handleGenderChange = (e) => {
    setValues({ ...values, gender: e.value });
  };

  const handlePartnerChange = (e) => {
    setValues({ ...values, partner: e.value });
  };

  const validatePassportId = (e) => {
    let rex = /[A-Z]{2}[0-9]{7}/;
    if (rex.test(e.target.value)) {
      setValidate({ ...validate, passportState: "has-success" });
    } else {
      setValidate({ ...validate, passportState: "has-danger" });
    }
  };

  const validatePhoneNumber = (e) => {
    let rex = /998[0-9]{9}/;
    if (rex.test(e.target.value)) {
      setValidate({ ...validate, phoneState: "has-success" });
    } else {
      setValidate({ ...validate, phoneState: "has-danger" });
    }
  };

  const validateEmail = (e) => {
    let rex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (rex.test(e.target.value)) {
      setValidate({ ...validate, emailState: "has-success" });
    } else {
      setValidate({ ...validate, emailState: "has-danger" });
    }
  };

  const regionLiveOptions = [
    { label: "Adijan", value: "Adijan" },
    { label: "Bukhara", value: "Bukhara" },
    { label: "Fergana", value: "Fergana" },
    { label: "Jizzakh", value: "Jizzakh" },
    { label: "Karshi", value: "Karshi" },
    { label: "Kokand", value: "Kokand" },
    { label: "Namangan", value: "Namangan" },
    { label: "Navoi", value: "Navoi" },
    { label: "Nukus", value: "Nukus" },
    { label: "Samarkand", value: "Samarkand" },
    { label: "Sirdarya", value: "Sirdarya" },
    { label: "Termez", value: "Termez" },
    { label: "Tashkent", value: "Tashkent" },
    { label: "Urgench", value: "Urgench" },
  ];

  const handleRegionLiveChange = (e) => {
    setValues({ ...values, regionLive: e.value });
  };

  const handlePaymentMethodChange = (e) => {
    setValues({ ...values, paymentMethod: e.value });
  };

  let correctImageTypes = ["JPEG", "PNG", "WEBP"];
  const handleImage = async (e) => {
    let file = e.target.files[0];
    let fileType = file.type.split("/")[1].toUpperCase();

    if (!correctImageTypes.includes(fileType)) {
      setLoading(true);
      const result = await toBase64(file);
      let { data } = await axios.post(
        "/api/upload-file",
        {
          file: {
            extension: file.name.split(".")[file.name.split(".").length - 1],
            type: file.type,
            base64Data: result,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      //set image in te state
      setImage(data);
      setValues({ ...values, passportIsImage: false });
      setLoading(false);
    } else {
      setLoading(true);
      //resize
      Resizer.imageFileResizer(
        file,
        720,
        500,
        fileType,
        100,
        0,
        async (uri) => {
          try {
            let { data } = await axios.post(
              "/api/upload-image",
              {
                image: uri,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );
            //set image in te state
            setImage(data);
            setValues({ ...values, passportIsImage: true });
            setLoading(false);
          } catch (err) {
            console.log(err);
            setLoading(false);
          }
        }
      );
    }
  };

  const handleImageRemove = async () => {
    try {
      setLoading(true);
      await axios.post("/api/remove-image", { image });
      setImage({});
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handleVerify = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        `/api/enquiry-verify/${id}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      navigate(0);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSearchTransfer = () => {
    axios
      .post(
        `/api/enquiry-available-transfers`,
        { page: 1, per_page: 300, ...searchValues },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => {
        if (result.status === 200) {
          setAvailableTransfers(
            result.data.enquiries.filter(
              (one) => one.seatsMax > one.seatsBooked
            )
          );
          setShowTransfers(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleTransfer = (examId, examDate, module, region) => {
    let data = { examId, examDate, module, region };
    if (showReinstateDetails) {
      data.type = "reinstate";
    }
    axios
      .post(`/api/enquiry-transfer/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        if (result.status === 200) {
          navigate(-1);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleReinstate = () => {
    axios
      .get(`/api/enquiry-reinstate/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(async (result) => {
        if (result.status === 200) {
          navigate(-1);
        } else {
          setShowReinstateDetails(true);
        }
      });
  };

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div className="mb-2">
              <h1>Application details #{values.enquiryId}</h1>
              <h2 className="text-primary">STATUS: {values.status}</h2>
            </div>
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        {!loading && (
          <Row>
            <Colxx xxs="12" className="mb-4">
              <Card className="mb-4 text-left">
                <CardBody>
                  <Form>
                    <Row>
                      <Col md="8">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <Label>First Name</Label>
                              <Input
                                required
                                type="text"
                                name="firstName"
                                value={values.firstName}
                                onChange={handleValuesChange}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Label>Last Name</Label>
                              <Input
                                required
                                type="text"
                                name="lastName"
                                value={values.lastName}
                                onChange={handleValuesChange}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Label>Gender</Label>
                              <Select
                                required
                                className="react-select mb-3"
                                value={{
                                  label: values.gender,
                                  value: values.gender,
                                }}
                                name="module"
                                options={[
                                  { label: "Male", value: "Male" },
                                  { label: "Female", value: "Female" },
                                ]}
                                onChange={handleGenderChange}
                                disabled={isUser}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Label>Passport Number</Label>
                              <Input
                                required
                                type="text"
                                name="passportId"
                                valid={validate.passportState === "has-success"}
                                invalid={
                                  validate.passportState === "has-danger"
                                }
                                value={values.passportId}
                                onChange={(e) => {
                                  validatePassportId(e);
                                  handleValuesChange(e);
                                }}
                              />{" "}
                              <FormFeedback>
                                Please input a correct passport or ID number.
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Label>Date of Birth</Label>
                              <DatePicker
                                name="dateOfBirth"
                                selected={
                                  new Date(
                                    moment(
                                      values.dateOfBirth
                                        ? values.dateOfBirth
                                        : new Date()
                                    )
                                  )
                                }
                                dateFormat="dd/MM/yyyy"
                                onChange={handleDOBChange}
                                placeholderText="e.g. 26/12/1993"
                                disabled={isUser}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Label>Region Living:</Label>
                              <Select
                                required
                                name="regionLive"
                                className="react-select mb-3"
                                value={{
                                  label: values.regionLive,
                                  value: values.regionLive,
                                }}
                                options={regionLiveOptions}
                                onChange={handleRegionLiveChange}
                                disabled={isUser}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Label>Phone Number</Label>
                              <Input
                                required
                                type="number"
                                name="phoneNumber"
                                valid={validate.phoneState === "has-success"}
                                invalid={validate.phoneState === "has-danger"}
                                value={values.phoneNumber}
                                onChange={(e) => {
                                  validatePhoneNumber(e);
                                  handleValuesChange(e);
                                }}
                              />{" "}
                              <FormFeedback>
                                Please input a correct phone number.
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Label>Email</Label>
                              <Input
                                required
                                type="email"
                                name="email"
                                valid={validate.emailState === "has-success"}
                                invalid={validate.emailState === "has-danger"}
                                value={values.email}
                                onChange={(e) => {
                                  validateEmail(e);
                                  handleValuesChange(e);
                                }}
                              />
                              <FormFeedback>
                                Please input a correct email address.
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Label>Exam Region</Label>
                              <Input
                                required
                                type="text"
                                name="region"
                                value={values.region}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Label>Exam Module</Label>
                              <Input
                                required
                                type="text"
                                name="module"
                                value={values.module}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Label>Exam Date</Label>
                              <Input
                                required
                                type="text"
                                name="examDate"
                                value={values.examDate}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Label>Password</Label>
                              <Input
                                type="text"
                                name="sw"
                                value={values?.sw}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Label>Partner</Label>
                              <Select
                                required
                                className="react-select mb-3"
                                value={{
                                  label: values.partner,
                                  value: values.partner,
                                }}
                                name="partner"
                                options={partnersOptions}
                                onChange={handlePartnerChange}
                                disabled={isUser}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <p className="text-danger">{errorMessage}</p>
                      </Col>

                      <Col md="4">
                        <p className="text-left">
                          Passport Preview
                          {!values.passportVerified && (
                            <Button
                              color="primary"
                              size="xs"
                              className="top-right-button ml-2"
                              onClick={handleVerify}
                              hidden={isUser}
                            >
                              Verify Passport
                            </Button>
                          )}
                          {!values.passportVerified && (
                            <>
                              <Button
                                color="primary"
                                size="xs"
                                className="top-right-button ml-2"
                                onClick={() =>
                                  handleSendRequestNewIdMail(id, token)
                                }
                                hidden={isUser}
                              >
                                Request New
                              </Button>
                              {values.passportRequestedOn && (
                                <>
                                  <br />
                                  <span>
                                    New ID Requested on:{" "}
                                    {values.passportRequestedOn}
                                  </span>
                                </>
                              )}
                              <p></p>
                            </>
                          )}
                          {values.passportVerified && (
                            <Button
                              color="success"
                              size="xs"
                              className="top-right-button ml-2"
                              disabled
                            >
                              Verified
                            </Button>
                          )}
                        </p>
                        {values.passportIsImage && (
                          <img
                            src={image.Location}
                            width="100%"
                            alt="passport"
                          />
                        )}
                        {!values.passportIsImage && (
                          <a href={image.Location} target="blank">
                            Link to file
                          </a>
                        )}

                        <FormGroup className="mt-3" hidden={isUser}>
                          <Label>Renew Passport File</Label>
                          <Input
                            type="file"
                            name="passportFile"
                            onChange={handleImage}
                            accept="image/*"
                            disabled={loading}
                          />
                        </FormGroup>
                      </Col>

                      <div>
                        <Button
                          color="primary"
                          className="top-right-button"
                          onClick={() => navigate(-1)}
                        >
                          Back
                        </Button>
                        <Button
                          color="primary"
                          className="top-right-button ml-2"
                          onClick={handleSubmit}
                        >
                          {isNew ? "Add" : "Update"}
                        </Button>
                      </div>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Colxx>
            <Colxx xxs="12" className="mb-4">
              <Separator className="mb-5" />
              <div className="mb-2">
                <h2>Application actions</h2>
              </div>
              <Card className="mb-4 text-left">
                <CardHeader>
                  <Nav tabs className="card-header-tabs">
                    {values.status !== "Expired" && (
                      <>
                        <NavItem>
                          <Link
                            className={`${
                              activeTab === "payment"
                                ? "nav-link active"
                                : "nav-link"
                            }`}
                            onClick={() => setActiveTab("payment")}
                            to="#"
                            location={{}}
                          >
                            Payment
                          </Link>
                        </NavItem>
                        <NavItem>
                          <Link
                            className={
                              activeTab === "refund"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            onClick={() => setActiveTab("refund")}
                            to="#"
                          >
                            Cancel / Refund
                          </Link>
                        </NavItem>
                        <NavItem>
                          <Nav
                            className={
                              activeTab === "transfer"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            onClick={() => setActiveTab("transfer")}
                            to="#"
                            location={{}}
                          >
                            Transfer
                          </Nav>
                        </NavItem>
                      </>
                    )}
                    {values.status === "Expired" && (
                      <NavItem>
                        <Nav
                          className={
                            activeTab === "reinstate"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          onClick={() => setActiveTab("reinstate")}
                          to="#"
                          location={{}}
                        >
                          Reinstate
                        </Nav>
                      </NavItem>
                    )}
                    {values.partner && (
                      <NavItem>
                        <Nav
                          className={
                            activeTab === "freeSeat"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          onClick={() => setActiveTab("freeSeat")}
                          to="#"
                          location={{}}
                        >
                          Free Seat
                        </Nav>
                      </NavItem>
                    )}
                  </Nav>
                </CardHeader>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="payment" id="payment">
                    <Row>
                      <Colxx sm="12">
                        <CardBody>
                          <Row>
                            <Col md="6">
                              <FormGroup>
                                <Label>Receipt Number</Label>
                                <Input
                                  type="text"
                                  name="paymentReceipt"
                                  className="react-select mb-3"
                                  value={values.paymentReceipt}
                                  disabled
                                />
                              </FormGroup>
                              <FormGroup>
                                <Label>Payment Date</Label>
                                <DatePicker
                                  name="paymentDate"
                                  selected={
                                    new Date(
                                      moment(values.paymentDate) || new Date()
                                    )
                                  }
                                  dateFormat="dd/MM/yyyy"
                                  onChange={(date) =>
                                    setValues({
                                      ...values,
                                      paymentDate: new Date(
                                        moment(date).set("hour", 10)
                                      ),
                                    })
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup>
                                <Label>Payment Method</Label>
                                <Select
                                  required
                                  name="paymentMethod"
                                  className="react-select mb-3"
                                  value={{
                                    label: values.paymentMethod,
                                    value: values.paymentMethod,
                                  }}
                                  options={[
                                    { label: "Cash", value: "Cash" },
                                    { label: "Terminal", value: "Terminal" },
                                    { label: "Online", value: "Online" },
                                  ]}
                                  onChange={handlePaymentMethodChange}
                                />
                              </FormGroup>
                              <FormGroup>
                                <Label>Note</Label>
                                <Input
                                  required
                                  type="textarea"
                                  name="paymentNote"
                                  value={values.paymentNote}
                                  className="react-select mb-3"
                                  onChange={handleValuesChange}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <div>
                            {body.status === "Paid" ? (
                              <Button
                                color="primary"
                                className="top-right-button ml-2"
                                onClick={handlePaymentSubmit}
                              >
                                Update
                              </Button>
                            ) : (
                              <Button
                                color="primary"
                                className="top-right-button ml-2"
                                onClick={handlePaymentSubmit}
                              >
                                Change to Paid
                              </Button>
                            )}

                            {/* <Button
                              color="primary"
                              className="top-right-button ml-2"
                              onClick={() =>
                                handleSendPaymentConfirmationMail(
                                  id,
                                  token,
                                  values.exam
                                )
                              }
                            >
                              Send Payment Confirmation
                            </Button> */}
                          </div>
                        </CardBody>
                      </Colxx>
                    </Row>
                  </TabPane>
                  <TabPane tabId="refund" id="refund">
                    <Row>
                      <Colxx sm="12">
                        <CardBody>
                          <Row>
                            <Col md="6">
                              <FormGroup className="ml-4">
                                <Label check>
                                  <Input
                                    type="checkbox"
                                    name="cancelWithRefund"
                                    className="react-select mb-3"
                                    checked={values.cancelWithRefund}
                                    onChange={handleValuesChange}
                                    disabled={isUser}
                                  />
                                  With Refund?
                                </Label>
                              </FormGroup>{" "}
                              <FormGroup>
                                <Label>Note</Label>
                                <Input
                                  required
                                  type="textarea"
                                  name="cancelNote"
                                  value={values.cancelNote}
                                  className="react-select mb-3"
                                  onChange={handleValuesChange}
                                  disabled={isUser}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <div>
                            <Button
                              color="primary"
                              className="top-right-button ml-2"
                              onClick={handleCancelSubmit}
                              hidden={isUser}
                            >
                              Proceed
                            </Button>
                          </div>
                        </CardBody>
                      </Colxx>
                    </Row>
                  </TabPane>
                  <TabPane tabId="transfer" id="transfer">
                    <Row>
                      <Colxx sm="12">
                        <CardBody hidden={isUser}>
                          <Row>
                            <Col md="3">
                              <FormGroup className="ml-4">
                                <Label>From</Label>
                                <DatePicker
                                  name="from"
                                  selected={
                                    searchValues.from
                                      ? new Date(searchValues.from)
                                      : null
                                  }
                                  dateFormat="dd/MM/yyyy"
                                  placeholderText="dd/mm/yyyy"
                                  onChange={(date) => {
                                    setSearchValues({
                                      ...searchValues,
                                      from: moment(date).format("YYYY-MM-DD"),
                                    });
                                    updateSearchModules();
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col md="3">
                              <FormGroup className="ml-4">
                                <Label>To</Label>
                                <DatePicker
                                  name="to"
                                  selected={
                                    searchValues.to
                                      ? new Date(searchValues.to)
                                      : null
                                  }
                                  dateFormat="dd/MM/yyyy"
                                  placeholderText="dd/mm/yyyy"
                                  onChange={(date) => {
                                    setSearchValues({
                                      ...searchValues,
                                      to: moment(date).format("YYYY-MM-DD"),
                                    });
                                    updateSearchModules();
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col md="3">
                              <FormGroup className="ml-4">
                                <Label>Module</Label>
                                <Select
                                  type="to"
                                  name="module"
                                  className="react-select mb-3"
                                  value={{
                                    label: searchValues.module,
                                    value: searchValues.module,
                                  }}
                                  options={modules}
                                  onChange={(e) => handleSearchModuleChange(e)}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <div>
                            <Button
                              color="primary"
                              className="top-right-button ml-2 mb-5"
                              onClick={handleSearchTransfer}
                            >
                              Search
                            </Button>
                          </div>
                          {showTransfers &&
                            availableTransfers.map((session) => (
                              <Colxx
                                xxs="12"
                                key={session._id}
                                className="mb-3"
                              >
                                <Card className="d-flex flex-row">
                                  <div className="pl-2 d-flex flex-grow-1 min-width-zero">
                                    <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
                                      <p className="list-item-heading mb-1 truncate">
                                        {session.name} | {session.sessionDate}
                                      </p>
                                      <p className="mb-1 text-muted text-small w-15 w-sm-100">
                                        Region: {session.region}
                                      </p>
                                      <p className="mb-1 text-muted text-small w-15 w-sm-100">
                                        Available slots:{" "}
                                        {session.seatsMax - session.seatsBooked}
                                      </p>
                                      <p className="mb-1 text-muted text-small w-15 w-sm-100">
                                        <Button
                                          color="primary"
                                          outline
                                          onClick={() =>
                                            handleTransfer(
                                              session._id,
                                              session.sessionDate,
                                              session.module,
                                              session.region
                                            )
                                          }
                                        >
                                          Transfer
                                        </Button>
                                      </p>
                                    </div>
                                  </div>
                                </Card>
                              </Colxx>
                            ))}
                        </CardBody>
                      </Colxx>
                    </Row>
                  </TabPane>
                  <TabPane tabId="reinstate" id="reinstate">
                    <Row>
                      <Colxx sm="12">
                        <CardBody>
                          {showReinstateDetails && (
                            <>
                              <Row>
                                <p color="primary">
                                  Reinstatement was failed. You may transfer to
                                  another date:
                                </p>
                                <Col md="3">
                                  <FormGroup className="ml-4">
                                    <Label>From</Label>
                                    <DatePicker
                                      name="from"
                                      selected={
                                        searchValues.from
                                          ? new Date(searchValues.from)
                                          : null
                                      }
                                      dateFormat="dd/MM/yyyy"
                                      placeholderText="dd/mm/yyyy"
                                      onChange={(date) => {
                                        setSearchValues({
                                          ...searchValues,
                                          from: moment(date).format(
                                            "YYYY-MM-DD"
                                          ),
                                        });
                                        updateSearchModules();
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md="3">
                                  <FormGroup className="ml-4">
                                    <Label>To</Label>
                                    <DatePicker
                                      name="to"
                                      selected={
                                        searchValues.to
                                          ? new Date(searchValues.to)
                                          : null
                                      }
                                      dateFormat="dd/MM/yyyy"
                                      placeholderText="dd/mm/yyyy"
                                      onChange={(date) => {
                                        setSearchValues({
                                          ...searchValues,
                                          to: moment(date).format("YYYY-MM-DD"),
                                        });
                                        updateSearchModules();
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md="3">
                                  <FormGroup className="ml-4">
                                    <Label>Module</Label>
                                    <Select
                                      name="module"
                                      className="react-select mb-3"
                                      value={{
                                        label: searchValues.module,
                                        value: searchValues.module,
                                      }}
                                      options={modules}
                                      onChange={(e) =>
                                        handleSearchModuleChange(e)
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <div>
                                <Button
                                  color="primary"
                                  className="top-right-button ml-2 mb-5"
                                  onClick={handleSearchTransfer}
                                >
                                  Search
                                </Button>
                              </div>
                              {showTransfers &&
                                availableTransfers.map((session) => (
                                  <Colxx
                                    xxs="12"
                                    key={session._id}
                                    className="mb-3"
                                  >
                                    <Card className="d-flex flex-row">
                                      <div className="pl-2 d-flex flex-grow-1 min-width-zero">
                                        <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
                                          <p className="list-item-heading mb-1 truncate">
                                            {session.name} |{" "}
                                            {session.sessionDate}
                                          </p>
                                          <p className="mb-1 text-muted text-small w-15 w-sm-100">
                                            Region: {session.region}
                                          </p>
                                          <p className="mb-1 text-muted text-small w-15 w-sm-100">
                                            Available slots:{" "}
                                            {session.seatsMax -
                                              session.seatsBooked}
                                          </p>
                                          <p className="mb-1 text-muted text-small w-15 w-sm-100">
                                            <Button
                                              color="primary"
                                              outline
                                              onClick={() =>
                                                handleTransfer(
                                                  session._id,
                                                  session.sessionDate,
                                                  session.module,
                                                  session.region
                                                )
                                              }
                                            >
                                              Transfer
                                            </Button>
                                          </p>
                                        </div>
                                      </div>
                                    </Card>
                                  </Colxx>
                                ))}
                            </>
                          )}

                          {!showReinstateDetails && (
                            <Row>
                              <Button
                                color="primary"
                                outline
                                onClick={handleReinstate}
                              >
                                Reinstate
                              </Button>
                            </Row>
                          )}
                        </CardBody>
                      </Colxx>
                    </Row>
                  </TabPane>
                  <TabPane tabId="freeSeat" id="freeSeat">
                    <Row>
                      <Colxx sm="12">
                        <CardBody>
                          <Row>
                            <Col md="6">
                              <FormGroup>
                                <Label>Partner</Label>
                                <Input
                                  type="text"
                                  name="partner"
                                  className="react-select mb-3"
                                  value={values.partner}
                                  disabled
                                />
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup>
                                <Label>Requested Date</Label>
                                <Input
                                  type="text"
                                  name="requestedDate"
                                  className="react-select mb-3"
                                  value={values.requestedDate}
                                  disabled
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <div>
                            {values.requestedFreeSeat ? (
                              <Button
                                color="primary"
                                className="top-right-button ml-2"
                                onClick={() => handleFreeSeat(true)}
                                hidden={isUser}
                              >
                                Remove Free Seat
                              </Button>
                            ) : (
                              <Button
                                color="primary"
                                className="top-right-button ml-2"
                                onClick={() => handleFreeSeat(false)}
                                hidden={isUser}
                              >
                                Add Free Seat
                              </Button>
                            )}
                          </div>
                        </CardBody>
                      </Colxx>
                    </Row>
                  </TabPane>
                </TabContent>
              </Card>
            </Colxx>
          </Row>
        )}
      </div>
    </AppLayout>
  );
};

export default EnquiryView;

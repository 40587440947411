/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useToken } from "auth/useToken";

import {
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";

import { NavLink } from "react-router-dom";
import { useUser } from "auth/useUser";
import menuItems from "constants/menu";

const TopNav = () => {
  const [isInFullScreen, setIsInFullScreen] = useState(false);
  const [token] = useToken();
  const navigate = useNavigate();
  let user = useUser();

  const location = useLocation();
  const currentPath = location.pathname.split("/")[2];

  useEffect(() => {
    menuItems.forEach((one) => {
      if (one.id === currentPath) {
        if (!one.access.includes(user.role)) {
          navigate("/unauthorized");
        }
      }
    });
  }, []);

  const isInFullScreenFn = () => {
    return (
      (document.fullscreenElement && document.fullscreenElement !== null) ||
      (document.webkitFullscreenElement &&
        document.webkitFullscreenElement !== null) ||
      (document.mozFullScreenElement &&
        document.mozFullScreenElement !== null) ||
      (document.msFullscreenElement && document.msFullscreenElement !== null)
    );
  };

  const toggleFullScreen = () => {
    const isFS = isInFullScreenFn();

    const docElm = document.documentElement;
    if (!isFS) {
      if (docElm.requestFullscreen) {
        docElm.requestFullscreen();
      } else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen();
      } else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen();
      } else if (docElm.msRequestFullscreen) {
        docElm.msRequestFullscreen();
      }
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
    setIsInFullScreen(!isFS);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  return (
    <nav className="navbar fixed-top">
      <div className="d-flex align-items-center navbar-left"></div>
      <NavLink className="navbar-logo" to={"#"}>
        {user.role === "User" ? null : `${user.role} Panel`}
      </NavLink>

      <div className="navbar-right">
        <div className="header-icons d-inline-block align-middle">
          <button
            className="header-icon btn btn-empty d-none d-sm-inline-block"
            type="button"
            id="fullScreenButton"
            onClick={toggleFullScreen}
          >
            {isInFullScreen ? (
              <i className="simple-icon-size-actual d-block" />
            ) : (
              <i className="simple-icon-size-fullscreen d-block" />
            )}
          </button>
        </div>
        <div className="user d-inline-block">
          <UncontrolledDropdown className="dropdown-menu-right">
            <DropdownToggle className="p-0" color="empty">
              <span className="name mr-1">{user.username}</span>
              <span>
                <img
                  alt="Profile"
                  src={
                    user.image
                      ? user.image.Location
                      : `/assets/img/profiles/profile.jpg`
                  }
                />
              </span>
            </DropdownToggle>
            <DropdownMenu className="mt-3" end>
              <DropdownItem>
                <Link to="/app/change-details">Change Details</Link>
              </DropdownItem>
              <DropdownItem onClick={() => handleLogout()}>
                Sign out
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>
    </nav>
  );
};
export default TopNav;

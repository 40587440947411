import React, { useEffect, useState } from "react";
import {
  Button,
  Row,
  Card,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import AppLayout from "layout/AppLayout";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axiosClient";
import { useToken } from "auth/useToken";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";

const CEQRegionsList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useToken();

  useEffect(() => {
    let isMounted = true;
    axios
      .get("/api/details/regions?exam=CEQs", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        if (isMounted) {
          setData(result.data);
          setLoading(false);
        }
      });
    return () => {
      isMounted = false;
    };
  }, []);

  const navigate = useNavigate();

  const onSearchKey = (e) => {
    if (e.key === "Enter") {
      setLoading(true);
      handleSearch(e.target.value.toLowerCase());
    }
  };

  const handleSearch = (word) => {
    ///
    axios
      .get(`/api/details/regions?search=${word}&exam=CEQs`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        // console.log(result.data.data);
        setData(result.data);
        setLoading(false);
      });
  };

  const postStatus = (status, id) => {
    axios
      .post(
        `/api/details/regions`,
        { id, status },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((result) => {
        if (result.data.success) {
          navigate(0);
        }
      });
  };

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div className="mb-2">
              <h1>CEQs Regions List</h1>
              <div className="text-zero top-right-button-container">
                <Button
                  color="primary"
                  className="top-right-button"
                  onClick={() => navigate("add")}
                >
                  Add new
                </Button>
                <UncontrolledDropdown className="ml-1 btn-group">
                  <DropdownToggle caret color="outline-primary">
                    Switch to
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => navigate("/app/tkt-regions")}>
                      Switch to TKT
                    </DropdownItem>
                    <DropdownItem onClick={() => navigate("/app/ls-regions")}>
                      Switch to Linguaskill
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
            <div className="mb-2">
              <div className="d-block d-md-inline-block pt-1">
                <div className="search-sm d-inline-block mb-1 ">
                  <input
                    type="text"
                    name="keyword"
                    id="search"
                    placeholder={"Search"}
                    onKeyPress={(e) => onSearchKey(e)}
                  />
                </div>
              </div>
            </div>
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row>
          <div className="loading" hidden={!loading} />
          {!loading && data.length === 0 ? (
            <p>List is empty</p>
          ) : (
            data.map((region) => (
              <Colxx xxs="12" key={region._id} className="mb-3">
                <Card className="d-flex flex-row">
                  <div className="pl-2 d-flex flex-grow-1 min-width-zero">
                    <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
                      <NavLink to={`${region._id}`} className="w-40 w-sm-100">
                        <p className="list-item-heading mb-1 truncate">
                          {region.name}
                        </p>
                      </NavLink>
                      <p className="mb-1 text-muted text-small w-15 w-sm-100">
                        <Switch
                          className="custom-switch custom-switch-primary-inverse"
                          checked={region.status}
                          onChange={(value) => postStatus(value, region._id)}
                        />
                      </p>
                    </div>
                  </div>
                </Card>
              </Colxx>
            ))
          )}
        </Row>
      </div>
    </AppLayout>
  );
};

export default CEQRegionsList;

import React, { useEffect, useState } from "react";
import { Button, Row, Card } from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import AppLayout from "layout/AppLayout";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axiosClient";
import { useToken } from "auth/useToken";

const DetailsList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentGroup, setCurrentGroup] = useState("");
  const [groups, setGroups] = useState(["Others"]);
  const [token, setToken] = useToken();

  const getData = (group) => {
    setCurrentGroup(group);
    setLoading(true);
    axios
      .get(`/api/details/info?group=${group}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        // console.log(result.data);
        setData(result.data);
        setLoading(false);
      });
  };

  useEffect(() => {
    let isMounted = true;
    axios
      .get("/api/details/info-groups", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        if (isMounted) {
          // console.log(result.data);
          setGroups([...result.data.groups, "Others"]);
          setLoading(false);
        }
      });
    return () => {
      isMounted = false;
    };
  }, []);

  const navigate = useNavigate();

  const onSearchKey = (e) => {
    if (e.key === "Enter") {
      setLoading(true);
      handleSearch(e.target.value.toLowerCase());
    }
  };

  const handleSearch = (word) => {
    ///
    axios
      .get(`/api/details/info?search=${word}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        // console.log(result.data.data);
        setData(result.data);
        setLoading(false);
      });
  };
  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div className="mb-2">
              <h1>Details List</h1>
            </div>
            <div className="mb-2">
              <div className="d-block d-md-inline-block pt-1">
                <div className="search-sm d-inline-block mb-1 ">
                  <input
                    type="text"
                    name="keyword"
                    id="search"
                    placeholder={"Search"}
                    onKeyPress={(e) => onSearchKey(e)}
                  />
                </div>
              </div>
            </div>
            <Separator className="mb-2" />
          </Colxx>
          {groups.map((group) => (
            <Button
              key={group}
              outline={currentGroup !== group}
              color="primary"
              className="m-1"
              onClick={() => getData(group)}
            >
              {group}
            </Button>
          ))}
        </Row>
        <Row className="mt-2">
          <div className="loading" hidden={!loading} />
          {!loading && data.length === 0 ? (
            <p>List is empty</p>
          ) : (
            data.map((info) => (
              <Colxx xxs="12" key={info._id} className="mb-3">
                <Card className="d-flex flex-row">
                  <div className="pl-2 d-flex flex-grow-1 min-width-zero">
                    <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
                      <NavLink to={`${info._id}`} className="w-40 w-sm-100">
                        <p className="list-item-heading mb-1 truncate">
                          {info.title}
                        </p>
                      </NavLink>
                    </div>
                  </div>
                </Card>
              </Colxx>
            ))
          )}
        </Row>
      </div>
    </AppLayout>
  );
};

export default DetailsList;

import React, { useEffect, useState, useRef } from "react";
import axios from "axiosClient";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";
import { CKEditor } from "ckeditor4-react";

import AppLayout from "layout/AppLayout";
import { useToken } from "auth/useToken";
import data from "constants/menu";
import Papa from "papaparse";
import Select from "react-select";

const lsReminderBody = `<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">Dear <strong><span style="font-family:&quot;Calibri&quot;,sans-serif">#CANDIDATE_NAME#,</span></strong></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">Linguaskill Test on <strong>#TEST_DATE#</strong> in <strong>#TEST_REGION#</strong></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">Please arrive at 09:20. On the test day you MUST bring a valid ID document which you indicated during the registration!</span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">If you do not bring your original passport, you will not be allowed to enter the test room!</span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">DO NOT BE LATE! </span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">If you come late, you will not be allowed to enter the test room!</span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">The use of electronic devices is strictly forbidden. You must keep your belongings and electronic items outside the exam room on the test day.</span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">Do not bring valuables as the test centre cannot be responsible for your belongings.</span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">Test venue location: Edu-Action office, 4th floor (opposite the Novza mosque)</span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">Here is the link for the test venue: shorturl.at/fUY39</span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">Regards,</span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">Edu-Action Exams Centre!</span></span></p>
`;

const lsResultsBody = `<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">Dear <strong><span style="font-family:&quot;Calibri&quot;,sans-serif">#CANDIDATE_NAME#,</span></strong></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">We are happy to inform you that the results for your <strong>#TEST_DATE#</strong> Linguaskill test are now ready to view.</span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><a href="https://www.metritests.com/metrica/">Click here to view your results</a></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">Login: #passportId# </span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">Password: #sw# </span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">Institution ID: UZ004</span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">Disclaimer: This Linguaskill result is provisional and may not be used as official confirmation of your achievement. You can collect your certificate by coming in person to our office with your ORIGINAL passport from today.</span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">If you have any questions, do not hesitate to contact us during the working hours.</span></span></p>
<p></p>
<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">Best regards,<br/>
Edu-Action Exams Centre UZ004<br/>
Telephone: +998712300103<br/>
Website: edu-action.uk<br/>
Address: 2-3 Bunyodkor Avenue, CIU Building 4th floor, Tashkent, Uzbekistan 100043</span></span></p>
`;

const SendEmail = () => {
  const ref = useRef(null);
  const navigate = useNavigate();
  const [editor, setEditor] = useState(null);
  const [showEditor, setShowEditor] = useState(false);
  const [subject, setSubject] = useState("Linguaskill Reminder");
  const [body, setBody] = useState("");
  const [candidates, setCandidates] = useState([]);
  const [candidatesLoading, setCandidatesLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);
  const [file, setFile] = useState(null);
  const [token, setToken] = useToken();

  const editorConfig = {
    toolbar: [
      ["Undo", "Redo"],
      ["Format", "Font", "FontSize"],
      ["TextColor", "BGColor"],
      ["JustifyLeft", "JustifyCenter", "JustifyRight", "JustifyBlock"],
      [
        "Bold",
        "Italic",
        "Underline",
        "Strike",
        "RemoveFormat",
        "CopyFormatting",
      ],
      ["Link", "Unlink"],
      [
        "NumberedList",
        "BulletedList",
        "-",
        "Outdent",
        "Indent",
        "-",
        "Blockquote",
      ],
      ["Image", "Table"],
      ["Scayt"],
    ],
    extraPlugins: "justify,format,font,colorbutton",
  };

  const onBeforeLoad = (e) => {
    setEditor(e.editor);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSending(true);

    try {
      if (errorMessage === "") {
        axios
          .post(
            `/api/send-email-csv`,
            { candidates, content: body, subject },
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((result) => {
            // console.log(result.data);
            setSent(true);
            setSending(false);
          })
          .catch((err) => {
            setErrorMessage(err.response.data);
          });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div className="mb-2">
              <h1>{data.title}</h1>
            </div>
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        {!loading && (
          <Row>
            <Colxx xxs="12" className="mb-4">
              <Card className="mb-4">
                <CardBody>
                  <Form>
                    <Row>
                      <Col md="12">
                        <>
                          <FormGroup>
                            <h2>Email Subject</h2>
                            <Select
                              value={{
                                label: subject,
                                value: subject,
                              }}
                              options={[
                                {
                                  label: "Linguaskill Reminder",
                                  value: "Linguaskill Reminder",
                                },
                                {
                                  label: "Linguaskill Results",
                                  value: "Linguaskill Results",
                                },
                              ]}
                              onChange={(e) => {
                                setShowEditor(false);
                                if (e.value === "Linguaskill Reminder") {
                                  setBody(lsReminderBody);
                                  editor && editor.setData(lsReminderBody);
                                } else if (e.value === "Linguaskill Results") {
                                  setBody(lsResultsBody);
                                  editor && editor.setData(lsResultsBody);
                                }
                                setSubject(e.value);
                                setShowEditor(true);
                              }}
                            />
                          </FormGroup>
                          <h2>Email Content</h2>
                          {showEditor && (
                            <CKEditor
                              initData={body}
                              onLoaded={onBeforeLoad}
                              onChange={(event) =>
                                setBody(event.editor.getData())
                              }
                              config={editorConfig}
                            />
                          )}
                        </>
                        <br />
                        Please, upload the .CSV file of candidates:
                        <FormGroup row>
                          <Colxx sm="9">
                            <Input
                              type="file"
                              accept=".csv"
                              onChange={(e) => {
                                const files = e.target.files;
                                if (files) {
                                  setCandidatesLoading(true);
                                  Papa.parse(files[0], {
                                    delimiter: ",",
                                    header: true,
                                    skipEmptyLines: true,
                                    complete: function (results) {
                                      // console.log("Finished:");
                                      // console.log(results.data);
                                      setCandidates(results.data);
                                      setCandidatesLoading(false);
                                    },
                                  });
                                }
                              }}
                            />
                          </Colxx>
                        </FormGroup>
                        {candidatesLoading ? (
                          <p>Loading...</p>
                        ) : (
                          <p>The number of candidates: {candidates.length}</p>
                        )}
                        {sending && (
                          <span className="btn btn-warning">Sending...</span>
                        )}
                        {sent && <span className="btn btn-success">DONE!</span>}
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Colxx>
          </Row>
        )}
        <div>
          <Button
            color="primary"
            className="top-right-button"
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            className="ml-2 top-right-button"
            onClick={handleSubmit}
          >
            Send
          </Button>
        </div>
        &nbsp;
        <div>{errorMessage}</div>
      </div>
    </AppLayout>
  );
};

export default SendEmail;
